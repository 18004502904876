import React, { useEffect, useState } from "react";
import styles from "./NewMultipleImageUploadComponent.module.scss";
// import { SelectBasicWithSelectTag } from "../SelectMultiSelect/SelectMultiSelect";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import ImageList from "./ImageList";
import DocumentList from "./DocumentList";
import VideoList from "./VideoList";
import { getDecryptedData } from "../../utils/crypto";

const MultipleImageUploadComponent = ({
  modelType,
  modelId,
  upload_collection,
  setState,
  fileTypeDisabled,
  multiple = true,

  // Url type
  urlType,
  tokenType,

  // upload url
  uploadType,

  // delete url
  deleteType,

  // For Agent Only
  file_type_agent,
  // fetchMediaRefetch,
  showSingleImage = false,
}) => {
  const [files, setFiles] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [fileType, setFileType] = useState("image");
  const [showFileType, setShowFileType] = useState("photos");
  const [isUploading, setIsUploading] = useState(false);
  // const vendorToken = localStorage.getItem("token");
  const vendorToken = getDecryptedData("userToken");
  const agentToken = getDecryptedData("userToken");

  // Validate file type
  const validateFileType = (file) => {
    const allowedTypes = {
      image: ["image/jpeg", "image/png"],
      video: ["video/mp4", "video/mov", "video/avi", "video/wmv", "video/mkv"],
      document: ["application/pdf"],
    };
    return allowedTypes[fileType]?.includes(file.type);
  };

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const validFiles = selectedFiles.filter(validateFileType);

    if (validFiles.length < selectedFiles.length) {
      showErrorToast(
        "Some files are not supported. Please select valid formats."
      );
    }

    const fileDataPromises = validFiles.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({
            file,
            preview: file.type === "application/pdf" ? null : reader.result,
            name: file.name,
          });
        };
        reader.readAsDataURL(file);
      });
    });

    Promise.all(fileDataPromises).then((fileData) => {
      setFiles(multiple ? [...files, ...fileData] : fileData);
    });
  };

  // const [
  //   fetchMedia,
  //   {
  //     data: fetchMediaData,
  //     isSuccess: fetchMediaSuccess,
  //     isLoading: fetchMediaLoading,
  //     isError: fetchMediaError,
  //     refetch: fetchMediaRefetch,
  //   },
  // ] = useFetchMediaMutation(modelId, {
  //   skip: !modelId,
  // });

  const fetchMedia = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${tokenType === "agent" ? agentToken : vendorToken}`
    );
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

    const formdata = new FormData();
    formdata.append("modelType", modelType);
    formdata.append("modelId", modelId);
    formdata.append(
      "upload_collection",
      file_type_agent ? file_type_agent : showFileType
    );

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${urlType}`,
        requestOptions
      );
      const result = await response.json();
      if (result.success) {
        setImageData(result?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   if (fetchMediaSuccess) {
  //     setImageData(fetchMediaData.data);
  //   }
  // }, [fetchMediaSuccess, fetchMediaData]);

  // console.log("imageData", imageData);

  useEffect(() => {
    if (modelId && showFileType) {
      fetchMedia({
        modelType: modelType,
        modelId: modelId,
        upload_collection: file_type_agent ? file_type_agent : showFileType,
      });
    }
  }, [modelId, showFileType]);

  const handleUpload = async () => {
    if (files.length === 0) {
      showErrorToast("No files to upload!");
      return;
    }

    setIsUploading(true);

    const array = getFieTypeArray(modelType);
    const obj = array.find((item) => item.value === fileType);

    if (!obj) {
      showErrorToast("Invalid file type!");
      setIsUploading(false);
      return;
    }

    try {
      // Set up headers
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", `Bearer ${vendorToken}`);
      myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

      // Create FormData
      const formData = new FormData();
      formData.append("modelType", modelType);
      formData.append("modelId", modelId);
      formData.append(
        "upload_collection",
        file_type_agent ? file_type_agent : obj.uploadCollection
      );
      formData.append("file_type", fileType);

      files.forEach((fileData) => {
        formData.append("files[]", fileData.file);
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };

      // Make the API call
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/${uploadType}`,
        requestOptions
      );

      const result = await response.json();

      if (response.ok && result?.success) {
        showSuccessToast(result.message);
        setFiles([]);
        fetchMedia({
          modelType: modelType,
          modelId: modelId,
          upload_collection: showFileType,
        });
      } else {
        showErrorToast(result?.message || "Upload failed!");
      }
    } catch (error) {
      showErrorToast("An error occurred during upload. Please try again.");
    } finally {
      setIsUploading(false);
    }
  };

  // Remove a file from the list
  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const normal = [
    { value: "image", label: "Images", uploadCollection: "photos" },
    { value: "video", label: "Videos", uploadCollection: "videos" },
  ];

  const agent = [
    { value: "image", label: "Agent Logo", uploadCollection: "agent-logo" },
    { value: "document", label: "Documents", uploadCollection: "documents" },
  ];
  const destinations = [
    { value: "image", label: "Images", uploadCollection: "photos" },
    { value: "video", label: "Videos", uploadCollection: "videos" },
  ];
  const packages = [
    { value: "image", label: "Images", uploadCollection: "photos" },
    { value: "video", label: "Videos", uploadCollection: "videos" },
  ];

  const accommodation = [
    { value: "image", label: "Images", uploadCollection: "photos" },
    { value: "video", label: "Videos", uploadCollection: "videos" },
    { value: "document", label: "Document", uploadCollection: "documents" },
  ];

  const normalShow = [
    { value: "photos", label: "Images", uploadCollection: "photos" },
    { value: "videos", label: "Videos", uploadCollection: "videos" },
  ];

  const agentShow = [
    { label: "Agent Logo", value: "agent-logo" },
    { label: "Documents", value: "documents" },
  ];

  const destinationsShow = [
    { value: "photos", label: "Images", uploadCollection: "photos" },
    { value: "videos", label: "Videos", uploadCollection: "videos" },
  ];

  const accommodationShow = [
    { value: "photos", label: "Images", uploadCollection: "photos" },
    { value: "videos", label: "Videos", uploadCollection: "videos" },
    { value: "documents", label: "Document", uploadCollection: "documents" },
  ];
  const packagesShow = [
    { value: "image", label: "Images", uploadCollection: "photos" },
    { value: "video", label: "Videos", uploadCollection: "videos" },
  ];

  const getFieTypeArray = (modelType) => {
    // if (modelType === "agents") {
    //   return agent;
    // } else {
    //   return normal;
    // }

    // with switch
    switch (modelType) {
      case "agents":
        return agent;
      case "destinations":
        return destinations;
      case "accommodation":
        return accommodation;
      case "packages":
        return packages;
      default:
        return normal;
    }
  };

  const getFieTypeArrayShow = (modelType) => {
    // if (modelType === "agents") {
    //   return agent;
    // } else {
    //   return normal;
    // }

    // with switch
    switch (modelType) {
      case "agents":
        return agentShow;
      case "destinations":
        return destinationsShow;
      case "accommodation":
        return accommodationShow;
      case "packagesShow":
        return packagesShow;
      default:
        return normalShow;
    }
  };

  return (
    <div className={styles.multipleImageUpload}>
      {files.length > 0 ? (
        <div>
          <div>Selected Files:</div>
          <div className={styles.previewContainer}>
            {files.map((fileData, index) => (
              <div key={index}>
                {fileType === "image" && (
                  <img
                    className={styles.previewImage}
                    src={fileData.preview}
                    alt={fileData.name}
                  />
                )}
                {fileType === "video" && (
                  <div style={{ width: "200px", height: "200px" }}>
                    <video
                      className={styles.previewVideo}
                      src={fileData.preview}
                      controls
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                )}
                {fileType === "document" && (
                  <div className={styles.documentPreview}>
                    <p>{fileData.name}</p>
                  </div>
                )}
                <button onClick={() => handleDelete(index)}>X</button>
              </div>
            ))}
          </div>
          <button
            style={{ marginTop: "10px", width: "auto" }}
            onClick={handleUpload}
            disabled={isUploading}
            className="primary_btn"
          >
            {isUploading ? "Uploading..." : "Upload"}
          </button>
        </div>
      ) : (
        <div className={styles.fileInputMedia}>
          <label>{multiple ? "Add Files" : "Add a File"}</label>
          <input
            type="file"
            accept={
              fileType === "image"
                ? "image/jpeg, image/png"
                : fileType === "video"
                  ? "video/mp4, video/mov, video/avi, video/wmv, video/mkv"
                  : "application/pdf"
            }
            multiple={multiple}
            onChange={handleFileChange}
          />
          {fileType === "image" && <p>or drop JPG, PNG images here</p>}
          {fileType === "video" && (
            <p>or drop mp4, mov, avi, wmv, mkv videos here</p>
          )}
          {fileType === "document" && <p>or drop PDF documents here</p>}
          <div className={styles.selectFileType}>
            {/* <SelectBasicWithSelectTag
              disabled={true}
              label="Select File Type"
              name="upload_collection"
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              options={getFieTypeArray(modelType)}
            /> */}
          </div>
        </div>
      )}
      {/* <hr /> */}
      <div>
        <div
          className="mt-10"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div>Uploaded Data:</div>
          <div style={{ width: "200px" }}>
            {/* <SelectBasicWithSelectTag
              label="Select File Type"
              name="upload_collection"
              value={showFileType}
              onChange={(e) => setShowFileType(e.target.value)}
              options={getFieTypeArrayShow(modelType)}
            /> */}
          </div>
        </div>
        <div>
          {/* {file_type_agent === "agent-logo" && (
            <ImageList
              imageData={imageData}
              deleteUrl={deleteType}
              fetchMediaRefetch={() =>
                fetchMedia({
                  modelType: modelType,
                  modelId: modelId,
                  upload_collection: file_type_agent ? file_type_agent : showFileType,
                })
              }
              multiple={multiple}
            />
          )} */}
          {showFileType === "image" && (
            <>
              {showSingleImage ? (
                <img src={imageData} />
              ) : (
                <ImageList
                  imageData={imageData}
                  deleteUrl={deleteType}
                  // fetchMediaRefetch={fetchMediaRefetch}
                  multiple={multiple}
                />
              )}
            </>
          )}

          {showFileType === "videos" && (
            <VideoList
              imageData={imageData}
              fetchMediaRefetch={() =>
                fetchMedia({
                  modelType: modelType,
                  modelId: modelId,
                  upload_collection: showFileType,
                })
              }
              multiple={true}
            />
          )}

          {showFileType === "photos" && (
            <>
              {showSingleImage ? (
                <>
                  {imageData && (
                    <div style={{ width: "100px", height: "100px", textAlign: "center", position: "relative" }}>
                      <img src={imageData} />
                    </div>
                  )}
                </>
              ) : (
                <ImageList
                  imageData={imageData}
                  deleteUrl={deleteType}
                  fetchMediaRefetch={() =>
                    fetchMedia({
                      modelType: modelType,
                      modelId: modelId,
                      upload_collection: showFileType
                    })
                  }
                  multiple={multiple}
                />
              )}
            </>
          )}

          {showFileType === "documents" && (
            <DocumentList
              documents={imageData}
              fetchMediaRefetch={() =>
                fetchMedia({
                  modelType: modelType,
                  modelId: modelId,
                  upload_collection: showFileType,
                })
              }
              multiple={multiple}
            />
          )}

        </div>
      </div>
      {/* <div style={{ marginTop: "10px" }}>
        <ImageList
          imageData={imageData}
          fetchMediaRefetch={fetchMediaRefetch}
        />
      </div> */}
    </div>
  );
};

export default MultipleImageUploadComponent;
