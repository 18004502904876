import React, { useEffect, useState } from "react";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import styles from "./SoldOutRoomPopup.module.scss";
import locationIcon from "../../assets/images/location_icon.png";
import destinationIcon from "../../assets/images/experienc_icon.png";
import securityIcon from "../../assets/images/security_icon.png";
import customerIcon from "../../assets/images/customer_icon.png";
import { formatDate, formatIndianCurrency } from "../../utils/utils";

const RoomGetQuotePopup = ({ setshowRatePopup, bookingData, noOfNights, hotelData, ratesData, bookingValues, selectedAutomaticDiscount, selectedDiscount, selectedMealPlan, commission, typeDetail }) => {
    const [showRatePopup, setShowRatePopup] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const [isMobile, setIsMobile] = useState(false);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    useEffect(() => {
        const checkScreenSize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        checkScreenSize();
        window.addEventListener("resize", checkScreenSize);

        return () => window.removeEventListener("resize", checkScreenSize);
    }, []);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        source: "website",
        content: "",
        source_type: "",
        type: typeDetail ? typeDetail : "package",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleShowRatePopup = (e) => {
        e.stopPropagation();
        setshowRatePopup(false);
    };

    const validate = () => {
        const errors = {};
        // Add validation logic for each form field
        // if (currentStep === 1) {
        if (!formData.name.trim()) {
            errors.name = "required";
        }
        if (!formData.company_name.trim()) {
            errors.company_name = "required";
        }
        if (!formData.phone.trim()) {
            errors.phone = "is required";
        }
        if (!formData.querry_detail) {
            errors.querry_detail = "required";
        }
        if (!formData.email) {
            errors.email = "required";
        }
        if (!formData.travel_date) {
            errors.travel_date = "required";
        }
        // }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
        myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

        const checkin = new Date(
            bookingData.checkinYear,
            bookingData.checkinMonth - 1,
            bookingData.checkinDay
        );
        const checkout = new Date(
            bookingData.checkoutYear,
            bookingData.checkoutMonth - 1,
            bookingData.checkoutDay
        );

        const formattedCheckInDate = checkin?.toISOString().split('T')[0];
        const formattedCheckOutDate = checkout?.toISOString().split('T')[0];

        const rawData = {
            name: formData?.name,
            email: formData?.email,
            phone_information: formData?.phone,
            content: formData?.content,
            source: formData?.source,
            source_type: formData?.source_type,
            type: formData?.type,

            check_in_information: formattedCheckInDate,
            check_out_information: formattedCheckOutDate,
            adults_information: bookingData?.adult ? bookingData?.adult : 2,
            children_information: bookingData?.children ? bookingData?.children : 0,
            child_age_information: bookingData?.childAge ? bookingData?.childAge : [],
            rooms_information: bookingData?.room ? bookingData?.room : 1,
            hotel_category_information: [hotelData?.hotelInfo?.category],
            room_category_information: [hotelData?.hotelInfo?.category],
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/query/create`,
                {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(rawData),
                    redirect: "follow",
                }
            );
            const result = await response.json();
            if (result.success) {
                showSuccessToast(result.message || "Form submitted successfully!");
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    source: "website",
                    content: "",
                    source_type: "",
                });
                setshowRatePopup(false);
            } else {
                showErrorToast(
                    result.message || "An error occurred. Please try again."
                );
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    source: "website",
                    content: "",
                    source_type: "",
                });
            }
        } catch (error) {
            showErrorToast("An error occurred. Please try again.");
            console.error(error);
        }
    };

    return (
        <div className="bg-backdrop">
            <div className={styles.soldout_popup}>
                <div className={`${styles.new_popup_flex} ${styles.soldout_popup_flex}`}>
                    <div className={`${styles.new_popup_colored} ${styles.soldout_room_price}`}>
                        <div className="flex h-full w-full">
                            <div className="w-full">
                                <div className="flex justify_content_between">
                                    <h4
                                        style={{
                                            fontSize: "20px",
                                            fontWeight: "600",
                                            color: "#333",
                                            textAlign: "left",
                                        }}
                                    >
                                        Price Breakup
                                    </h4>

                                    {isMobile && (
                                        <button onClick={() => setIsAccordionOpen(!isAccordionOpen)} className="flex flex_gap_5 align_items_center" style={{ color: '#007ae4', fontSize: '13px' }}>
                                            {isAccordionOpen ? "Hide Breakup" : "View Breakup"}
                                            <svg
                                                width="13"
                                                height="8"
                                                viewBox="0 0 13 8"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    transition: "transform 0.3s",
                                                    margin: '0',
                                                    transform: isAccordionOpen
                                                        ? "rotate(180deg)"
                                                        : "rotate(0deg)",
                                                }}
                                            >
                                                <path
                                                    d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                                                    stroke="#222222"
                                                    strokeWidth="1.5"
                                                />
                                            </svg>
                                        </button>
                                    )}
                                </div>

                                {(!isMobile || isAccordionOpen) && (
                                    <div>
                                        <div className="flex justify_content_between mt-10">
                                            <p>
                                                Room Type
                                            </p>
                                            <span className="room_text-right">
                                                {ratesData?.room_name}
                                            </span>
                                        </div>

                                        {(selectedAutomaticDiscount.length > 0 || selectedDiscount.length > 0) && (
                                            <div className="mt-10">
                                                <p><b>Property Discount{selectedAutomaticDiscount?.length > 1 ? 's' : ''}</b></p>
                                                {selectedAutomaticDiscount?.map((discount, index) => (
                                                    <div className="flex justify_content_between mt-2" key={discount.id}>
                                                        <span className="discount-name">{discount.promotionName}</span>
                                                        <span className="room_text-right green">
                                                            {discount.promotionMode === "percentage"
                                                                ? `${+discount.promotionAmount}%`
                                                                : `${formatIndianCurrency(discount.promotionAmount)}`}
                                                        </span>
                                                    </div>
                                                ))}

                                                {selectedDiscount && selectedDiscount?.length > 0 && selectedDiscount?.map((discount, index) => (
                                                    <div className="flex justify_content_between mt-2" key={discount.id}>
                                                        <span className="discount-name">{discount.promotionName}</span>
                                                        <span className="room_text-right green">
                                                            {discount.promotionMode === "percentage"
                                                                ? `${+discount.promotionAmount}%`
                                                                : `${formatIndianCurrency(discount.promotionAmount)}`}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        )}

                                        <div className="flex justify_content_between mt-10">
                                            <p>
                                                Room Rate <small>(Per Night)</small>
                                            </p>
                                            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                                {(selectedAutomaticDiscount.length > 0 || selectedDiscount.length > 0) && bookingValues.newRoomRate && bookingValues.roomRateAfterDiscount && (
                                                    <small style={{ color: 'red', textDecoration: 'line-through' }}>
                                                        {formatIndianCurrency(bookingValues?.roomRatePerNight)}
                                                    </small>
                                                )}
                                                <b>
                                                    {formatIndianCurrency(bookingValues.roomRateAfterDiscount ? bookingValues.roomRateAfterDiscount : bookingValues.newRoomRate)}
                                                </b>
                                            </span>
                                        </div>

                                        {(bookingData?.room > 1 || noOfNights > 1) && (
                                            <div className="flex justify_content_between mt-10">
                                                <p>
                                                    {noOfNights} Night * {bookingData?.room} Rooms
                                                </p>
                                                <span className="room_text-right">
                                                    {formatIndianCurrency(
                                                        bookingValues.roomRateAfterDiscount *
                                                        bookingData?.room *
                                                        noOfNights
                                                    )}
                                                </span>
                                            </div>
                                        )}

                                        {selectedMealPlan?.extra_adult_count > 0 && (
                                            <div className="flex justify_content_between mt-10">
                                                <p>
                                                    {selectedMealPlan?.extra_adult_count} Extra Adult{" "}
                                                    <small>
                                                        (
                                                        {formatIndianCurrency(
                                                            bookingValues?.extraAdultPrice
                                                        )}{" "}
                                                        * {noOfNights} Night){" "}
                                                    </small>{" "}
                                                </p>
                                                <span className="room_text-right">
                                                    {formatIndianCurrency(
                                                        bookingValues?.totalAdultCost
                                                    )}
                                                </span>
                                            </div>
                                        )}

                                        {bookingValues?.childPrices > 0 && (
                                            <div className="flex justify_content_between mt-10">
                                                <p>
                                                    {bookingData.children} Extra Child{" "}
                                                    <small>
                                                        (
                                                        {formatIndianCurrency(
                                                            bookingValues?.childPrices
                                                        )}{" "}
                                                        * {noOfNights} Night){" "}
                                                    </small>{" "}
                                                </p>
                                                <span className="room_text-right">
                                                    {formatIndianCurrency(
                                                        bookingValues?.totalChildAdultCost
                                                    )}
                                                </span>
                                            </div>
                                        )}

                                        {commission && commission?.commissionAmount && (
                                            <div className="flex justify_content_between mt-10">
                                                <p>Commission{' '}
                                                    <small>
                                                        ( {commission.commissionType === "fixed" ? (
                                                            <span className="room_text-right">
                                                                {formatIndianCurrency(
                                                                    commission?.commissionAmount
                                                                )}
                                                            </span>
                                                        ) : (
                                                            <span className="room_text-right">
                                                                {commission?.commissionAmount}%
                                                            </span>
                                                        )})
                                                    </small>
                                                </p>
                                                {formatIndianCurrency(Number(bookingValues?.calculatedCommissionAmount))}
                                            </div>
                                        )}

                                        <div className="flex justify_content_between mt-10">
                                            <p>Subtotal</p>
                                            <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                                {commission && Object.keys(commission).length > 0 && bookingValues?.calculatedCommissionAmount && (
                                                    <small style={{ color: 'red', textDecoration: 'line-through' }}>
                                                        {formatIndianCurrency(
                                                            Number(bookingValues?.subtotal) + Number(bookingValues?.calculatedCommissionAmount)
                                                        )}
                                                    </small>
                                                )}
                                                <b>
                                                    {formatIndianCurrency(bookingValues?.subtotal)}
                                                </b>
                                            </span>
                                        </div>

                                        <div className="flex justify_content_between mt-10">
                                            <p>Tax Amount ({bookingValues?.taxSlab}%)</p>
                                            <span className="room_text-right">
                                                {formatIndianCurrency(bookingValues.taxAmount)}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div className="flex justify_content_between mt-10">
                                    <h6>
                                        <b>Grand Total</b>
                                    </h6>
                                    <h6>
                                        <b>
                                            {formatIndianCurrency(bookingValues?.grandTotal)}
                                        </b>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className={styles.new_popup_form}>

                        <h4
                            style={{
                                fontSize: "18px",
                                fontWeight: "600",
                                color: "#BB735D",
                                textAlign: "left",
                            }}
                        >
                            Get Your Personalized Hotel Quote Now!
                        </h4>

                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                marginTop: "20px",
                            }}
                        >
                            <div className="flex flex_gap_10">
                                <div>
                                    <label htmlFor="name">Name:</label>
                                    <input
                                        // required
                                        className="w-full"
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        placeholder="Your Name"
                                        style={{
                                            padding: "5px",
                                            borderRadius: "4px",
                                            border: "1px solid #ccc",
                                            ...(formErrors.name && {
                                                backgroundColor: "#ff000040",
                                                border: "1px solid red",
                                            }),
                                        }}
                                    />
                                </div>

                                <div>
                                    <label htmlFor="phone">Phone:</label>
                                    <input
                                        className="w-full"
                                        type="number"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        placeholder="Phone"
                                        style={{
                                            padding: "5px",
                                            borderRadius: "4px",
                                            border: "1px solid #ccc",
                                            ...(formErrors.phone && {
                                                backgroundColor: "#ff000040",
                                                border: "1px solid red",
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                            {/* 
                        <div className="flex flex_gap_10">
                            <div
                                className={
                                    typeDetail === "activity"
                                        ? "activity_inputs_query_forms"
                                        : "w-full"
                                }
                            >
                                <label htmlFor="source_type">Travel Date:</label>
                                <input
                                    className="w-full"
                                    type="date"
                                    name="travel_date"
                                    value={formData.travel_date}
                                    onChange={handleInputChange}
                                    placeholder="Travel Date"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.travel_date && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />

                            </div>
                            {typeDetail === "activity" && (
                                <div className="activity_inputs_query_forms">
                                    <label htmlFor="guide_required">Do you need Guide:</label>
                                    <select
                                        style={{
                                            padding: "10px",
                                            ...(formErrors.guide_required && {
                                                backgroundColor: "#ff000040",
                                                border: "1px solid red",
                                            }),
                                        }}
                                        className="w-full"
                                        name="guide_required"
                                        value={formData.guide_required}
                                        onChange={handleInputChange}
                                    >
                                        <option value="" disabled>
                                            Select an option
                                        </option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            )}
                        </div> */}

                            <div className="flex flex_gap_10">
                                <div className="activity_inputs_query_forms">
                                    <label htmlFor="email">Email:</label>
                                    <input
                                        className="w-full"
                                        type="text"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder="Email"
                                        style={{
                                            padding: "5px",
                                            borderRadius: "4px",
                                            border: "1px solid #ccc",
                                            ...(formErrors.email && {
                                                backgroundColor: "#ff000040",
                                                border: "1px solid red",
                                            }),
                                        }}
                                    />
                                </div>
                                <div className="activity_inputs_query_forms">
                                    <label htmlFor="source_type">Are you Agent:</label>
                                    <select
                                        style={{
                                            padding: "5px",
                                            ...(formErrors.source_type && {
                                                backgroundColor: "#ff000040",
                                                border: "1px solid red",
                                            }),
                                        }}
                                        className="w-full"
                                        name="source_type"
                                        value={formData.source_type}
                                        onChange={handleInputChange}
                                    >
                                        <option value="" disabled>
                                            Select an option
                                        </option>
                                        <option value="agent">Yes</option>
                                        <option value="customer">No</option>
                                    </select>
                                </div>
                            </div>
                            {/*  
            {typeDetail === "activity" && (
              <div className="flex flex_gap_10">
                <div className="activity_inputs_query_forms">
                  <label htmlFor="source_type">Adult:</label>
                  <input
                    className="w-full"
                    type="number"
                    name="adult"
                    value={formData.adult}
                    onChange={handleInputChange}
                    placeholder="Adult"
                    style={{
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      ...(formErrors.adult && {
                        backgroundColor: "#ff000040",
                        border: "1px solid red",
                      }),
                    }}
                  />
                </div>
                <div className="activity_inputs_query_forms">
                  <label htmlFor="source_type">Time:</label>
                  <input
                    className="w-full"
                    type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleInputChange}
                    placeholder="Time"
                    style={{
                      padding: "10px",
                      borderRadius: "4px",
                      border: "1px solid #ccc",
                      ...(formErrors.time && {
                        backgroundColor: "#ff000040",
                        border: "1px solid red",
                      }),
                    }}
                  />
                </div>
              </div>
            )} */}

                            <div>
                                <label htmlFor="content">Query Detail:</label>
                                <textarea
                                    className="w-full"
                                    type="text-area"
                                    name="content"
                                    value={formData.content}
                                    onChange={handleInputChange}
                                    placeholder="Querry Detail"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.content && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>
                            <div className="">
                                <button type="submit" className={styles.common_btn}>
                                    SEND
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div
                    className={`${styles.soldout_close_btn} dashboard_checkin_modal_close_btn`}
                    onClick={handleShowRatePopup}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                    >
                        <path
                            d="M1 12.7464L13 0.851318"
                            stroke="#ffffff"
                            strokeWidth="1.2"
                        />
                        <path
                            d="M13 12.7463L0.999999 0.851296"
                            stroke="#ffffff"
                            strokeWidth="1.2"
                        />
                    </svg>

                    close
                </div>
            </div>
        </div>
    );
};

export default RoomGetQuotePopup;