import React, { useEffect, useState } from "react";
import Header from "../../CustomComponents/Header/Header";
import Footer from "../../CustomComponents/Footer/Footer";
import { Link } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";

function ContactUs() {
  const [formErrors, setFormErrors] = useState({});

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    source: "website",
    content: "",
    source_type: "",
    type: ""
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const postFormDataHandle = (e) => {
    e.preventDefault();
    const myHeaders = new Headers();
    const valid = validate();
    setTimeout(() => {
      setFormErrors({});
    }, 1500);
    if (!valid) {
      showErrorToast("Please fill the above details.");
      return;
    }

    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_API_KEY);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      leadName: formData?.name,
      leadPhone: formData?.phone_number,
      leadEmail: formData?.email,
      leadDetail: formData?.message,
      leadSource: "Website",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_APP_URL}/api/v1/contact-us`, requestOptions)
      .then((response) => response.json())

      .then((result) => {
        if (result.success) {
          setFormData();
          showSuccessToast("Form Submitted Successfully");
        }
      })

      .catch((error) => console.error(error));
  };

  const validate = () => {
    const errors = {};
    // Add validation logic for each form field
    // if (currentStep === 1) {
    if (!formData.name.trim()) {
      errors.name = "required";
    }
    if (!formData.email.trim()) {
      errors.email = "required";
    }
    if (!formData.phone_number.trim()) {
      errors.phone_number = "required";
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append(
      "Cookie", process.env.REACT_APP_COOKIE
    );

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/query/create`,
        {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(formData),
          redirect: "follow",
        }
      );
      const result = await response.json();
      if (result.success) {
        console.log(result);
        showSuccessToast(result.message || "Form submitted successfully!");
        setFormData({
          name: "",
          email: "",
          source: "website",
          phone_number: "",
          content: "",
          source_type: "",
          type: ""
        })
      } else {
        showErrorToast(result.message || "An error occurred. Please try again.");
        setFormData({
          name: "",
          email: "",
          source: "website",
          phone_number: "",
          content: "",
          source_type: "",
          type: ""
        })
      }
    } catch (error) {
      showErrorToast("An error occurred. Please try again.");
      console.error(error);
    }
  };

  return (
    <>
      <PageMetaData
        title="Contact Us | Mountain Mysteries – Reach Out for Assistance"
        description="Get in touch with Mountain Mysteries or personalized support, inquiries, or assistance. We're here to help via email, phone, or our contact form. Reach out today."
      // keywords="hotel management, RevCatalyst, property optimization"
      />
      <OgMetaTitles
        title="Contact Us | Mountain Mysteries – Reach Out for Assistance"
        type="Get in touch with Mountain Mysteries or personalized support, inquiries, or assistance. We're here to help via email, phone, or our contact form. Reach out today."
        url={`https://www.mountainmysteries.com/contact-us`}
      />
      <Header />
      <section className="contactUsBg">
        <div className="container">
          <h3>Contact Us</h3>
          <p className="text-center mt-10">
            Get in touch with us today to turn your travel dreams into reality,
            and enjoy a smooth journey with our guidance every step of the way!
          </p>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="main_div" style={{ alignItems: 'normal', justifyContent: 'center' }}>
            <div className="contact_div">
              <div>
                <h3 className="text-white text-left">Get In Touch</h3>
                <div className="contact-data">
                  <span>
                    {" "}
                    <svg
                      width="18"
                      height="19"
                      viewBox="0 0 18 19"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_2263_992"
                        style={{
                          maskType: "alpha",
                          maskUnits: "userSpaceOnUse",
                          x: "0",
                          y: "0",
                          width: "18",
                          height: "19",
                        }}
                      >
                        <rect y="0.5" width="18" height="18" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_2263_992)">
                        <path
                          d="M14.9625 16.25C13.4 16.25 11.8563 15.9094 10.3313 15.2281C8.80625 14.5469 7.41875 13.5813 6.16875 12.3313C4.91875 11.0813 3.95312 9.69375 3.27188 8.16875C2.59063 6.64375 2.25 5.1 2.25 3.5375C2.25 3.3125 2.325 3.125 2.475 2.975C2.625 2.825 2.8125 2.75 3.0375 2.75H6.075C6.25 2.75 6.40625 2.80937 6.54375 2.92812C6.68125 3.04688 6.7625 3.1875 6.7875 3.35L7.275 5.975C7.3 6.175 7.29375 6.34375 7.25625 6.48125C7.21875 6.61875 7.15 6.7375 7.05 6.8375L5.23125 8.675C5.48125 9.1375 5.77812 9.58437 6.12187 10.0156C6.46562 10.4469 6.84375 10.8625 7.25625 11.2625C7.64375 11.65 8.05 12.0094 8.475 12.3406C8.9 12.6719 9.35 12.975 9.825 13.25L11.5875 11.4875C11.7 11.375 11.8469 11.2906 12.0281 11.2344C12.2094 11.1781 12.3875 11.1625 12.5625 11.1875L15.15 11.7125C15.325 11.7625 15.4688 11.8531 15.5812 11.9844C15.6937 12.1156 15.75 12.2625 15.75 12.425V15.4625C15.75 15.6875 15.675 15.875 15.525 16.025C15.375 16.175 15.1875 16.25 14.9625 16.25ZM4.51875 7.25L5.75625 6.0125L5.4375 4.25H3.76875C3.83125 4.7625 3.91875 5.26875 4.03125 5.76875C4.14375 6.26875 4.30625 6.7625 4.51875 7.25ZM11.2313 13.9625C11.7188 14.175 12.2156 14.3438 12.7219 14.4688C13.2281 14.5938 13.7375 14.675 14.25 14.7125V13.0625L12.4875 12.7063L11.2313 13.9625Z"
                          fill="#fff"
                        />
                      </g>
                    </svg>
                    +91-98050-21966
                  </span>
                  <span>
                    {" "}
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_2263_999"
                        style={{
                          maskType: "alpha",
                          maskUnits: "userSpaceOnUse",
                          x: "0",
                          y: "0",
                          width: "18",
                          height: "19",
                        }}
                      >
                        <rect
                          x="0.333374"
                          y="0.5"
                          width="18"
                          height="18"
                          fill="#D9D9D9"
                        />
                      </mask>
                      <g mask="url(#mask0_2263_999)">
                        <path
                          d="M3.33337 15.5C2.92087 15.5 2.56775 15.3531 2.274 15.0594C1.98025 14.7656 1.83337 14.4125 1.83337 14V5C1.83337 4.5875 1.98025 4.23438 2.274 3.94063C2.56775 3.64688 2.92087 3.5 3.33337 3.5H15.3334C15.7459 3.5 16.099 3.64688 16.3927 3.94063C16.6865 4.23438 16.8334 4.5875 16.8334 5V14C16.8334 14.4125 16.6865 14.7656 16.3927 15.0594C16.099 15.3531 15.7459 15.5 15.3334 15.5H3.33337ZM9.33337 10.25L3.33337 6.5V14H15.3334V6.5L9.33337 10.25ZM9.33337 8.75L15.3334 5H3.33337L9.33337 8.75ZM3.33337 6.5V5V14V6.5Z"
                          fill="#fff"
                        />
                      </g>
                    </svg>{" "}
                    info@mountainmysteries.com
                  </span>
                  <span>
                    {" "}
                    <svg
                      width="19"
                      height="19"
                      viewBox="0 0 19 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_2263_1006"
                        style={{
                          maskType: "alpha",
                          maskUnits: "userSpaceOnUse",
                          x: "0",
                          y: "0",
                          width: "18",
                          height: "19",
                        }}
                      >
                        <rect
                          x="0.666626"
                          y="0.5"
                          width="18"
                          height="18"
                          fill="#D9D9D9"
                        />
                      </mask>
                      <g mask="url(#mask0_2263_1006)">
                        <path
                          d="M9.66663 9.5C10.0791 9.5 10.4323 9.35313 10.726 9.05938C11.0198 8.76563 11.1666 8.4125 11.1666 8C11.1666 7.5875 11.0198 7.23438 10.726 6.94063C10.4323 6.64687 10.0791 6.5 9.66663 6.5C9.25413 6.5 8.901 6.64687 8.60725 6.94063C8.3135 7.23438 8.16663 7.5875 8.16663 8C8.16663 8.4125 8.3135 8.76563 8.60725 9.05938C8.901 9.35313 9.25413 9.5 9.66663 9.5ZM9.66663 15.0125C11.1916 13.6125 12.3229 12.3406 13.0604 11.1969C13.7979 10.0531 14.1666 9.0375 14.1666 8.15C14.1666 6.7875 13.7323 5.67188 12.8635 4.80312C11.9948 3.93438 10.9291 3.5 9.66663 3.5C8.40413 3.5 7.3385 3.93438 6.46975 4.80312C5.601 5.67188 5.16663 6.7875 5.16663 8.15C5.16663 9.0375 5.53538 10.0531 6.27288 11.1969C7.01038 12.3406 8.14163 13.6125 9.66663 15.0125ZM9.66663 17C7.65413 15.2875 6.151 13.6969 5.15725 12.2281C4.1635 10.7594 3.66663 9.4 3.66663 8.15C3.66663 6.275 4.26975 4.78125 5.476 3.66875C6.68225 2.55625 8.07913 2 9.66663 2C11.2541 2 12.651 2.55625 13.8573 3.66875C15.0635 4.78125 15.6666 6.275 15.6666 8.15C15.6666 9.4 15.1698 10.7594 14.176 12.2281C13.1823 13.6969 11.6791 15.2875 9.66663 17Z"
                          fill="#fff"
                        />
                      </g>
                    </svg>{" "}
                    Ground Floor, Gupta Niwas, near Petrol Pump, Vikasnagar,
                    Shimla Himachal Pradesh 171009
                  </span>
                  <div className="flex align_items_center mt-10">
                    <Link to="https://www.facebook.com/mountainmysteries.in">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10.5" r="10" fill="#fff" />
                        <path
                          d="M11 11.25H12.25L12.75 9.25H11V8.25C11 7.735 11 7.25 12 7.25H12.75V5.57C12.587 5.5485 11.9715 5.5 11.3215 5.5C9.964 5.5 9 6.3285 9 7.85V9.25H7.5V11.25H9V15.5H11V11.25Z"
                          fill="#016937"
                        />
                      </svg>
                    </Link>
                    <Link to="https://www.instagram.com/mountainmysteries/">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10.5" r="10" fill="#fff" />
                        <path
                          d="M7.9 5.5H12.1C13.7 5.5 15 6.8 15 8.4V12.6C15 13.3691 14.6945 14.1068 14.1506 14.6506C13.6068 15.1945 12.8691 15.5 12.1 15.5H7.9C6.3 15.5 5 14.2 5 12.6V8.4C5 7.63087 5.30553 6.89325 5.84939 6.34939C6.39325 5.80553 7.13087 5.5 7.9 5.5ZM7.8 6.5C7.32261 6.5 6.86477 6.68964 6.52721 7.02721C6.18964 7.36477 6 7.82261 6 8.3V12.7C6 13.695 6.805 14.5 7.8 14.5H12.2C12.6774 14.5 13.1352 14.3104 13.4728 13.9728C13.8104 13.6352 14 13.1774 14 12.7V8.3C14 7.305 13.195 6.5 12.2 6.5H7.8ZM12.625 7.25C12.7908 7.25 12.9497 7.31585 13.0669 7.43306C13.1842 7.55027 13.25 7.70924 13.25 7.875C13.25 8.04076 13.1842 8.19973 13.0669 8.31694C12.9497 8.43415 12.7908 8.5 12.625 8.5C12.4592 8.5 12.3003 8.43415 12.1831 8.31694C12.0658 8.19973 12 8.04076 12 7.875C12 7.70924 12.0658 7.55027 12.1831 7.43306C12.3003 7.31585 12.4592 7.25 12.625 7.25ZM10 8C10.663 8 11.2989 8.26339 11.7678 8.73223C12.2366 9.20107 12.5 9.83696 12.5 10.5C12.5 11.163 12.2366 11.7989 11.7678 12.2678C11.2989 12.7366 10.663 13 10 13C9.33696 13 8.70107 12.7366 8.23223 12.2678C7.76339 11.7989 7.5 11.163 7.5 10.5C7.5 9.83696 7.76339 9.20107 8.23223 8.73223C8.70107 8.26339 9.33696 8 10 8ZM10 9C9.60218 9 9.22064 9.15804 8.93934 9.43934C8.65804 9.72064 8.5 10.1022 8.5 10.5C8.5 10.8978 8.65804 11.2794 8.93934 11.5607C9.22064 11.842 9.60218 12 10 12C10.3978 12 10.7794 11.842 11.0607 11.5607C11.342 11.2794 11.5 10.8978 11.5 10.5C11.5 10.1022 11.342 9.72064 11.0607 9.43934C10.7794 9.15804 10.3978 9 10 9Z"
                          fill="#016937"
                        />
                      </svg>
                    </Link>

                    <Link to="https://www.youtube.com/channel/UCLCvoKy9UnH6f8nvax44R8w">
                      <svg
                        width="25"
                        height="25"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="10" cy="10.5" r="10" fill="#fff" />
                        <path
                          d="M13.8035 7.9975C13.7655 7.8485 13.6575 7.736 13.534 7.7015C13.315 7.64 12.2505 7.5 10.0005 7.5C7.7505 7.5 6.6865 7.64 6.466 7.7015C6.344 7.7355 6.236 7.848 6.1975 7.9975C6.143 8.2095 6.0005 9.0975 6.0005 10.5C6.0005 11.902 6.143 12.79 6.1975 13.003C6.2355 13.1515 6.3435 13.264 6.4665 13.298C6.6865 13.36 7.75 13.5 10 13.5C12.25 13.5 13.3145 13.36 13.5345 13.2985C13.6565 13.2645 13.7645 13.152 13.803 13.0025C13.858 12.7905 14 11.9 14 10.5C14 9.1 13.858 8.21 13.8035 7.9975ZM14.7715 7.749C15 8.64 15 10.5 15 10.5C15 10.5 15 12.36 14.7715 13.251C14.6445 13.7435 14.273 14.131 13.8025 14.262C12.9485 14.5 10 14.5 10 14.5C10 14.5 7.0535 14.5 6.1975 14.262C5.725 14.129 5.354 13.742 5.2285 13.251C5 12.36 5 10.5 5 10.5C5 10.5 5 8.64 5.2285 7.749C5.3555 7.2565 5.727 6.869 6.1975 6.738C7.054 6.5 10.0005 6.5 10.0005 6.5C10.0005 6.5 12.9485 6.5 13.803 6.738C14.275 6.871 14.6465 7.258 14.772 7.749M9.0005 12.25V8.75L12.0005 10.5L9.0005 12.25Z"
                          fill="#016937"
                        />
                      </svg>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="form_div">
              <form action="" onSubmit={handleSubmit}>
                <div className="input-flex">
                  <div>
                    <label htmlFor="">Name:</label>
                    <input
                      style={
                        formErrors.name && {
                          backgroundColor: "#ff000040",
                          border: "1px solid red",
                        }
                      }
                      type="text"
                      className="w-full"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="">Email Address:</label>
                    <input
                      style={
                        formErrors.email && {
                          backgroundColor: "#ff000040",
                          border: "1px solid red",
                        }
                      }
                      type="text"
                      className="w-full"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="input-flex">
                  <div>
                    <label htmlFor="source_type">Are you Agent:</label>
                    <select
                      style={
                        formErrors.source_type && {
                          backgroundColor: "#ff000040",
                          border: "1px solid red",
                        }
                      }
                      className="w-full"
                      name="source_type"
                      value={formData.source_type}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="agent">Yes</option>
                      <option value="customer">No</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="type">Type:</label>
                    <select
                      style={
                        formErrors.source_type && {
                          backgroundColor: "#ff000040",
                          border: "1px solid red",
                        }
                      }
                      className="w-full"
                      name="type"
                      value={formData.type}
                      onChange={handleInputChange}
                    >
                      <option value="" disabled>
                        Select an option
                      </option>
                      <option value="package">Package</option>
                      <option value="activity">Activity</option>
                      <option value="transport">Transport</option>
                      <option value="hotel">Hotel</option>
                      <option value="other">Other</option>
                    </select>
                  </div>
                </div>
                <label htmlFor="">Message:</label>
                <textarea
                  style={
                    formErrors.message && {
                      backgroundColor: "#ff000040",
                      border: "1px solid red",
                    }
                  }
                  name="message"
                  value={formData.content}
                  onChange={handleInputChange}
                  id=""
                  className="w-full mt-5"
                  rows={3}
                ></textarea>
                <button
                  className="primary_btn mt-10"
                  style={{ width: "120px" }}
                  type="submit"
                >
                  Send Message
                </button>
              </form>
            </div> */}
          </div>
        </div>
      </section>
      <section className="common_padding">
        <div className="mt-20">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19329.323566599684!2d77.1652166431102!3d31.082651808258305!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3905827257abaaab%3A0x2cc0362740358b96!2sMountain%20Mysteries!5e0!3m2!1sen!2sin!4v1722243527259!5m2!1sen!2sin"
            width="100%"
            height="340"
            style={{ border: "0" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ContactUs;
