import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Header from "../../CustomComponents/Header/Header";
import Footer from "../../CustomComponents/Footer/Footer";
import CustomListingCard from "../../CustomComponents/CustomListingCard/CustomListingCard";
import FilterHoliday from "../../CustomComponents/FilterSection/FilterHoliday";
import NoDataFound from "../../CustomComponents/NoDataFound/NoDataFound";
import SendEnquiryPopup from "../../CustomComponents/SendEnquiryPopup/SendEnquiryPopup";
import Slider from "react-slick";
import imgNotFound from "../../assets/images/single-image.png";
import NewSendEnquiryPopup from "../../CustomComponents/NewSendEnquiryPopup/NewSendEnquiryPopup";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} arrowRight slideArrows`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} arrowLeft slideArrows`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    />
  );
}

function AllThemesPackages() {
  const [singleTheme, setSingleTheme] = useState([]);
  const [themeName, setThemeName] = useState("");
  const location = useLocation();
  const { id, handle } = useParams();
  const [showSendEnquiryPopup, setShowSendEnquiryPopup] = useState(false);

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   setThemeName(query.get("themename"));
  //   if (id) {
  //     fetchThemesPkg(id);
  //   }
  // }, [id, location.search]);

  const fetchThemesPkg = (id) => {
    const myHeaders = new Headers();
    myHeaders.append(
      "key",
      process.env.REACT_APP_BASE_API_KEY
    );
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/themes/single/${handle}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSingleTheme(data?.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching  details:", error);
      });
  };

  useEffect(() => {
    fetchThemesPkg();
  }, []);

  const settings = {
    dots: false,
    infinite: singleTheme?.themeImages > 1,
    speed: 500,
    slidesToShow: Math.min(singleTheme?.themeImages, 3),
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: "40px",
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const slider = React.useRef(null);

  const [markupData, setMarkupData] = useState(null);

  const fetchMarkup = async () => {
    const myHeaders = new Headers();
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/markups`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          const findObject = result?.data?.find(
            (mark) => mark?.markupCustomer === "both"
          );
          setMarkupData(findObject || null);
        } else {
          setMarkupData("No data found");
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchMarkup();
  }, []);

  return (
    <>
      <Header />
      <div className="container pb_30" id="themePackages">
        <div className="common_padding">
          <h3 className="text-left" style={{ textTransform: "capitalize" }}>
            {singleTheme?.title}
          </h3>
          {singleTheme?.themeImages && singleTheme?.themeImages.length > 0 && (
            <div className="singleActivity">
              <Slider ref={slider} {...settings} className="mt-10">
                {singleTheme?.themeImages?.map((item, index) => {
                  return (
                    <div className="activity-slider" key={index}>
                      <img src={item} alt="" />
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
          <div
            className="pt_10"
            dangerouslySetInnerHTML={{ __html: singleTheme?.content }}
          ></div>
        </div>
        <div className="">
          {singleTheme?.themePackages &&
            singleTheme.themePackages.length > 0 && (
              <>
                <h3
                  className="text-left mt-20"
                  style={{ textTransform: "capitalize" }}
                >
                  <span>Showing All Packages in {themeName} Theme </span>
                </h3>
                <div className="cards-flex ">
                  {singleTheme?.themePackages?.map((data, index) => (
                    <CustomListingCard
                      key={index}
                      imgSrc={data?.featuredImage ? data?.featuredImage : imgNotFound}
                      title={data.packageName}
                      packageHub={data.packageHub}
                      duration={data.totalDays}
                      nightDuration={data.totalNights}
                      price={data.startingPrice}
                      bookingLink={data.bookingLink}
                      detailsLink={data.detailsLink}
                      packagehandle={data.packagehandle}
                      canBookable={data?.canBookable}
                      packageId={data.packageId}
                      setShowSendEnquiryPopup={setShowSendEnquiryPopup}
                      markupData={markupData}
                      NoPax={data?.NoPax}
                    />
                  )
                  )}
                </div>
              </>
            )}
        </div>
        {/* <main>
                    <FilterHoliday />
                </main> */}
      </div>

      {/* {showSendEnquiryPopup && (
        <SendEnquiryPopup
          package_id={""}
          setshowRatePopup={setShowSendEnquiryPopup}
          typeDetail="package"
        />
      )} */}

      {showSendEnquiryPopup && (
        <NewSendEnquiryPopup
          package_id={""}
          setshowRatePopup={setShowSendEnquiryPopup}
          typeDetail="package"
        />
      )}
      <Footer />
    </>
  );
}

export default AllThemesPackages;
