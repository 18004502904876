import React, { useEffect, useState } from "react";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import styles from "./PackageQueryForm.module.scss";
import locationIcon from "../../assets/images/location_icon.png";
import destinationIcon from "../../assets/images/experienc_icon.png";
import securityIcon from "../../assets/images/security_icon.png";
import customerIcon from "../../assets/images/customer_icon.png";
import MultiSelectInputSingleId from "../MultiSelectInputSingleId/MultiSelectInputSingleId";

const PackageQueryForm = ({ package_id, setshowRatePopup, typeDetail, packageData, guest, selectedCategory, children, childAge }) => {
    const [showRatePopup, setShowRatePopup] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const [loader, setLoader] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        travel_date: "",
        start_date_information: "",
        end_date_information: "",
        end_Date: "",
        source: "website",
        adult: "",
        children_information: "",
        time: "",
        content: "",
        source_type: "",
        guide_required: "",

        hotel_category_information: "",
        room_category_information: "",
        type: typeDetail ? typeDetail : "package",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleShowRatePopup = (e) => {
        e.stopPropagation();
        setshowRatePopup(false);
    };

    const postDataHandle = (e) => {
        e.preventDefault();
        const myHeaders = new Headers();
        const valid = validate();
        setTimeout(() => {
            setFormErrors({});
        }, 1500);
        if (!valid) {
            showErrorToast("Please fill the required details");
            return;
        }

        setTimeout(() => {
            setFormErrors({});
        }, 1000);

        myHeaders.append("Accept", "application/json");
        myHeaders.append("key", process.env.REACT_APP_API_KEY);

        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            leadName: formData?.company_name,

            leadPhone: formData?.phone,

            leadSubject: formData?.subject,

            leadEmail: formData?.email,

            package_id: package_id,

            leadDetail: formData?.querry_detail,

            leadSource: "Website",

            query_company: formData?.name,

            query_type: "agent",

            travel_date: formData?.travel_date,

            leadSubject: "Enquiry",

            type: typeDetail ? typeDetail : "",

            adult: formData?.adult,

            time: formData?.time,
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_APP_URL}/api/v1/send-query`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    // console.log(result);
                    showSuccessToast("Enquiry sent successfully");

                    setTimeout(() => {
                        window.location.reload();
                    }, 200);

                    setShowRatePopup(false);
                }
            })

            .catch((error) => console.error(error));
    };

    const validate = () => {
        const errors = {};
        // Add validation logic for each form field
        // if (currentStep === 1) {
        if (!formData.name.trim()) {
            errors.name = "required";
        }
        if (!formData.company_name.trim()) {
            errors.company_name = "required";
        }
        if (!formData.phone.trim()) {
            errors.phone = "is required";
        }
        if (!formData.querry_detail) {
            errors.querry_detail = "required";
        }
        if (!formData.email) {
            errors.email = "required";
        }
        if (!formData.travel_date) {
            errors.travel_date = "required";
        }
        // }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
        myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

        const destinationIds = packageData?.packageDestination?.map(dest => dest.destinationId);

        const firstDestination = destinationIds?.length > 0 ? destinationIds[0] : null;
        const lastDestination = destinationIds?.length > 0 ? destinationIds[destinationIds.length - 1] : null;

        const rawData = {
            name: formData?.name,
            email: formData?.email,
            phone_information: formData?.phone,
            travel_date_information: formData?.travel_date,
            guide_required_information: formData?.guide_required,
            content: formData?.content,
            source: formData?.source,
            source_type: formData?.source_type,
            type: formData?.type,


            adults_information: guest ? guest : 2,
            children_information: children,
            child_age_information: childAge,
            start_date_information: formData.start_date_information,
            end_date_information: formData.end_date_information,
            hotel_category_information: [selectedCategory],
            room_category_information: [selectedCategory],
            destination_start_information: firstDestination,
            destination_end_information: lastDestination,
            all_destinations_information: destinationIds
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/query/create`,
                {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(rawData),
                    redirect: "follow",
                }
            );
            const result = await response.json();
            if (result.success) {
                showSuccessToast(result.message || "Form submitted successfully!");
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    travel_date: "",
                    adult: "",
                    time: "",
                    source: "website",
                    content: "",
                    source_type: "",
                });
                setshowRatePopup(false);
            } else {
                showErrorToast(
                    result.message || "An error occurred. Please try again."
                );
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    travel_date: "",
                    adult: "",
                    time: "",
                    source: "website",
                    content: "",
                    source_type: "",
                });
            }
        } catch (error) {
            showErrorToast("An error occurred. Please try again.");
            console.error(error);
        }
    };

    // const fetchDestinations = async () => {
    //     setLoader(true);
    //     const myHeaders = new Headers();
    //     myHeaders.append("Accept", "application/json");
    //     myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    //     const formData = new FormData();
    //     // formData.append("limit", 10);

    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         redirect: "follow",
    //         body: formData,
    //     };

    //     try {
    //         const response = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/api/v1/destinations/fetch`,
    //             requestOptions
    //         );
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }
    //         const result = await response.json();
    //         setDestinationData(result?.data);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     } finally {
    //         setLoader(false);
    //     }
    // };

    // useEffect(() => {
    //     fetchDestinations();
    // }, []);

    // console.log(destinationData, 'destinationData');

    const AccommodationCategoryOptions = [
        {
            label: "Budget",
            value: "budget",
            id: "budget"
        },
        {
            label: "Standard",
            value: "standard",
            id: "standard"
        },
        {
            label: "Deluxe",
            value: "deluxe",
            id: "deluxe"
        },
        {
            label: "Super Deluxe",
            value: "super-deluxe",
            id: "super-deluxe"
        },
        {
            label: "Luxury",
            value: "luxury",
            id: "luxury"
        },
        {
            label: "Premium",
            value: "premium",
            id: "premium"
        },
    ];

    const RoomCategoryOptions = [
        {
            label: "Budget",
            value: "budget",
            id: "budget"
        },
        {
            label: "Standard",
            value: "standard",
            id: "standard"
        },
        {
            label: "Deluxe",
            value: "deluxe",
            id: "deluxe"
        },
        {
            label: "Super Deluxe",
            value: "super-deluxe",
            id: "super-deluxe"
        },
        {
            label: "Luxury",
            value: "luxury",
            id: "luxury"
        },
        {
            label: "Premium",
            value: "premium",
            id: "premium"
        },
    ];

    return (
        <div className="bg-backdrop">
            <div className={styles.new_popup_flex}>
                <div className={styles.new_popup_colored}>
                    <div className="flex align_items_center h-full">
                        <div>
                            <h4
                                style={{
                                    fontSize: "25px",
                                    fontWeight: "600",
                                    color: "#333",
                                    textAlign: "left",
                                }}
                            >
                                Over 22+ Years of Experience
                            </h4>
                            <div className={styles.choose_us}>
                                <div className={styles.highlight}>
                                    <img src={locationIcon} alt="location-icon" />
                                    <span>All in one platform</span>
                                </div>
                                <div className={styles.highlight}>
                                    <img src={destinationIcon} alt="destination-icon" />
                                    <span>Expertise in Mountain Destinations</span>
                                </div>
                                <div className={styles.highlight}>
                                    <img src={securityIcon} alt="security-icon" />
                                    <span> Security</span>
                                </div>
                                <div className={styles.highlight}>
                                    <img src={customerIcon} alt="customer-icon" />
                                    <span>24x7 Customer Support</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={styles.new_popup_form}>
                    <h4
                        style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            color: "#BB735D",
                            textAlign: "left",
                        }}
                    >
                        Our expert will get in touch with you shortly
                    </h4>
                    <div
                        className="dashboard_checkin_modal_close_btn"
                        onClick={handleShowRatePopup}
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                        >
                            <path
                                d="M1 12.7464L13 0.851318"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                            />
                            <path
                                d="M13 12.7463L0.999999 0.851296"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                            />
                        </svg>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginTop: "20px",
                        }}
                    >
                        <div className="flex flex_gap_10">
                            <div className={styles.package_query_input_50}>
                                <label htmlFor="name">Name:</label>
                                <input
                                    // required
                                    className="w-full"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder="Your Name"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.name && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>

                            <div className={styles.package_query_input_50}>
                                <label htmlFor="email">Email:</label>
                                <input
                                    className="w-full"
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder="Email"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.email && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex flex_gap_10">
                            <div className={styles.package_query_input_50}>
                                <label htmlFor="phone">Phone:</label>
                                <input
                                    className="w-full"
                                    type="number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    placeholder="Phone"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.phone && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>
                            <div className={styles.package_query_input_50}>
                                <label htmlFor="source_type">Are you Agent:</label>
                                <select
                                    style={{
                                        padding: "10px",
                                        ...(formErrors.source_type && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                    className="w-full"
                                    name="source_type"
                                    value={formData.source_type}
                                    onChange={handleInputChange}
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    <option value="agent">Yes</option>
                                    <option value="customer">No</option>
                                </select>
                            </div>
                        </div>

                        <div className="flex flex_gap_10">
                            <div className={styles.package_query_input_50}>
                                <label htmlFor="start_date_information">Start Date:</label>
                                <input
                                    className="w-full"
                                    type="date"
                                    name="start_date_information"
                                    value={formData.start_date_information}
                                    onChange={handleInputChange}
                                    placeholder="Start Date"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.start_date_information && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>
                            <div className={styles.package_query_input_50}>
                                <label htmlFor="end_date_information">End Date:</label>
                                <input
                                    className="w-full"
                                    type="date"
                                    name="end_date_information"
                                    value={formData.end_date_information}
                                    onChange={handleInputChange}
                                    placeholder="End Date"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.end_date_information && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>
                        </div>

                        {/* 

                            <div className={styles.package_query_input_33}>
                                <label htmlFor="start_destination">Start Destination:</label>
                                <select
                                    style={{
                                        padding: "10px",
                                        ...(formErrors.start_destination && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                    className="w-full"
                                    name="start_destination"
                                    value={formData.start_destination}
                                    onChange={handleInputChange}
                                >
                                    <option value="">
                                        Select an option
                                    </option>
                                    {destinationData && destinationData.slice(0, 10)?.map((item) => (
                                        <option value={item.name}>{item.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className={styles.package_query_input_33}>
                                <label htmlFor="end_destinnation">End Destination:</label>
                                <select
                                    style={{
                                        padding: "10px",
                                        ...(formErrors.end_destination && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                    className="w-full"
                                    name="end_destination"
                                    value={formData.end_destination}
                                    onChange={handleInputChange}
                                >
                                    <option value="">
                                        Select an option
                                    </option>
                                    {destinationData && destinationData.map((item) => (
                                        <option value={item.name}>{item.name}</option>
                                    ))}
                                </select>
                            </div> */}

                        {/* <div className={styles.package_query_input_33}>
                                <MultiSelectInputSingleId
                                    selectById={true}
                                    showById={false}
                                    multiSelectInputData={AccommodationCategoryOptions}
                                    getDataFromMultiSelectInput={(data) => {
                                        console.log("data", data);
                                        setFormData({
                                            ...formData,
                                            hotel_category_information: data,
                                        });
                                    }}
                                    value={formData?.hotel_category_information}
                                    label="Hotel Categories"
                                />
                            </div>
                            <div className={styles.package_query_input_33}>
                                <MultiSelectInputSingleId
                                    selectById={true}
                                    showById={false}
                                    multiSelectInputData={RoomCategoryOptions}
                                    getDataFromMultiSelectInput={(data) => {
                                        console.log("data", data);
                                        setFormData({
                                            ...formData,
                                            room_category_information: data,
                                        });
                                    }}
                                    value={formData?.room_category_information}
                                    label="Room Categories"
                                />
                            </div>

                            <div className={styles.package_query_input_33}>
                                <MultiSelectInputSingleId
                                    selectById={true}
                                    showById={false}
                                    multiSelectInputData={allDestinationsArray}
                                    getDataFromMultiSelectInput={(data) => {
                                        console.log("data", data);
                                        setFormData({
                                            ...formData,
                                            all_destinations_information: data,
                                        });
                                    }}
                                    value={formData?.all_destinations_information}
                                    label="All Destinations"
                                />
                            </div> */}

                        {/* <div className="flex flex_gap_10">
                            <div className={styles.package_query_input_33}>
                                <label htmlFor="source_type">Adult:</label>
                                <input
                                    className="w-full"
                                    type="number"
                                    name="adult"
                                    value={formData.adult}
                                    onChange={handleInputChange}
                                    placeholder="Adult"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.adult && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>
                            <div className="activity_inputs_query_forms">
                                <label htmlFor="source_type">Children:</label>
                                <input
                                    className="w-full"
                                    type="number"
                                    name="children"
                                    value={formData.children_information}
                                    onChange={handleInputChange}
                                    placeholder="Children"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.children && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>
                        </div> */}

                        {/* {typeDetail === "activity" && (
                            <div className="flex flex_gap_10">
                                <div className="activity_inputs_query_forms">
                                    <label htmlFor="guide_required">Do you need Guide:</label>
                                    <select
                                        style={{
                                            padding: "10px",
                                            ...(formErrors.guide_required && {
                                                backgroundColor: "#ff000040",
                                                border: "1px solid red",
                                            }),
                                        }}
                                        className="w-full"
                                        name="guide_required"
                                        value={formData.guide_required}
                                        onChange={handleInputChange}
                                    >
                                        <option value="" disabled>
                                            Select an option
                                        </option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                                <div className="activity_inputs_query_forms">
                                    <label htmlFor="source_type">Time:</label>
                                    <input
                                        className="w-full"
                                        type="time"
                                        name="time"
                                        value={formData.time}
                                        onChange={handleInputChange}
                                        placeholder="Time"
                                        style={{
                                            padding: "10px",
                                            borderRadius: "4px",
                                            border: "1px solid #ccc",
                                            ...(formErrors.time && {
                                                backgroundColor: "#ff000040",
                                                border: "1px solid red",
                                            }),
                                        }}
                                    />
                                </div>
                            </div>
                        )} */}

                        <div>
                            <label htmlFor="content">Query Detail:</label>
                            <textarea
                                className="w-full"
                                type="text-area"
                                name="content"
                                value={formData.content}
                                onChange={handleInputChange}
                                placeholder="Querry Detail"
                                style={{
                                    padding: "10px",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                    ...(formErrors.content && {
                                        backgroundColor: "#ff000040",
                                        border: "1px solid red",
                                    }),
                                }}
                            />
                        </div>
                        <div className="">
                            <button type="submit" className={styles.common_btn}>
                                SEND
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PackageQueryForm;
