import React, { useEffect, useState } from "react";
import sightseeingImg from "../../assets/images/Sightseeing.jpg";
import { Link } from "react-router-dom";
import StarRating from "../StarRating/StarRating";
import NoDataFound from "../NoDataFound/NoDataFound";
import imgNotFound from "../../assets/images/single-image.png";
import Skeleton from "react-loading-skeleton";

const AccommodationContent = ({
  singlePackageData,
  setSelectedCategory,
  selectedCategory,
  setSelectedMealPlan,
  selectedMealPlan,
  loader,
  allPackageHotels,
  packageRates,
  ratesOfPackages,
  // roomsDetails,
  // setRoomsDetails
}) => {
  const [expandedCard, setExpandedCard] = useState(selectedCategory);
  const [selectedCard, setSelectedCard] = useState(0);

  const toggleCard = (index, e) => {
    e.stopPropagation();
    if (expandedCard === index) {
      setExpandedCard(null); // Collapse the card if it's already open
    } else {
      setExpandedCard(index); // Expand the clicked card
    }
  };

  const toggleSelect = (index, category) => {
    setSelectedCard(index); // Toggle the selected card
    setSelectedCategory(category);
    setExpandedCard(category);
    window.scrollTo({ top: 0, behavior: 'smooth' })
  };

  // const groupByCategory = (accommodations) => {
  //   return (
  //     accommodations &&
  //     accommodations?.reduce((acc, accommodation) => {
  //       const category = accommodation.hotelcategory;
  //       if (!acc[category]) {
  //         acc[category] = [];
  //       }
  //       acc[category].push(accommodation);
  //       return acc;
  //     }, {})
  //   );
  // };

  const groupByCategory = (accommodations,) => {
    if (!accommodations) return {};

    const grouped = accommodations.reduce((acc, accommodation) => {
      const category = accommodation.hotelcategory;
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(accommodation);
      return acc;
    }, {});

    // Sort the categories to ensure "Deluxe" is first
    const sortedCategories = Object.keys(grouped).sort((a, b) => {
      if (a === selectedCategory) return -1; // Move "Deluxe" to the top
      if (b === selectedCategory) return 1;  // Keep "Deluxe" above other categories
      return 0;                      // Maintain the existing order for others
    });

    // Reconstruct the grouped object with "Deluxe" first
    return sortedCategories.reduce((sortedAcc, category) => {
      sortedAcc[category] = grouped[category];
      return sortedAcc;
    }, {});
  };

  // useEffect(() => {
  //   // setSelectedCategory("Accommodation");

  //   const selectedArray = groupByCategory(singlePackageData?.packageHotels)[selectedCategory];
  //   if(selectedArray?.length > 0){
  //     const findMeaPlan = selectedArray[0]?.accommodationMealPlan;
  //     setSelectedMealPlan(findMeaPlan);
  //     // console.log(findMeaPlan, "findMeaPlan");
  //   }

  // }, [selectedCategory]);

  useEffect(() => {
    if (!singlePackageData || !selectedCategory || !allPackageHotels) {
      console.error(
        "Missing data: singlePackageData or selectedCategory is undefined."
      );
      return;
    }

    const groupedData = groupByCategory(allPackageHotels);

    if (!groupedData || !groupedData[selectedCategory]) {
      console.error(
        `Error: groupByCategory returned undefined or no data for category ${selectedCategory}.`
      );
      return;
    }

    const selectedArray = groupedData[selectedCategory];

    if (selectedArray.length === 0) {
      console.warn(
        `No data found for the selected category: ${selectedCategory}`
      );
      return;
    }

    // const findMealPlan = selectedArray[0]?.accommodationMealPlan;

    if (selectedMealPlan) {
      // setSelectedMealPlan(selectedMealPlan);
    } else {
      console.warn("No meal plan found in the selected category.");
    }
  }, [selectedCategory, singlePackageData]);

  const data = groupByCategory(allPackageHotels);

  const setMealPlan = (selectedMealPlanObj) => {
    const findObj = packageRates.find((rate) => rate.accommodationType === selectedMealPlanObj)
    if (!findObj) {
      setSelectedMealPlan(selectedMealPlan)
      return;
    }
    // setSelectedMealPlan(findObj?.mealPlan)
  };

  // const [roomsDetails, setRoomsDetails] = useState({})

  // const fetchRoomDetails = async (hotelId) => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Accept", "application/json");
  //   myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
  //   myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

  //   const requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/holidays/fetch/rooms/${ratesOfPackages?.RoomType}/${hotelId}/details`,
  //       requestOptions
  //     );
  //     const result = await response.json();

  //     if (result.data) {
  //       setRoomsDetails((prevDetails) => ({
  //         ...prevDetails,
  //         [hotelId]: result.data,
  //       }));
  //     }
  //   } catch (error) {
  //     console.error("Error fetching room details:", error);
  //   }
  // };

  // useEffect(() => {
  //   if (allPackageHotels?.length) {
  //     allPackageHotels.forEach((hotel) => {
  //       fetchRoomDetails(hotel.accommodationId);
  //     });
  //   }
  // }, [allPackageHotels, ratesOfPackages]);

  return (
    <>
      {loader ? (
        <div className="package-update-content-containers">
          <div>
            {Array.from({ length: 3 }).map((_, index) => (
              <div className="package-update-card">
                <div className="p_10">
                  <Skeleton width={100} />
                </div>
                <div
                  className="content-consider"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    maxHeight: "1000px",
                    overflow: "hidden",
                  }}
                >
                  {Array.from({ length: 5 }).map((_, index) => (
                    <div
                      className="card-content"
                      style={{
                        width: "calc(33.33% - 10px)",
                        boxSizing: "border-box",
                        border: "1px solid rgb(221, 221, 221)",
                        padding: "10px",
                      }}
                    >
                      <Skeleton height={150} width={225} />
                      <Skeleton width={80} />
                      <div className="flex justify_content_between">
                        <Skeleton width={50} />
                        <Skeleton width={30} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          {allPackageHotels && allPackageHotels.length > 0 ? (
            <>
              {Object.keys(
                groupByCategory(allPackageHotels)
              ).map((category, index) => (
                <div
                  key={index}
                  className="package-update-card"
                  style={{ transition: "height 0.3s ease-out" }}
                >
                  <div
                    className="flex p_10"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={(e) => toggleCard(category, e)}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 9 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          cursor: "pointer",
                          transform:
                            expandedCard === category
                              ? "rotate(180deg)"
                              : "rotate(0deg)",
                          transition: "transform 0.3s",
                        }}
                      >
                        <path
                          d="M8.25 0.75L4.75 4.25L1.25 0.75"
                          stroke="#000"
                        />
                      </svg>{" "}
                      <div
                        className="flex text-capitalize"
                        style={{ alignItems: "center", gap: "4px" }}
                      >
                        {" "}
                        <b> {category} :</b>
                      </div>
                    </div>
                    <button
                      // onClick={() => [
                      //   toggleSelect(index, category),
                      //   // setSelectedCategory(category),
                      //   setMealPlan(category)
                      // ]}

                      onClick={() => {
                        toggleSelect(index, category);
                        setMealPlan(category);
                      }}
                      style={
                        selectedCategory !== category
                          ? {
                            cursor: "pointer",
                            border: "1px solid #BB735D",
                            color: "#BB735D",
                            textTransform: "capitalize",
                            background: "#fff",
                            borderRadius: "4px",
                            display: "inline-block",
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: "500",
                            padding: "5px 10px",
                          }
                          : { cursor: "pointer", color: "#016932" }
                      }
                    >
                      {selectedCategory === category ? "Selected" : "Select"}
                    </button>
                  </div>
                  <div
                    className="accomodation_card"
                    style={{
                      display: "flex",
                      gap: "10px",
                      maxHeight: expandedCard === category ? "1000px" : "0",
                      overflow: "hidden",
                      transition: "max-height 0.3s ease-out",
                    }}
                  >
                    {groupByCategory(allPackageHotels)[
                      category
                    ]?.map((itemData, itemIndex) => {
                      return (
                        <div
                          key={itemIndex}
                          className="card_content accomodation_img"
                          style={{
                            boxSizing: "border-box",
                            border: "1px solid #ddd",
                            padding: "10px",
                          }}
                        >
                          <img
                            src={
                              itemData.featuredImage
                                ? itemData.featuredImage
                                : imgNotFound
                            }
                            style={{ width: "100%", height: "120px" }}
                            alt={`Image ${itemIndex + 1}`}
                          />
                          <h6 className="py-5">{itemData.name}</h6>
                          <div className="flex justify_content_between flex_wrap">
                            <p className="font_12">
                              {itemData.city}
                            </p>
                            <p className="font_12">
                              {itemData.starRating} Stars
                            </p>
                          </div>
                          <div className="flex py-5 flex_gap_5">
                            <b className="font_12">Meal Plan: </b>
                            <p className="text-uppercase font_12">
                              {" "}
                              {selectedMealPlan}
                            </p>
                          </div>
                          <div className="flex flex_gap_5">
                            <b className="font_12">Room Category:</b>{" "}
                            <p className="text-capitalize font_12 text_right" >
                              {itemData?.roomDetails[0]?.name}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </>
  );
};

export default AccommodationContent;

// flexBasis: "calc(33.33% - 10px)",
// {
//   itemData?.accommodationRoom?.map((item, index) => {
//     return ( 

//  );
//   })
// }

// {
//   roomsDetails[itemData?.accommodationId] && (
//     <div>
//       {roomsDetails[itemData?.accommodationId].map((room, roomIndex) => (
//         <div className="flex flex_gap_5" key={roomIndex}>
//           <b className="font_12">Room Category:</b>{" "}
//           <p className="text-capitalize font_12 text_right" >
//             {room.RoomCategory}
//           </p>
//         </div>
//       ))}
//     </div>
//   )
// } 