import React, { useState, useEffect, useRef } from "react";
import './SearchStateInput.css'

const MultiSelectSearch = ({ type, onMultiSelectionChange, onClick, className, package_type, defaultSelected = [] }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [results, setResults] = useState([]);
    const [selectedDestinations, setSelectedDestinations] = useState(defaultSelected);
    
    const [isLoading, setIsLoading] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const popupRef = useRef(null);

    useEffect(() => {
        // Sync defaultSelected with selectedDestinations
        if (defaultSelected.length > 0) {
            setSelectedDestinations(defaultSelected);
        }
    }, [defaultSelected.length]);

    // Fetch destination API
    const fetchDestination = async (destination) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
        myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

        // const raw = JSON.stringify({ search: destination, type });

        const raw = JSON.stringify({
            search: destination,
            // type: type,
            // package_type: package_type
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        setIsLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/destinations/fetch`,
                requestOptions
            );
            const result = await response.json();
            if (result?.data) {
                setResults(result.data);
                setIsPopupOpen(true); // Show popup when results are available
            }
        } catch (error) {
            console.error("Error fetching destinations:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        if (query.trim()) {
            fetchDestination(query);
        } else {
            setResults([]);
            setIsPopupOpen(false);
        }
    };

    const handleToggle = (destination) => {
        setSelectedDestinations((prev) =>
            prev.includes(destination)
                ? prev.filter((d) => d !== destination)
                : [...prev, destination]
        );
    };

    useEffect(() => {
        if (onMultiSelectionChange) {
            onMultiSelectionChange(selectedDestinations);
        }
    }, [selectedDestinations, onMultiSelectionChange]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setIsPopupOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div
            className={`relative ${className} p_0`}
        >
            {selectedDestinations && selectedDestinations.length > 0 && (
                <div className="selected-destinations">
                    <span style={{ padding: defaultSelected.length > 0 ? '0 10px' : '0' }}>{selectedDestinations.join(", ")}</span>
                    <button
                        type="button"
                        className="clear-btn"
                        onClick={() => setSelectedDestinations([])}
                        aria-label="Clear Destinations"
                    >
                        ✖
                    </button>
                </div>
            )}

            <input
                type="text"
                placeholder="Type to search destinations"
                value={searchQuery}
                onChange={handleSearchChange}
                className={`search_form_input m_0 ${className}`}
                onClick={onClick}

            />
            {isLoading && <p>Loading...</p>}
            {isPopupOpen && results.length > 0 && (
                <div ref={popupRef} className="search_list">
                    {results.map((destination, index) => (
                        <div key={index} style={{ padding: "10px" }}>
                            <label style={{ display: "flex", alignItems: "center", cursor: 'pointer' }}>
                                <input
                                    type="checkbox"
                                    checked={selectedDestinations.includes(destination.name)}
                                    onChange={() => handleToggle(destination.name)}
                                    style={{ marginRight: "6px" }}
                                />
                                {destination.name}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelectSearch;
