import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./SinglePackageHeader.css";
import { showErrorToast } from "../../assets/toastUtils";
import GooglePlaceApi from "../GoogleApi/GooglePlaceApi/GooglePlaceApi";
import Flatpickr from "react-flatpickr";
import ButtonToDownloadTablePDF from "../ButtonToDownload/ButtonToDownload";
import PackageDetails from "../ButtonToDownload/PackageDetails";
import {
  calculateToDateMinus,
  calculateToDatePlus,
  currentDateSingle,
  formatDateToYYYYMMDD,
} from "../../utils/utils";
import SendEnquiryPopup from "../SendEnquiryPopup/SendEnquiryPopup";
import GetQuoteDownloadPopup from "../SendEnquiryPopup/GetQuoteDownloadPopup";

function SingleTransportHeader({ transportData, selectedCategory, allPackageHotels }) {

  const [modifyPkg, setModiyPkg] = useState(false);
  const [openGetQuotePopup, setOpenGetQuotePopup] = useState(false);
  const toggleModify = () => {
    setModiyPkg(!modifyPkg);
  };
  const [guestDetails, setIsguestDetails] = useState(false);
  const dropdownGuestDetails = () => {
    setIsguestDetails(!guestDetails);
  };

  const [selectedLocation, setSelectedLocation] = useState(null);
  const [data, setData] = useState({ fromCity: "", toCity: "" });
  // console.log(data, "Location");

  const showErrorToast = (message) => {
    alert(message);
  };

  const [bookingData, setBookingData] = useState({});
  const location = useLocation();


  useEffect(() => {
    const params = new URLSearchParams(location.search);
    // const childAges = [];
    // let i = 1;
    // while (params.has(`childAge[${i}]`)) {
    //   childAges.push(params.get(`childAge[${i}]`));
    //   i++;
    // }
    const data = {
      // location: params.get("location"),
      // room: params.get("room"),
      // guest: params.get("guest"),
      // children: params.get("children"),
      // day: params.get("day"),
      // month: params.get("month"),
      // year: params.get("year"),
      // packageHandle: params.get("packagehandle"),
      // radio: params.get("radio"),
      // childAge: childAges,

      date: params.get("date"),
      year: params.get("year"),
      month: params.get("month"),
      noOfDays: params.get("noOfDays"),
      startDestination: params.get("startdestination"),
      endDestination: params.get("enddestination"),
    };

    // const checkInDate = `${data.checkinYear}-${String(
    //   data.checkinMonth
    // ).padStart(2, "0")}-${String(data.checkinDay).padStart(2, "0")}`;
    // const checkOutDate = `${data.checkoutYear}-${String(
    //   data.checkoutMonth
    // ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;
    // const selectedDate = `${data.year}-${String(
    //   data.month
    // ).padStart(2, "0")}-${String(data.day).padStart(2, "0")}`;
    // setData({
    //   fromCity: params.get("location"),
    // });
    // setGuest(data.guest);
    // setChildren(data.children ? data.children : 0);
    // setRooms(data.room);
    // setChildAge(childAges);

    // if (data.year && data.month && data.day) {
    //   setStartDate(selectedDate);
    // } else {
    //   setStartDate(currentDateSingle);
    // }

    // setEndDate(checkOutDate);

    const travelDate = `${data.year}-${String(data.month).padStart(
      2,
      "0"
    )}-${String(data.date).padStart(2, "0")}`;
    setBookingData({ ...data, travelDate });
    setDate(travelDate);
    setNoOfDays(data?.noOfDays);
    setStartDestination(data?.startDestination);
    setEndDestination(data?.endDestination);
  }, [location.search]);

  const navigate = useNavigate();

  // STATES
  const [startDate, setStartDate] = useState(currentDateSingle);
  const [endDate, setEndDate] = useState("");

  const [selectedRadio, setSelectedRadio] = useState("");

  //
  const [guest, setGuest] = useState(1);
  const [children, setChildren] = useState(0);
  const [rooms, setRooms] = useState(1);
  const [childAge, setChildAge] = useState([]);

  const [startDestination, setStartDestination] = useState("");
  const [endDestination, setEndDestination] = useState("");
  const [noOfDays, setNoOfDays] = useState(null);
  const [date, setDate] = useState(currentDateSingle);
  // console.log(children, "children");

  // Date alterations
  const [loader, setLoader] = useState(false);

  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // Click is outside the dropdown, so close it
      // setIsguestDetails(false);
    }
  };

  // Effect to add click event listener when guestDetails is true
  useEffect(() => {
    if (guestDetails) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [guestDetails]);

  // functions for increase decrease
  const onChildDecrease = (e) => {
    e.preventDefault();
    const updatedChildren = Number(children) - 1;
    setChildren(updatedChildren);
    const updatedAges = childAge.slice(0, childAge.length - 1);
    setChildAge(updatedAges);
  };

  const onChildIncrease = (e) => {
    e.preventDefault();
    const updatedChildren = Number(children) + 1;
    setChildren(updatedChildren);
    setChildAge([...childAge, ""]);
  };

  const onAdultIncrease = () => {
    const newAdult = Number(guest) + 1;
    setGuest(newAdult);
    if (newAdult > 1 && newAdult % 2 === 0 && rooms < newAdult / 2) {
      setRooms(Number(rooms) + 1);
    }
  };

  const onAdultDecrease = () => {
    if (guest > 2) {
      const newAdult = Number(guest) - 1;
      setGuest(newAdult);
      if (newAdult % 2 !== 0 && rooms > 1) {
        setRooms(Number(rooms) - 1);
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
  };

  const handleChildrenAgeChange = (index, event) => {
    const updatedAges = [...childAge];
    const ageValue = event.target.value.replace(/[^0-9]/g, "");

    if (ageValue > 12) {
      showErrorToast("Age should be less than or equal to 12");
      return;
    }

    updatedAges[index] = ageValue !== "" ? parseInt(ageValue, 10) : "";
    setChildAge(updatedAges);
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    let hasChildAgeError = false;

    const sanitizedChildAges = childAge
      .map((age) => (age !== "" ? age : null))
      .filter((age) => age !== null);

    const childAgeParams = sanitizedChildAges
      .map((age, index) => {
        if (age > 12) {
          alert("Child age must be between 0 and 12");
          hasChildAgeError = true;
          return;
        }
        return `&childAge[${index + 1}]=${age}`;
      })
      .join("");

    if (hasChildAgeError) {
      alert("Child age must be between 0 and 12");
      return;
    }

    try {
      await navigate(
        `/transport/${transportData?.packageHandle}?packageId=${transportData?.packageId}&startdestination=${startDestination}&enddestination=${endDestination}&noOfDays=${noOfDays}&date=${new Date(startDate).getDate()}&month=${new Date(startDate).getMonth() + 1}&year=${new Date(startDate).getFullYear()}`,
        undefined,
        { shallow: false }
      );

      setModiyPkg(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoader(false);
    } finally {
      setLoader(false);
    }
  };

  const handleDateChange = ([start, end]) => {
    setStartDate(start);
    setEndDate(end);
  };

  const [filteredData, setFilteredData] = useState({
    location: "",
    rooms: "",
    adults: "",
    children: "",
    checkInDate: "",
    checkOutDate: "",
    radio: "",
    childAge: [],
  });

  useEffect(() => {
    setFilteredData({
      location: data.fromCity,
      rooms: rooms,
      guest: guest,
      children: children,
      checkInDate: startDate,
      checkOutDate: endDate,
      radio: selectedRadio,
      childAge: childAge,
    });
  }, [
    data.fromCity,
    rooms,
    guest,
    children,
    startDate,
    endDate,
    selectedRadio,
    childAge,
  ]);

  // Handle Date Change
  // const [modifyDate, setModifyDate] = useState({
  //   date: "",
  //   day: "",
  //   month: "",
  //   year: "",
  // });
  // console.log("modifyDate", modifyDate);

  // const handleModifyDateChange = (selectedDateValue) => {
  //   const newDate = new Date(selectedDateValue);
  //   setModifyDate({
  //     date: formatDateToYYYYMMDD(newDate),
  //     day: newDate.getDate(),
  //     month: newDate.getMonth() + 1,
  //     year: newDate.getFullYear(),
  //   });

  //   setDate({
  //     mainDate: newDate.toISOString().split("T")[0], // YYYY-MM-DD format
  //     setDay: newDate.getDate().toString().padStart(2, "0"), // Day with leading zero
  //     setMonth: (newDate.getMonth() + 1).toString().padStart(2, "0"), // 1-based month
  //     setYear: newDate.getFullYear().toString(), // Full year
  //   });
  // };

  return (
    <>
      <div className="flex Single_pkg_header-main flex_align_center">
        <div className="package_flex_gap">
          <div className="flex Single_pkg_header-head">
            <div
              onClick={() => navigate(-1)}
              className=" border-right-one flex_align_center p_10"
            >
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM1 9H16V7H1V9Z"
                    fill="#333333"
                  ></path>
                </svg>
              </span>
            </div>
            {transportData?.packageName &&
              transportData?.packageName !== "null" && (
                <div className="SinglePkgHeader_pkg-name Single_pkg_header-cards border-right-one ">
                  <div className="Single_pkg_header-form_content">
                    <label
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      htmlFor=""
                    >
                      <small
                        style={{
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        Package Name
                      </small>
                    </label>
                    <div>
                      <h1
                        className="SinglePkgHeader_pkg_text show_Half_package_title"
                        style={{
                          color: "black",
                          fontSize: "18px",
                          fontWeight: "500",
                        }}
                      >
                        {/* {transportData.packageName} */}
                        {transportData.packageName.length > 50
                          ? transportData.packageName.substring(0, 50) + "..."
                          : transportData.packageName}
                      </h1>
                    </div>
                  </div>
                </div>
              )}

            {bookingData?.location && bookingData?.location !== "null" && (
              <div
                className="Single_pkg_header-cards border-right-one "
              // onClick={toggleModify}
              >
                <div className="Single_pkg_header-form_content">
                  <label htmlFor="">
                    <small>From</small>
                  </label>
                  <div>
                    <h1
                      className="text-left"
                      style={{
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "500",
                        textTransform: "capitalize",
                      }}
                    >
                      {bookingData.location}
                    </h1>
                  </div>
                </div>
              </div>
            )}

            <div className="SinglePkgHeader_pkg-name Single_pkg_header-cards border-right-one ">
              <div className="Single_pkg_header-form_content">
                <label
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  htmlFor=""
                >
                  <small
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    Detarture Date
                  </small>
                </label>
                <div>
                  <h1
                    className="SinglePkgHeader_pkg_text"
                    style={{
                      color: "black",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    {date ? date : currentDateSingle}
                  </h1>
                </div>
              </div>
            </div>

            {/* <div className="SinglePkgHeader_pkg-name Single_pkg_header-cards border-right-one ">
              <div className="Single_pkg_header-form_content">
                <label
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  htmlFor=""
                >
                  <small
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                  >
                    No. of days
                  </small>
                </label>
                <div>
                  <h1
                    className="SinglePkgHeader_pkg_text"
                    style={{
                      color: "black",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    {noOfDays}
                  </h1>
                </div>
              </div>
            </div> */}

            {/* <div className="Single_pkg_header-cards ">
              <div className="Single_pkg_header-form_content">
                <label htmlFor="">Guests</label>
                <div className="Single_pkg_header-Guests">
                  <h1
                    className=""
                    style={{
                      color: "black",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  >
                    {bookingData.guest} <small> Guests, </small>
                    {bookingData.children} <small> Children</small>
                  </h1>
                </div>
              </div>
            </div> */}
          </div>

          <div className="flex Single_pkg_header-access">
            {/* <PackageDetails
              transportData={transportData}
              setOpenGetQuotePopup={setOpenGetQuotePopup}
              selectedCategory={selectedCategory}
              allPackageHotels={allPackageHotels}
            /> */}
            <div>
              <button onClick={toggleModify} className="Single_pkg_header-btn">
                <strong className="Single_pkg_header_modify">Modify</strong>
                <span className="Single_pkg_header_modify_icon">
                  <svg
                    className="m_0"
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_2797_7470"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                    >
                      <rect width="20" height="20" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_2797_7470)">
                      <path
                        d="M4.16667 15.8333H5.35417L13.5 7.6875L12.3125 6.5L4.16667 14.6458V15.8333ZM2.5 17.5V13.9583L13.5 2.97917C13.6667 2.82639 13.8507 2.70833 14.0521 2.625C14.2535 2.54167 14.4653 2.5 14.6875 2.5C14.9097 2.5 15.125 2.54167 15.3333 2.625C15.5417 2.70833 15.7222 2.83333 15.875 3L17.0208 4.16667C17.1875 4.31944 17.309 4.5 17.3854 4.70833C17.4618 4.91667 17.5 5.125 17.5 5.33333C17.5 5.55556 17.4618 5.76736 17.3854 5.96875C17.309 6.17014 17.1875 6.35417 17.0208 6.52083L6.04167 17.5H2.5ZM12.8958 7.10417L12.3125 6.5L13.5 7.6875L12.8958 7.10417Z"
                        fill="#333333"
                      />
                    </g>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {modifyPkg && (
        <div className="ScreenDimmer">
          <div className="container Single_pkg_header-modify">
            <button
              className="Single_pkg_header-close"
              onClick={() => [toggleModify(false)]}
            >
              X
            </button>
            <div className="Single_pkg_header-modify_main">
              <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
                Modify
              </h2>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="flex_align_center Single_pkg_header-resp Single_pkg_header-modify_head">
                  <div className="flex_align_center Single_pkg_header-resp-cards Single_pkg_header-resp-cards_content">
                    <div className="Single_pkg_header-cards  Single_pkg_header-modify_cards border-right-one ">
                      <div className="Single_pkg_header-form_content Single_pkg_header-input">
                        <label htmlFor="">
                          <small>Departure Date</small>
                        </label>
                        {/* <Flatpickr
                          className="w-full"
                          value={date ? date : currentDateSingle}
                          onChange={([date]) => setDate(date)}
                          options={{
                            minDate: "today",
                          }}
                        /> */}

                        <Flatpickr
                          className="w-full"
                          value={startDate ? startDate : currentDateSingle}
                          onChange={([date]) => setStartDate(date)}
                          options={{
                            minDate: "today",
                          }}
                        />
                      </div>
                    </div>

                    {/* <div className="Single_pkg_header-cards  Single_pkg_header-modify_cards border-right-one ">
                      <div className="Single_pkg_header-form_content Single_pkg_header-input">
                        <label htmlFor="">
                          <small>No. of Days</small>
                        </label>
                        <input
                          type="number"
                          value={noOfDays}
                          onChange={(e) => setNoOfDays(e.target.value)}
                          className="testform_input"
                        />
                      </div>
                    </div> */}

                    {/* <div
                      className="Single_pkg_header-cards Single_pkg_header-modify_cards"
                      style={{ padding: "0 30px 0 15px" }}
                    >
                      <label htmlFor="">Guests</label>
                      <p
                        style={{ position: "relative" }}
                        className="Single_pkg_header-input SingleSearch-Guests dropdown-toggle  "
                      >
                        <div
                          style={{ margin: "5px 0" }}
                          onClick={dropdownGuestDetails}
                          ref={dropdownRef}
                        >
                          <div className="optionCounter"></div>
                          <span
                            className="Single_pkg_header-guest_details"
                            style={{ fontWeight: "700" }}
                            id="adult_count"
                          >
                            {guest}
                          </span>
                          <span className="Single_pkg_header-guest_details">
                            {" "}
                            Guests,
                          </span>{" "}
                          <span
                            className="Single_pkg_header-guest_details"
                            style={{ fontWeight: "700" }}
                            id="child_count"
                          >
                            {children !== undefined && children > 0
                              ? children
                              : 0}
                          </span>
                          <span className="Single_pkg_header-guest_details">
                            {" "}
                            Child
                          </span>
                        </div>
                        {guestDetails && (
                          <div
                            className="guest_details_dropdown"
                            id="guestDetailsPopup"
                          >
                            <div className="agent">
                              <span>Guests</span>
                              <div>
                                <button
                                  type="button"
                                  disabled={guest <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={() => {
                                    if (Number(guest) <= 2) return;
                                    setGuest(Number(guest) - 1);
                                  }}
                                >
                                  <span
                                    className="onclick_btn"
                                    id="adult_minus"
                                  >
                                    -
                                  </span>
                                </button>

                                <input
                                  className="optionCounterNumber"
                                  value={guest}
                                  min={1}
                                />

                                <button
                                  type="button"
                                  // disabled={rooms <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={() => setGuest(Number(guest) + 1)}
                                >
                                  <span className="onclick_btn" id="adult_plus">
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div className="agent">
                              <span>Children</span>
                              <div>
                                <button
                                  type="button"
                                  disabled={children <= 0}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={onChildDecrease}
                                >
                                  <span
                                    className="onclick_btn"
                                    id="adult_minus"
                                  >
                                    -
                                  </span>
                                </button>

                                <input
                                  className="optionCounterNumber"
                                  value={children}
                                  min={1}
                                />

                                <button
                                  type="button"
                                  // disabled={children <= 1}
                                  className="optionCounterButton optionCounterButtonWithBorder"
                                  onClick={onChildIncrease}
                                >
                                  <span className="onclick_btn" id="adult_plus">
                                    +
                                  </span>
                                </button>
                              </div>
                            </div>

                            {childAge.map((age, index) => (
                              <div className="navbar agent">
                                <div>
                                  <span>Child Age</span>
                                </div>
                                <div>
                                  <input
                                    className="childAge_input"
                                    type="number"
                                    name="childAge[]"
                                    min="1"
                                    value={age}
                                    onChange={(event) =>
                                      handleChildrenAgeChange(index, event)
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </p>
                    </div> */}
                  </div>
                  <div
                    className="Single_pkg_header-cards  Single_pkg_header-resp-cards"
                    style={{ textAlign: "center" }}
                  >
                    <button type="submit" className="Single_pkg_header_apply">
                      Apply
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SingleTransportHeader;
