import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { showErrorToast } from "../../../assets/toastUtils";
import Flatpickr from "react-flatpickr";
import GooglePlaceApi from "../../GoogleApi/GooglePlaceApi/GooglePlaceApi";
import "../CustomSearchFormActivities/CustomSearchFormActivities.css";
import NewHeaderBg from "../../NewHeaderBg/NewHeaderBg";
import SearchStateInput from "../../SearchSateInput/SearchStateInput";
import NewSearchSateInput from "../../NewSearchSateInput/NewSearchSateInput";
import { currentDateSingle } from "../../../utils/utils";
import MultiSelectSearch from "../../NewSearchSateInput/MultiSelectSearch";
import PackagesStateInput from "../../PackagesStateInput/PackagesStateInput";
import TransportHubsSearchInput from "../../NewSearchSateInput/TransportHubsSearchInput";

function CustomSearchFormTransport({ topRadios }) {
    const [guestDetails, setIsguestDetails] = useState(false);
    const [hub, setHub] = useState("");

    const [destinationToggle, setdestinationToggle] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            // Click is outside the dropdown, so close it
            // setIsguestDetails(false);
        }
    };
    const [startDestination, setStartDestination] = useState("");
    const [endDestination, setEndDestination] = useState("");
    const [noOfDays, setNoOfDays] = useState("");

    const [selectedType, setSelectedType] = useState(null);
    // const [stateResultArray, setStateResultArray] = useState("");
    // console.log(stateResultArray);

    const getDataFromChild = (data, resultArray) => {
        setHub(data);
        // setSelectedType(selectedType);
        // setStateResultArray(resultArray)
    };

    const getDataFromChildForToCity = (data, resultArray, selectedType) => {
        setEndDestination(data);
        setSelectedType(selectedType);
        // setStateResultArray(resultArray)
    };
    // console.log(dataFromChild, "getDataFromChild");

    const dropdownGuestDetails = () => {
        setIsguestDetails(!guestDetails);
    };

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const checkinDay = queryParams.get("checkinDay") || "";
    const checkinMonth = queryParams.get("checkinMonth") || "";
    const checkinYear = queryParams.get("checkinYear") || "";
    const checkoutDay = queryParams.get("checkoutDay") || "";
    const checkoutMonth = queryParams.get("checkoutMonth") || "";
    const checkoutYear = queryParams.get("checkoutYear") || "";

    const [checkin, setCheckin] = useState({
        day: checkinDay,
        month: checkinMonth,
        year: checkinYear,
    });

    const [checkout, setCheckout] = useState({
        day: checkoutDay,
        month: checkoutMonth,
        year: checkoutYear,
    });

    const navigate = useNavigate();

    // STATES
    const [date, setDate] = useState(new Date());
    const [selectedRadio, setSelectedRadio] = useState("");

    const [data, setData] = useState({
        fromCity: "",
        toCity: "",
    });

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    //
    const [adult, setAdults] = useState(1);
    const [children, setChildren] = useState(0);
    const [rooms, setRooms] = useState(1);
    const [childAge, setChildAge] = useState([]);

    // Date alterations
    const [loader, setLoader] = useState(false);

    // const [checkin, setCheckin] = useState({
    //   day: router.query.checkinDay || "",
    //   month: router.query.checkinMonth || "",
    //   year: router.query.checkinYear || "",
    // });

    // const [checkout, setCheckout] = useState({
    //   day: router.query.checkoutDay || "",
    //   month: router.query.checkoutMonth || "",
    //   year: router.query.checkoutYear || "",
    // });

    useEffect(() => {
        if (
            checkinDay &&
            checkinMonth &&
            checkinYear &&
            checkoutDay &&
            checkoutMonth &&
            checkoutYear
        ) {
            const formatDateToString = (year, month, day) => {
                const paddedMonth = String(month).padStart(2, "0");
                const paddedDay = String(day).padStart(2, "0");
                return `${year}-${paddedMonth}-${paddedDay}`;
            };

            const checkInDateString = formatDateToString(
                checkinYear,
                checkinMonth,
                checkinDay
            );
            const checkOutDateString = formatDateToString(
                checkoutYear,
                checkoutMonth,
                checkoutDay
            );

            const checkInDate = new Date(checkInDateString);
            const checkOutDate = new Date(checkOutDateString);

            setStartDate(checkInDate);
            setEndDate(checkOutDate);
        }
    }, [
        checkinDay,
        checkinMonth,
        checkinYear,
        checkoutDay,
        checkoutMonth,
        checkoutYear,
    ]);

    // functions for increase decrease
    const onChildDecrease = (e) => {
        e.preventDefault();
        const updatedChildren = children - 1;
        setChildren(updatedChildren);
        const updatedAges = childAge.slice(0, childAge.length - 1);
        setChildAge(updatedAges);
    };

    const onChildIncrease = (e) => {
        e.preventDefault();
        const updatedChildren = children + 1;
        setChildren(updatedChildren);
        setChildAge([...childAge, ""]);
    };

    const onAdultIncrease = () => {
        const newAdult = adult + 1;
        setAdults(newAdult);
        if (newAdult > 1 && newAdult % 2 === 0 && rooms < newAdult / 2) {
            setRooms(rooms + 1);
        }
    };

    const onAdultDecrease = () => {
        if (adult > 1) {
            const newAdult = adult - 1;
            setAdults(newAdult);
            if (newAdult % 2 !== 0 && rooms > 1) {
                setRooms(rooms - 1);
            }
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
    };

    const handleChildrenAgeChange = (index, event) => {
        const updatedAges = [...childAge];
        const ageValue = event.target.value.replace(/[^0-9]/g, "");

        if (ageValue > 12) {
            showErrorToast("Age should be less than or equal to 12");
            return;
        }

        updatedAges[index] = ageValue !== "" ? parseInt(ageValue, 10) : "";
        setChildAge(updatedAges);
    };

    const [selectedMultipleDestinations, setSelectedMultipleDestinations] = useState([]);

    const handleMultiDestinationsChange = (selected) => {
        setSelectedMultipleDestinations(selected);
    };

    const handleSubmitButtonClickHandler = async (e) => {
        if (e) {
            e.preventDefault();
        }
        if (selectedMultipleDestinations.length === 0) {
            showErrorToast('Please select destinations!')
            return
        }

        setLoader(true);

        try {
            await navigate(
                `/transport/listing?startdestination=${hub}&noOfDays=${noOfDays}&date=${new Date(date).getDate()}&month=${new Date(date).getMonth() + 1}&year=${new Date(date).getFullYear()}&selectedDestination=${selectedMultipleDestinations?.length}`,
                {
                    state: {
                        includingDestinations: selectedMultipleDestinations,
                    },
                },
                undefined,
                { shallow: false }
            );
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoader(false);
        } finally {
            setLoader(false);
        }
    };

    const handleSubmitButtonClickHandlerTransport = async (e) => {
        if (e) {
            e.preventDefault();
        }
        setLoader(true);

        try {
            await navigate(
                `/transport/${(selectedType?.packagehandle)}?packageId=${selectedType?.id}&startdestination=${hub}&noOfDays=${noOfDays}&date=${new Date(date).getDate()}&month=${new Date(date).getMonth() + 1}&year=${new Date(date).getFullYear()}`,
                {
                    state: {
                        includingDestinations: selectedMultipleDestinations,
                    },
                },
                undefined,
                { shallow: false }
            );
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoader(false);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (destinationToggle) {
            document.body.classList.add("no-scroll");
        } else {
            document.body.classList.remove("no-scroll");
        }

        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, [destinationToggle]);

    return (
        <>
            <section className="NewSearch-BG">
                <div className="container">
                    {/* <NewHeaderBg /> */}
                    <div className="top-banner-img" id="activityBanner">
                        <div
                            className="NewSearchActivities-header"
                            id="width-NewSearch"
                            style={
                                guestDetails || destinationToggle
                                    ? { position: "absolute", zIndex: "9999" }
                                    : { position: "" }
                            }
                        >
                            <h1 className="text-center">
                                Himachal’s Transport & Experiences
                            </h1>
                            <p className="common_text">
                                Book Exciting Adventures & Experiences in Himachal
                            </p>
                            <div
                                style={
                                    guestDetails || destinationToggle
                                        ? { zIndex: "999" }
                                        : { position: "" }
                                }
                                className="NewSearch-Main search_form"
                                id="activitySearchForm"
                            >
                                <form
                                    className="search_form_main"
                                    // onSubmit={handleSubmitButtonClickHandlerTransport}

                                    onSubmit={
                                        selectedType?.type === "package"
                                            ? handleSubmitButtonClickHandlerTransport
                                            : handleSubmitButtonClickHandler
                                    }
                                >
                                    <button className="hide_radio_btn">
                                        <span>All</span>
                                        <svg
                                            fill="#BB735D"
                                            height="10px"
                                            width="10px"
                                            version="1.1"
                                            id="Layer_1"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 330 330"
                                            xmlSpace="preserve"
                                        >
                                            <path
                                                id="XMLID_225_"
                                                d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
          c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
          s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                                            />
                                        </svg>
                                    </button>

                                    <div className="search_data_home">
                                        <div className="search_form_div NewSearch-tags transpost_search_form">
                                            <label htmlFor="">Starting From</label>
                                            <TransportHubsSearchInput
                                                onClick={() => setdestinationToggle(true)}
                                                onDataChange={getDataFromChild}
                                                dropdownGuestDetails={dropdownGuestDetails}
                                                setIsguestDetails={setIsguestDetails}
                                            />

                                            {/* <PackagesStateInput onDataChange={getDataFromChild} /> */}
                                            {/* 
                                            <NewSearchSateInput
                                                onClick={() => setdestinationToggle(true)}
                                                onDataChange={getDataFromChild}
                                                dropdownGuestDetails={dropdownGuestDetails}
                                                setIsguestDetails={setIsguestDetails}
                                                type="destination"
                                                package_type="transport"
                                            /> */}
                                        </div>
                                        {/* <div className="search_form_div NewSearch-tags transpost_search_form">
                                            <label htmlFor="">To City/Destination</label>
                                            <NewSearchSateInput
                                                onClick={() => setdestinationToggle(true)}
                                                onDataChange={getDataFromChildForToCity}
                                                dropdownGuestDetails={dropdownGuestDetails}
                                                setIsguestDetails={setIsguestDetails}
                                                type="package"
                                                package_type="transport"
                                            />
                                        </div> */}
                                        <div className="search_form_div NewSearch-tags transpost_search_form">
                                            <label htmlFor="">Including Destinations</label>
                                            <MultiSelectSearch
                                                onClick={() => setdestinationToggle(true)}
                                                onMultiSelectionChange={handleMultiDestinationsChange}
                                                // selectedMultipleDestinations={selectedMultipleDestinations}
                                                defaultSelected={selectedMultipleDestinations}
                                            />
                                        </div>
                                        <div className="search_form_div NewSearch-tags transpost_search_form">
                                            <label htmlFor="">No. of Days</label>
                                            <input
                                                type="number"
                                                value={noOfDays}
                                                onChange={(e) => setNoOfDays(e.target.value)}
                                                className="serach_form_input"
                                                required
                                            />
                                        </div>
                                        <div className="search_form_div NewSearch-tags border_0">
                                            <label>Departure Date</label>
                                            <Flatpickr
                                                className="m_0 serach_form_input"
                                                color="#ffffff"
                                                value={date}
                                                onChange={([date]) => setDate(date)}
                                                min={new Date()}
                                                // f
                                                options={{
                                                    minDate: "today",
                                                }}
                                            />
                                        </div>
                                        {/* <div className="search_form_div NewSearch-tags icon_button_only">
                                            <label
                                                onClick={dropdownGuestDetails}
                                                className="search_label"
                                            >
                                                Pax
                                                <svg
                                                    width="9"
                                                    height="5"
                                                    viewBox="0 0 9 5"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M8.25 0.75L4.75 4.25L1.25 0.75"
                                                        stroke="#BB735D"
                                                    />
                                                </svg>
                                            </label>
                                            <div
                                                style={{ position: "relative" }}
                                                className="dropdown-toggle search_form_input"
                                            >
                                                <div onClick={dropdownGuestDetails} ref={dropdownRef}>
                                                    <div className="optionCounter"></div>
                                                    <span id="adult_count">{adult}</span>
                                                    <span> Adults</span>
                                                </div>
                                                {guestDetails && (
                                                    <div
                                                        className="guest_details_dropdown"
                                                        id="guestDetailsPopup"
                                                    >
                                                        <div className="agent">
                                                            <span>Adult</span>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    disabled={adult <= 1}
                                                                    className="optionCounterButton optionCounterButtonWithBorder"
                                                                    onClick={onAdultDecrease}
                                                                >
                                                                    <span className="onclick_btn" id="adult_minus">
                                                                        -
                                                                    </span>
                                                                </button>

                                                                <input
                                                                    className="optionCounterNumber"
                                                                    value={adult}
                                                                    min={1}
                                                                />
                                                                <button
                                                                    type="button"
                                                                    className="optionCounterButton optionCounterButtonWithBorder"
                                                                    onClick={onAdultIncrease}
                                                                >
                                                                    <span className="onclick_btn" id="adult_plus">
                                                                        +
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div> */}
                                        <div className="">
                                            <button type="submit" className="search-btn m_0">
                                                SEARCH
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {(guestDetails || destinationToggle) && (
                <div
                    onClick={() => [
                        setIsguestDetails(false),
                        setdestinationToggle(false),
                    ]}
                    className="ScreenDimmer"
                ></div>
            )}
        </>
    );
}

export default CustomSearchFormTransport;
