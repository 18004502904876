import React from "react";
import "./PointsRemember.css";
import Skeleton from "react-loading-skeleton";

const TransportCancellation = ({ singleTransportData, loader }) => {
  return (
    <>
      {loader ? (
        <div className="package-update-content-containers">
          <div className="package-update-cards p_10">
            <div className="package-card-body">
              <div className="package-update-card">
                <div className="content-consider">
                  <div>
                    <Skeleton width={100} />
                    <div className="agent">
                      <div className="singlePackageTabShowBullets">
                        <ul>
                          <li>
                            <Skeleton width={720} />
                          </li>
                          <li>
                            <Skeleton width={400} />
                          </li>
                          <li>
                            <Skeleton width={300} />
                          </li>
                          <li>
                            <Skeleton width={200} />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="package-update-card p_10">
            {singleTransportData?.packagePolicies?.filter(
              (policy) =>
                policy.type !== "inclusion" &&
                policy.type !== "exclusion" &&
                policy.type !== "points_to_remember"
            ).length > 0 ? (
              <div>
                {singleTransportData.packagePolicies
                  .filter(
                    (policy) =>
                      policy.type !== "inclusion" &&
                      policy.type !== "exclusion" &&
                      policy.type !== "points_to_remember"
                  )
                  .map((policy, index) => (
                    <div key={index} className="policy-section">
                      <div className="agent">
                        <div>
                          <span style={{ fontWeight: "bold" }}>{policy.name}</span>
                        </div>
                      </div>
                      <div
                        className="singlePackageTabShowBullets"
                        dangerouslySetInnerHTML={{ __html: policy.content }}
                      ></div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="no-data-found text-center">No data found</div>
            )}
          </div>

          {/* <div className="package-update-card p_10">
            {singleTransportData?.packagePolicies?.find(
              (item) => item.type === "cancellation_policy"
            ) ? (
              <div>
                <div className="agent">
                  <div>
                    <span style={{ fontWeight: "bold" }}>Cancellation Policies</span>
                  </div>
                </div>
                <>
                  {singleTransportData?.packagePolicies?.map((items, index) => {
                    return (
                      <div key={index}>
                        {items.type === "cancellation_policy" && (
                          <div
                            className="singlePackageTabShowBullets"
                            dangerouslySetInnerHTML={{
                              __html: items.content,
                            }}
                          ></div>
                        )}
                      </div>
                    );
                  })}
                </>
              </div>
            ) : (
              <div className="no-data-found text-center">No data found</div>
            )}
          </div>

          {singleTransportData?.packagePolicies?.find(
            (item) => item.type === "terms_and_conditions"
          )?.length > 0 && (
              <div className="package-update-card p_10">
                <div className="content-consider">
                  <div>
                    <div className="agent">
                      <div>
                        <span style={{ fontWeight: "bold" }}>
                          Terms & Conditions
                        </span>
                      </div>
                    </div>
                    <>
                      {singleTransportData?.packagePolicies?.map((items, index) => {
                        return (
                          <div key={index}>
                            {items.type === "terms_and_conditions" && (
                              <div
                                className="singlePackageTabShowBullets"
                                dangerouslySetInnerHTML={{
                                  __html: items.content,
                                }}
                              ></div>
                            )}
                          </div>
                        );
                      })}
                    </>
                  </div>
                </div>
              </div>
            )} */}
        </>
      )}
    </>
  );
};

export default TransportCancellation;
