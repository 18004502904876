import React, { useEffect, useState } from "react";
import Footer from "../../CustomComponents/Footer/Footer";
import OurQuery from "../../CustomComponents/OurQuery/OurQuery";
import PageLinks from "../../CustomComponents/PageLinks/PageLinks";
import ReviewReservationBanner from "../../CustomComponents/ReviewReservationBanner/ReviewReservationBanner";
import Header from "../../CustomComponents/Header/Header";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import { useLocation, useNavigate } from "react-router-dom";
import AdultDetailsInPackage from "../../CustomComponents/AdultDetailsInPackage/AdultDetailsInPackage";
import {
  DateFormatMoment,
  calculateLOS,
  currentDateSingle,
  formatIndianCurrency,
} from "../../utils/utils";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import Flatpickr from "react-flatpickr";
import AddressAutocomplete from "../../CustomComponents/CustomGooglePlaceAPi/AddressAutocomplete";
import moment from "moment";
import LoadAtomCheckoutScript from "../../CustomComponents/PaymentGateway/LoadAtomCheckoutScript";
import SmallLoader from "../../CustomComponents/Loader/SmallLoader";
import FullWidthLoader from "../../CustomComponents/Loader/FullWidthLoader";

function CheckOutTransport() {
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const userType = localStorage.getItem("userType");
  const userDetails = JSON?.parse(localStorage?.getItem("userDetails"));

  // const {
  //   transportData,
  //   bookingData,
  //   selectedVehicle,
  //   calculationsOfPackages
  // } = location?.state || {};

  const {
    transportData,
    bookingData,
    selectedVehicle,
    selectedCategory,
    calculationsOfPackages,
    hotelDayByDay,
    vehicleDetails,
    customized,
    ratesOfPackages,
    selectedAddOns,
    markupData,
    selectedMealPlan,
    allPackageHotels,
    commission,
    // roomsDetails,
    totalAddonsCost,
    totalAddonsCostWithoutCommission,
    selectedAutomaticDiscount,
    selectedDiscount
  } = location?.state || {};

  const [transportBookingData, setTransportBookingData] = useState(null);
  // const [commission, setCommission] = useState({});
  const navigate = useNavigate();
  const userToken = localStorage.getItem("token");

  const [validity, setValidity] = useState(
    bookingData?.travelDate || currentDateSingle
  );

  const [promotions, setPromotions] = useState([]);
  // const [selectedDiscount, setSelectedDiscount] = useState([]);
  // const [selectedAutomaticDiscount, setSelectedAutomaticDiscount] = useState(
  //   []
  // );

  const [atomAccessToken, setAtomAccessToken] = useState(null);
  const [paymentGatewayLoader, setPaymentGatewayLoader] = useState(false);

  const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`; // 24-hour format
  };

  const formatTo12Hour = (time24) => {
    const [hours, minutes] = time24.split(":").map(Number);
    const ampm = hours >= 12 ? "PM" : "AM";
    const hours12 = String(hours % 12 || 12).padStart(2, "0"); // Add leading zero for single-digit hours
    return `${hours12}:${minutes.toString().padStart(2, "0")} ${ampm}`;
  };

  const [modifyDate, setmodifyDate] = useState(false);

  const openmodifyDate = (id) => {
    setmodifyDate(id);
  };

  const closemodifyDate = (id) => {
    setmodifyDate(id);
  };

  const [bookingValues, setBookingValues] = useState({
    taxSlab: 0,
    subtotal: 0,
    taxAmount: 0,
    grandTotal: 0,
    discountAmt: 0,
    totalChildAdultCost: 0,
  });

  const [travelerDetails, settravelerDetails] = useState(true);

  const toggletravelerDetails = () => {
    settravelerDetails(!travelerDetails);
  };

  useEffect(() => {
    const updateHideToggle = () => {
      if (window.innerWidth <= 768) {
        settravelerDetails(false);
      } else {
        settravelerDetails(true);
      }
    };

    updateHideToggle(); // Set the initial state based on screen size
    window.addEventListener("resize", updateHideToggle);

    return () => window.removeEventListener("resize", updateHideToggle);
  }, []);

  // const handleDiscountChange = (item) => {
  //   if (selectedDiscount?.id === item?.id) {
  //     setSelectedDiscount(null);
  //   } else {
  //     setSelectedDiscount(item);
  //   }
  // };

  // const handleDiscountChange = (item) => {
  //   setSelectedDiscount([item]);
  // };

  const [guestData, setGuestData] = useState({
    guest_name: "",
    guest_email: "",
    guest_phone: "",
    zipcode: "",
    transport_notes_information: "",
    guest_address: "",
    guest_country: "",
    guest_city: "",
    guest_state: "",
    gst_number: "",
    company_number: "",
    business_email: "",
  });

  const guestDataHandleChange = (event) => {
    const { name, value } = event.target;
    setGuestData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [addressData, setAddressData] = useState({
    country: "",
    state: "",
    city: "",
    address: "",
    latitude: "",
    longitude: "",
    placeId: "",
  });

  const handleAddressData = (data, e) => {
    e.preventDefault();
    setAddressData(data);
  };

  const [paymentGateways, setPaymentGateways] = useState([]);
  const [selectedPaymentGateway, setSelectedPaymentGateway] = useState(null);

  const fetchPaymentGateway = async () => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        key: process.env.REACT_APP_BASE_API_KEY,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/fetch/active`,
        {
          method: "GET",
          headers: headers,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setPaymentGateways(result.data);
      const firstActiveGateway = result.data.find(
        (gateway) => gateway.gatewayName === "NTTData"
      );
      if (firstActiveGateway) {
        setSelectedPaymentGateway(firstActiveGateway);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePaymentGatewayChange = (item) => {
    setSelectedPaymentGateway(item);
  };

  // const fetchPromotions = async () => {
  //   try {
  //     const myHeaders = new Headers();
  //     myHeaders.append("Accept", "application/json");
  //     myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
  //     myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

  //     const requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     };

  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/promotions`,
  //       requestOptions
  //     );
  //     const result = await response.json();
  //     setPromotions(result?.data);

  //     const applicableAutomaticPromotion = result?.data
  //       ?.filter((item) => {
  //         const isAutomatic = item.promotionType === "automatic";

  //         const isValidDate = checkDates(item.startDate, item.endDate);

  //         const isBookingWithinDiscount = checkBookingDates(
  //           bookingData?.travelDate,
  //           bookingData?.travelDate,
  //           item.startDate,
  //           item.endDate
  //         );

  //         const isUserEligible = isPromotionApplicable(
  //           userType === "customer"
  //             ? "customers"
  //             : userType === "agent"
  //               ? "agents"
  //               : "",
  //           userDetails?.id,
  //           item.promotionApplicable
  //         );

  //         const blackoutDates = item.promotionBlackOutDate || [];
  //         const isBlackout = isBlackoutDate(
  //           blackoutDates,
  //           bookingData?.travelDate
  //         );

  //         const isApplicableForEntity = isPromotionApplicableForEntity(
  //           item.promotionEntity,
  //           "transport",
  //           transportData?.id
  //         );

  //         const isConditionsValid = evaluateConditions(
  //           item.promotionConditions,
  //           bookingData?.travelDate
  //         );

  //         return (
  //           isAutomatic &&
  //           isValidDate &&
  //           isBookingWithinDiscount &&
  //           isUserEligible &&
  //           !isBlackout &&
  //           isApplicableForEntity &&
  //           isConditionsValid
  //         );
  //       })
  //       ?.sort((a, b) => b.stackable - a.stackable);

  //     let selectedPromotions = [];

  //     if (applicableAutomaticPromotion.length > 0) {
  //       const firstPromotion = applicableAutomaticPromotion[0];
  //       selectedPromotions.push(firstPromotion);

  //       if (applicableAutomaticPromotion.length > 1) {
  //         const secondPromotion = applicableAutomaticPromotion[1];
  //         if (firstPromotion.stackable && secondPromotion.stackable) {
  //           selectedPromotions.push(secondPromotion);
  //         }
  //       }
  //     }

  //     setSelectedAutomaticDiscount(selectedPromotions);
  //   } catch (error) {
  //     console.error("Error fetching promotions:", error);
  //   }
  // };

  useEffect(() => {
    fetchPaymentGateway();
    // fetchPromotions();
  }, []);

  const processPayment = async (bookingData) => {
    try {
      setPaymentGatewayLoader(true);
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        key: process.env.REACT_APP_BASE_API_KEY,
        Cookie: process.env.REACT_APP_COOKIE,
      };

      const body = JSON.stringify({
        order_amount: bookingData?.grandTotal,
        order_number: bookingData?.bookingNo,
        order_id: bookingData?.id,
        email: bookingData?.customerDetails?.email,
        phone: bookingData?.customerDetails?.phone,
        callbackUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response/${bookingData?.id}`,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/process/${selectedPaymentGateway?.gatewayName}/payment`,
        {
          method: "POST",
          headers: headers,
          body: body,
          redirect: "follow",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      setAtomAccessToken(result?.data);
      setPaymentGatewayLoader(false);
    } catch (error) {
      console.error("Error processing payment:", error);
      setPaymentGatewayLoader(false);
    }
  };

  useEffect(() => {
    if (selectedPaymentGateway) {
      const scriptUrl = selectedPaymentGateway?.testMode
        ? "https://pgtest.atomtech.in/staticdata/ots/js/atomcheckout.js"
        : "https://psa.atomtech.in/staticdata/ots/js/atomcheckout.js";

      const existingScript = document.querySelector(
        `script[src="${scriptUrl}"]`
      );

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = scriptUrl;
        script.async = true;
        script.onload = () => console.log("Atom Payment Gateway script loaded");
        script.onerror = () => console.error("Failed to load Atom script");
        document.body.appendChild(script);

        // Clean up script on unmount
        return () => {
          document.body.removeChild(script);
        };
      } else if (existingScript.readyState === "complete") {
        console.log("Atom Payment Gateway script already loaded");
      }
    }
  }, [selectedPaymentGateway]);

  useEffect(() => {
    if (
      atomAccessToken &&
      transportBookingData &&
      typeof window.AtomPaynetz !== "undefined"
    ) {
      const options = {
        merchId: process.env.REACT_APP_ATOM_MERCHANT_ID,
        atomTokenId: atomAccessToken,
        custEmail: transportBookingData?.customerDetails?.email,
        custMobile: transportBookingData?.customerDetails?.phone,
        returnUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response/${transportBookingData?.id}`,
      };

      const atom = new window.AtomPaynetz(options, "uat");

      const handlePaymentClick = (e) => {
        e.preventDefault();

        try {
          atom.invoke((status) => {
            if (status === "success") {
              navigate("/thank-you", {
                state: { bookingId: transportBookingData?.id },
              });
            } else if (status === "failure" || status === "cancel") {
              showErrorToast.error("Payment failed. Please try again.");
              navigate("/cancel", {
                state: { bookingId: transportBookingData?.id },
              });
            } else {
              showErrorToast("Unknown payment status. Please contact support.");
            }
          });
        } catch (err) {
          console.error("Error initiating payment", err);
          showErrorToast("Failed to initiate payment. Please try again.");
        }
      };

      const paymentButton = document.querySelector("#paymentButton");
      if (paymentButton) {
        paymentButton.addEventListener("click", handlePaymentClick);
      }

      // Clean up event listener on unmount
      return () => {
        if (paymentButton) {
          paymentButton.removeEventListener("click", handlePaymentClick);
        }
      };
    } else {
      console.warn("AtomPaynetz is not ready or required data is missing");
    }
  }, [atomAccessToken, transportBookingData, selectedPaymentGateway, navigate]);

  const handleMessage = async ({ data }) => {
    if (data === "cancelTransaction") {
      try {
        const headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          key: process.env.REACT_APP_BASE_API_KEY,
          Cookie: process.env.REACT_APP_COOKIE,
        };
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response/${transportBookingData?.id}`,
          {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
              encData: "",
              order_id: transportBookingData?.id,
              status: "cancelled",
            }),
          }
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const result = await response.json();
        navigate(
          `/thank-you?paymentGateway=${selectedPaymentGateway}&bookingId=${mainData[0]?.transportBookingData?.id}&status=cancelled`
          // `/cancel/${transportBookingData?.id}?paymentGateway=${selectedPaymentGateway?.gatewayName}&bookingId=${transportBookingData?.id}&status=cancelled`
        );
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [transportBookingData]);

  const mainData = [
    {
      selectedPaymentGateway: selectedPaymentGateway?.gatewayName,
      order_amount: bookingData?.grandTotal,
      order_number: bookingData?.bookingNo,
      order_id: bookingData?.id,
      email: bookingData?.customerDetails?.email,
      phone: bookingData?.customerDetails?.phone,
      callbackUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response`,
      custEmail: transportBookingData?.customerDetails?.email,
      custMobile: transportBookingData?.customerDetails?.phone,
      returnUrl: `${process.env.REACT_APP_BASE_URL}/api/v1/website/payment-gateway/payment/${selectedPaymentGateway?.gatewayName}/response`,
    },
  ];

  const checkDates = (startDate, endDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    return currentDate >= start && currentDate <= end;
  };

  const checkBookingDates = (
    checkinBookingDate,
    checkoutBookingDate,
    discountStartDate,
    discountEndDate
  ) => {
    const checkin = new Date(checkinBookingDate);
    const checkout = new Date(checkoutBookingDate);
    const discountStart = new Date(discountStartDate);
    const discountEnd = new Date(discountEndDate);

    return checkin <= discountEnd && checkout >= discountStart;
  };

  const isPromotionApplicable = (userType, userId, promotionApplicable) => {
    if (
      Array.isArray(promotionApplicable) &&
      promotionApplicable.length === 0
    ) {
      return true;
    }

    if (promotionApplicable) {
      return promotionApplicable?.some((applicable) => {
        return (
          applicable?.applicableTo === userType &&
          applicable?.applicableIds?.includes(userId?.toString())
        );
      });
    }
  };

  const isBlackoutDate = (blackoutDates, checkDate) => {
    const normalizedCheckDate = new Date(checkDate).toLocaleDateString("en-CA");
    return blackoutDates.some((blackout) => {
      const blackoutDate = new Date(blackout.date).toISOString().split("T")[0];
      return blackoutDate === normalizedCheckDate;
    });
  };

  const isPromotionApplicableForEntity = (
    promotionEntities,
    currentEntityType,
    currentEntityId
  ) => {
    return promotionEntities.some((entity) => {
      return (
        entity.entityType === currentEntityType &&
        entity.entityIds.some(
          (id) => id.toString() === currentEntityId?.toString()
        )
      );
    });
  };

  const evaluateConditions = (conditions, validity) => {
    const evaluateCondition = (condition, dateToCheck) => {
      const { conditionType, conditionCondition, conditionValue } = condition;
      let startDate = new Date();
      let endDate = new Date();
      endDate.setDate(startDate.getDate() + 1);
      let inputDate = new Date(dateToCheck);
      let currentDate = new Date();
      let comparisonValue;
      let valueToCompare;

      const los = calculateLOS(startDate, endDate);

      if (conditionType === "booking_date") {
        comparisonValue = moment(currentDate).format("DD-MM-YYYY");
        valueToCompare = moment(conditionValue).format("DD-MM-YYYY");

        comparisonValue = new Date(currentDate)
        valueToCompare = new Date(conditionValue)
      } else if (conditionType === "LOS") {
        comparisonValue = los;
        valueToCompare = conditionValue;
      } else {
        comparisonValue = moment(inputDate).format("DD-MM-YYYY");
        valueToCompare = moment(conditionValue).format("DD-MM-YYYY");

        comparisonValue = new Date(inputDate)
        valueToCompare = new Date(conditionValue)
      }

      switch (conditionCondition) {
        case "=":
          return comparisonValue === valueToCompare;
        case ">":
          return comparisonValue > valueToCompare;
        case "!=":
          return comparisonValue != valueToCompare;
        case ">=":
          return comparisonValue >= valueToCompare;
        case "<":
          return comparisonValue < valueToCompare;
        case "<=":
          return comparisonValue <= valueToCompare;
        default:
          return false;
      }
    };

    let result = conditions.length > 0 ? true : false;

    for (const condition of conditions) {
      const conditionResult = evaluateCondition(
        condition,
        bookingData?.travelDate
      );

      if (condition.logicalCondition === "OR") {
        result = result || conditionResult;
      } else {
        result = result && conditionResult;
      }
    }
    return result;
  };

  // commission api
  // const fetchCommissions = async () => {
  //   const myHeaders = new Headers();
  //   myHeaders.append("Accept", "application/json");
  //   myHeaders.append("Authorization", `Bearer ${userToken}`);
  //   myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);

  //   const requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     // body: urlencoded,
  //     redirect: "follow",
  //   };

  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/commission/transport/${transportData?.id}`,
  //       requestOptions
  //     );
  //     const data = await response.json();
  //     if (data.success) {
  //       setCommission(data?.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (userType === "agent") {
  //     fetchCommissions();
  //   }
  // }, [userType]);

  // console.log(transportData, "trans");
  // console.log(bookingData, "bookingData");
  // console.log(bookingValues, "bookingValues");
  // console.log(validity, "validity");

  const createBooking = async () => {
    try {
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        key: process.env.REACT_APP_BASE_API_KEY,
        Cookie: process.env.REACT_APP_COOKIE,
      };

      const body = JSON.stringify({
        booking_type: "transport",
        customer_type: userType === "agent" ? "agent" : "customer",
        customer_name: guestData?.guest_name,
        customer_email: guestData?.guest_email,
        customer_phone: guestData?.guest_phone,
        customer_address: addressData?.address,
        customer_country: addressData?.country,
        customer_state: addressData?.state,
        customer_city: addressData?.city,
        customer_zipcode: guestData?.zipcode,
        total: bookingValues?.subtotal,
        grand_total: bookingValues?.grandTotal,
        status: "confirmed",
        transport_notes_information: guestData?.transport_notes_information,
        misc_details: [],
        markup_details: [],
        tax_details: [
          {
            rate: bookingValues?.taxSlab,
            amount: bookingValues?.taxAmount,
          },
        ],
        transports: [
          {
            name: transportData?.name,
            price: transportData?.price,
          },
        ],
        addons: [],
      });

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/booking/create`,
        {
          method: "POST",
          headers: headers,
          body: body,
          redirect: "follow",
        }
      );

      const result = await response.json();
      console.log(result);
      setTransportBookingData(result.data);
      processPayment(result.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // console.log(calculationsOfPackages);

  return (
    <div id="Listingholidays">
      {paymentGatewayLoader && <FullWidthLoader />}
      <PageMetaData
        title="Mountain Mysteries"
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <Header />
      <ReviewReservationBanner heading="Review Reservation Details" />
      {/* <PageLinks links={["Home"]} /> */}
      <section>
        <div className="container">
          <main className="justify_content_center">
            <div className="reservation-content ">
              <div className="p_0 common_background ">
                <div className="fixed_color">
                  <div className="flex justify_content_between flex_col_mobile">
                    <div className="tranport_checkout_details">
                      <h3 className="m_0">{transportData?.packageName}</h3>
                      <div className="flex align_items_center flex_gap_10">
                        <p className="text-capitalize">{selectedVehicle}</p>
                        {/* <p>AC</p> */}
                        <div className="flex">
                          <b>Pickup Date:</b> {DateFormatMoment(validity)}
                        </div>
                        <button className="modify_btn" onClick={openmodifyDate}>
                          Modify
                        </button>
                      </div>
                      {modifyDate && (
                        <div className="popup_backdrop">
                          <div
                            className="common_background"
                            id="dateChangePopup"
                          >
                            <div className="flex justify_content_between">
                              <h4>
                                <b>Modify</b>
                              </h4>
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                onClick={() => closemodifyDate(null)}
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 13L13 1"
                                  stroke="#AAAAAA"
                                  strokeWidth="1.2"
                                />
                                <path
                                  d="M13 13L0.999999 1"
                                  stroke="#AAAAAA"
                                  strokeWidth="1.2"
                                />
                              </svg>
                            </div>
                            <form action="">
                              <label style={{ margin: "5px 0" }}>Date</label>
                              <div className="modify_date_time_inputs_con">
                                <Flatpickr
                                  className="w-full modify_date_picker_input"
                                  value={
                                    validity ? validity : currentDateSingle
                                  }
                                  onChange={([date]) => setValidity(date)}
                                />
                              </div>
                              {/* <label style={{ margin: "5px 0" }}>Time</label>
                                                            <div className="modify_date_time_inputs_con">
                                                                <input
                                                                    type="time"
                                                                    value={time}
                                                                    className="w-full"
                                                                    onChange={handleTimeChange}
                                                                />
                                                            </div> */}
                              <div className="buttons">
                                <button
                                  type="button"
                                  onClick={() => closemodifyDate(null)}
                                  className="primary_btn"
                                >
                                  Done
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className="tranport_checkout_details_right">
                      <b> Pickup Location:</b>
                      <p>Delhi Airport, Bus Stand & Railway Station</p>
                    </div> */}
                  </div>

                  {/* <div className="flex justify_content_between align_items_center pt_10">
                                        <div style={{ textAlign: 'left' }}>
                                            <span className="font_11">Pickup Location</span>
                                            <p>{bookingData?.startDestination}</p>
                                        </div>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#222222"><path d="m600-200-57-56 184-184H80v-80h647L544-704l56-56 280 280-280 280Z" /></svg>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <span className="font_11">Drop-Off Location</span>
                                            <p>{bookingData?.endDestination}</p>
                                        </div>
                                    </div> */}
                  {/* <div className="flex justify_content_between align_items_center pt_10">
                                        <div style={{ textAlign: 'left' }}>
                                            <span className="font_11">Pickup Date</span>
                                            {bookingData?.travelDate && (
                                                <p>{moment(bookingData?.travelDate).format("dddd, DD MMMM, YYYY")}</p>
                                            )}
                                        </div>
                                    </div> */}
                </div>
                <div className="list_details">
                  <div
                    className="flex justify_content_between align_items_center cursor_pointer"
                    onClick={toggletravelerDetails}
                  >
                    <h5 className="text-left">1. Traveler Details</h5>
                    <svg
                      width="13"
                      height="8"
                      viewBox="0 0 13 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        transition: "transform 0.4s",
                        transform: travelerDetails
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      <path
                        d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                        stroke="#222222"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </div>
                  {/* {travelerDetails && (
                                        <>
                                            <div className="flex flex_direction_col flex_gap_10">
                                                <div className="text-left mt-10">1 Adult</div>
                                                <div>  <small><b>Adult 1</b></small></div>
                                                <button className="text-left add_btn" onClick={openPopup} style={{ color: "#016937" }}>Add Traveler</button>
                                                {isPopupVisibleId && (
                                                    <div className="popup_backdrop">
                                                        <div className="common_background" id="dateChangePopup">
                                                            <div className="flex justify_content_between">
                                                                <h4><b>Enter Traveler Details</b></h4>
                                                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" onClick={() => closePopup(null)} xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M1 13L13 1" stroke="#AAAAAA" strokeWidth="1.2" />
                                                                    <path d="M13 13L0.999999 1" stroke="#AAAAAA" strokeWidth="1.2" />
                                                                </svg>
                                                            </div>
                                                            <form action="">
                                                                <input type="text" placeholder="Name" />
                                                                <input type="text" placeholder="D.O.B" />
                                                                <input type="text" placeholder="Gender" />
                                                                <div className="buttons">
                                                                    <button className="primary_btn">Done</button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>

                                            <form action="" className="guest_details mt-10">
                                                <h5 className="text-left">Enter contact details</h5>
                                                <div className="input-flex mt-10">
                                                    <div>
                                                        <label for="Phone">Phone</label>
                                                        <input type="text" placeholder="77788-89990" />
                                                    </div>
                                                    <div>
                                                        <label for="Email">Email</label>
                                                        <input type="text" placeholder="example@email.com" />
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    )} */}

                  {travelerDetails && (
                    // <div style={{ paddingTop: '20px' }}>
                    //     <AdultDetailsInPackage
                    //         sendDataToParent={sendDataToParent}
                    //     />
                    // </div>

                    <form action="">
                      <div className="" id="padding">
                        <div className="input-flex mt-10">
                          <div className="HotelCheckout-form">
                            <label htmlFor="">Full Name</label>
                            <input
                              className="HotelCheckout-Input"
                              id="guest_name"
                              type="text"
                              name="guest_name"
                              value={guestData.guest_name}
                              onChange={guestDataHandleChange}
                              required
                              placeholder="Name"
                            />
                          </div>
                          <div className="CheckoutHotel-Fields">
                            <label htmlFor="">Phone</label>
                            <input
                              className="HotelCheckout-Input"
                              type="number"
                              id="guest_phone"
                              name="guest_phone"
                              value={guestData.guest_phone}
                              onChange={guestDataHandleChange}
                              required
                              placeholder="Phone"
                            />
                          </div>
                        </div>
                        <div className="input-flex mt-10">
                          <div className="CheckoutHotel-Fields">
                            <label htmlFor="">Email Address </label>
                            <input
                              className="HotelCheckout-Input"
                              id="guest_email"
                              name="guest_email"
                              value={guestData.guest_email}
                              onChange={guestDataHandleChange}
                              required
                              placeholder="Email"
                            />
                          </div>
                          <div className="CheckoutHotel-Fields w-half">
                            <label htmlFor="">Zipcode</label>
                            <input
                              className="HotelCheckout-Input"
                              type="number"
                              id="zipcode"
                              name="zipcode"
                              value={guestData.zipcode}
                              onChange={guestDataHandleChange}
                              placeholder="Zipcode"
                            />
                          </div>
                        </div>
                        <div className="mt-10">
                          <label htmlFor="address">Address</label>
                          <AddressAutocomplete
                            formdata={addressData}
                            onAddressChange={handleAddressData}
                          />
                        </div>

                        <div className="mt-10">
                          <label htmlFor="address">Additional Details</label>
                          <textarea
                            className="HotelCheckout-Input"
                            id="transport_notes_information"
                            name="transport_notes_information"
                            value={guestData.transport_notes_information}
                            onChange={guestDataHandleChange}
                            required
                            placeholder="Additional Details"
                          />
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
            <aside>
              <div className="common_background" id="activityPrice">
                <h5 className="text-left">Price Breakup</h5>
                <div>
                  <div className="flex justify_content_between mt-10">
                    <p>Total Cost</p>
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "end",
                      }}
                    >
                      <b>
                        {formatIndianCurrency(calculationsOfPackages.basePrice)}
                      </b>
                    </span>
                  </div>

                  {(selectedAutomaticDiscount.length > 0 ||
                    selectedDiscount.length > 0) && (
                      <div className="mt-10">
                        <p>
                          <b>
                            Transport Discount
                            {selectedAutomaticDiscount?.length > 1 ? "s" : ""}
                          </b>
                        </p>

                        {selectedAutomaticDiscount?.map((discount, index) => (
                          <div
                            className="flex justify_content_between mt-2"
                            key={discount.id}
                          >
                            <span className="discount-name">
                              {discount.promotionName}
                            </span>
                            <span className="room_text-right green">
                              {discount.promotionMode === "percentage"
                                ? `${+discount.promotionAmount}%`
                                : `${formatIndianCurrency(
                                  discount.promotionAmount
                                )}`}
                            </span>
                          </div>
                        ))}

                        {selectedDiscount &&
                          selectedDiscount?.length > 0 &&
                          selectedDiscount?.map((discount, index) => (
                            <div
                              className="flex justify_content_between mt-2"
                              key={discount.id}
                            >
                              <span className="discount-name">
                                {discount.promotionName}
                              </span>
                              <span className="room_text-right green">
                                {discount.promotionMode === "percentage"
                                  ? `${+discount.promotionAmount}%`
                                  : `${formatIndianCurrency(
                                    discount.promotionAmount
                                  )}`}
                              </span>
                            </div>
                          ))}
                      </div>
                    )}

                  {calculationsOfPackages?.discount > 0 && (
                    <div className="flex justify_content_between mt-10 border-bottom-one ">
                      <p>Package Cost (After Disc.)</p>
                      <span
                        className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
                          0 && "filterBlur3"
                          }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.priceAfterDiscount
                        )}
                      </span>
                    </div>
                  )}

                  {/* {bookingValues?.discountAmt > 0 && (
                    <div className="flex justify_content_between mt-10">
                      <p>Discount Amt.</p>
                      <span className="room_text-right">
                        {formatIndianCurrency(bookingValues?.discountAmt)}
                      </span>
                    </div>
                  )} */}

                  {commission && Object.keys(commission).length > 0 && (
                    <>
                      <div className="flex justify_content_between mt-10">
                        <p>
                          Commission
                          <small>
                            ({" "}
                            {commission.commissionType === "fixed" ? (
                              <span className="room_text-right">
                                {formatIndianCurrency(
                                  commission?.commissionAmount
                                )}
                              </span>
                            ) : (
                              <span className="room_text-right">
                                {commission?.commissionAmount}%
                              </span>
                            )}
                            )
                          </small>
                        </p>
                        <span className="room_text-right">
                          {formatIndianCurrency(
                            calculationsOfPackages?.calculatedCommissionAmount
                          )}
                        </span>
                      </div>
                    </>
                  )}

                  {/* {bookingValues?.totalActivityCostWithoutAddons > 0 && totalAddonsCost > 0 && (
                                        <div className="flex justify_content_between mt-10">
                                            <p>Total Activity Cost</p>
                                            <span className="room_text-right">
                                                {formatIndianCurrency(bookingValues?.totalActivityCostWithoutAddons)}
                                            </span>
                                        </div>
                                    )} */}

                  {selectedAddOns && selectedAddOns.length > 0 && (
                    <div className="flex justify_content_between mt-10">
                      <p>Addons Price</p>

                      <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                        {commission && Object.keys(commission).length > 0 && totalAddonsCostWithoutCommission && (
                          <small style={{ color: 'red', textDecoration: 'line-through' }}>
                            {formatIndianCurrency(totalAddonsCostWithoutCommission)}
                          </small>
                        )}
                        <b>
                          {formatIndianCurrency(totalAddonsCost)}
                        </b>
                      </span>
                    </div>
                  )}

                  {/* {selectedAddOns && selectedAddOns.length > 0 && ( */}
                  <div className="flex justify_content_between mt-10">
                    <p>Sub total</p>
                    <span className="room_text-right">
                      {formatIndianCurrency(calculationsOfPackages?.subtotal)}
                    </span>
                  </div>
                  {/* )} */}

                  <div className="flex justify_content_between mt-10">
                    <p>GST(5%) </p>
                    <span className="room_text-right">
                      {formatIndianCurrency(calculationsOfPackages?.taxAmount)}
                    </span>
                  </div>

                  <div className="flex justify_content_between mt-10">
                    <h6>
                      <b>Total Amount:</b>
                    </h6>
                    <h6>
                      <b className="room_text-right">
                        {formatIndianCurrency(
                          calculationsOfPackages?.totalAmount
                        )}
                      </b>
                    </h6>
                  </div>
                </div>
                {/* 
                {promotions.length > 0 && (
                  <>
                    {promotions.some((item) => {
                      const isCouponCode =
                        item?.promotionType === "coupon" ? true : false;

                      const isValidDate = checkDates(
                        item.startDate,
                        item.endDate
                      );

                      const isBookingWithinDiscount = checkBookingDates(
                        bookingData?.travelDate,
                        bookingData?.travelDate,
                        item.startDate,
                        item.endDate
                      );

                      const isUserEligible = isPromotionApplicable(
                        userType === "customer"
                          ? "customers"
                          : userType === "agent"
                            ? "agents"
                            : "",
                        userDetails?.id,
                        item.promotionApplicable
                      );

                      const blackoutDates = item.promotionBlackOutDate || [];

                      const isBlackout = isBlackoutDate(
                        blackoutDates,
                        bookingData?.travelDate
                      );

                      const isApplicableForEntity =
                        isPromotionApplicableForEntity(
                          item.promotionEntity,
                          "transport",
                          transportData?.id
                        );

                      const isConditionsValid = evaluateConditions(
                        item.promotionConditions,
                        bookingData?.travelDate
                      );

                      return (
                        isCouponCode &&
                        isValidDate &&
                        isBookingWithinDiscount &&
                        isUserEligible &&
                        !isBlackout &&
                        isApplicableForEntity &&
                        isConditionsValid
                      );
                    }) && (
                        <div
                          className="common_background"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="flex justify_content_between cursor_pointer">
                            <div className="flex justify_content_between w-100">
                              <div>
                                <h5 className="text-left">Discounts</h5>
                              </div>
                              {selectedDiscount && (
                                <button
                                  className="view_btn"
                                  onClick={() => setSelectedDiscount([])}
                                >
                                  clear
                                </button>
                              )}
                            </div>
                          </div>
                          <div>
                            {promotions.map((item, index) => {
                              const isCouponCode =
                                item?.promotionType === "coupon" ? true : false;

                              const isValidDate = checkDates(
                                item.startDate,
                                item.endDate
                              );

                              const isBookingWithinDiscount = checkBookingDates(
                                bookingData?.travelDate,
                                bookingData?.travelDate,
                                item.startDate,
                                item.endDate
                              );

                              const isUserEligible = isPromotionApplicable(
                                userType === "customer"
                                  ? "customers"
                                  : userType === "agent"
                                    ? "agents"
                                    : "",
                                userDetails?.id,
                                item.promotionApplicable
                              );

                              const blackoutDates =
                                item.promotionBlackOutDate || [];

                              const isBlackout = isBlackoutDate(
                                blackoutDates,
                                bookingData?.travelDate
                              );

                              const isApplicableForEntity =
                                isPromotionApplicableForEntity(
                                  item.promotionEntity,
                                  "transport",
                                  transportData?.id
                                );

                              const isConditionsValid = evaluateConditions(
                                item.promotionConditions,
                                bookingData?.travelDate
                              );

                              return (
                                isCouponCode &&
                                isValidDate &&
                                isBookingWithinDiscount &&
                                isUserEligible &&
                                !isBlackout &&
                                isApplicableForEntity &&
                                isConditionsValid && (
                                  <div
                                    key={index}
                                    className="flex discountSingleDiv justify_content_between w-100"
                                  >
                                    <div className="flex">
                                      <label
                                        style={{
                                          textTransform: "capitalize",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        htmlFor={item?.promotionName}
                                      >
                                        {" "}
                                        <input
                                          // checked={
                                          //   selectedDiscount?.id === item?.id
                                          // }
                                          // onChange={() =>
                                          //   handleDiscountChange(item)
                                          // }
                                          // type="radio"
                                          // id={item?.promotionName}

                                          checked={selectedDiscount?.some(
                                            (discount) => discount.id === item.id
                                          )}
                                          onChange={() =>
                                            handleDiscountChange(item)
                                          }
                                          type="radio"
                                          id={item.promotionName}
                                        />
                                        <div>
                                          <div>
                                            {item?.promotionName?.replace(
                                              /-/g,
                                              " "
                                            )}
                                          </div>
                                        </div>
                                      </label>
                                    </div>
                                    <div>
                                      {item?.promotionMode === "fixed" ? (
                                        formatIndianCurrency(
                                          item?.promotionAmount
                                        )
                                      ) : (
                                        <>{item?.promotionAmount}%</>
                                      )}
                                    </div>
                                  </div>
                                )
                              );
                            })}
                          </div>
                        </div>
                      )}
                  </>
                )} */}

                {paymentGateways.length > 0 ? (
                  <>
                    <div className="mt-20">
                      <label htmlFor="">
                        <strong>Payment gateway</strong>
                      </label>
                      {paymentGateways &&
                        paymentGateways.map((item, index) => (
                          <>
                            <div
                              key={index}
                              className="flex discountSingleDiv justify_content_between w-100"
                            >
                              <div className="flex">
                                <label
                                  style={{
                                    textTransform: "capitalize",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  htmlFor={item?.gatewayName}
                                >
                                  <input
                                    checked={
                                      selectedPaymentGateway?.gatewayName ===
                                      item?.gatewayName
                                    }
                                    onChange={() =>
                                      handlePaymentGatewayChange(item)
                                    }
                                    type="radio"
                                    id={item?.gatewayName}
                                  />
                                  <div style={{ cursor: "pointer" }}>
                                    <div>{item.gatewayName}</div>
                                  </div>
                                </label>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                    <div className="buttons">
                      <button
                        className="common_btn m_0_auto"
                        onClick={createBooking}
                      >
                        Proceed to Payment
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="buttons">
                    <button
                      // onClick={bookPackageHandle}
                      disabled={true}
                      className="common_btn"
                      style={{ cursor: "not-allowed" }}
                    >
                      Proceed to Payment
                    </button>
                  </div>
                )}
              </div>
              <OurQuery />
            </aside>
          </main>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default CheckOutTransport;
