import React, { useState, useEffect } from "react";
import Footer from "../../CustomComponents/Footer/Footer";
import Header from "../../CustomComponents/Header/Header";
import OurQuery from "../../CustomComponents/OurQuery/OurQuery";
import { useParams } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import {
  calculateLOS,
  currentDateSingle,
  formatIndianCurrency,
  scrollToTop,
} from "../../utils/utils";
import moment from "moment";
import TransportTabHeader from "../../CustomComponents/TransportCustomTabHeader/TransportTabHeader";
import OgMetaTitles from "../../CustomComponents/PageMetaData/OgMetaTitles";
import NewSendEnquiryPopup from "../../CustomComponents/NewSendEnquiryPopup/NewSendEnquiryPopup";
import PageMetaData from "../../CustomComponents/PageMetaData/PageMetaData";
import TransportOverviewContent from "../../CustomComponents/TransportCustomTabHeader/TransportOverviewContent";
import TransportItineraryContent from "../../CustomComponents/TransportCustomTabHeader/TransportItineraryContent";
import TransportInclusionExclusionContent from "../../CustomComponents/TransportCustomTabHeader/TransportInclusionExclusionContent";
import TransportCancellation from "../../CustomComponents/TransportCustomTabHeader/TransportCancellation";
import TransportGallery from "../../CustomComponents/TransportCustomTabHeader/TransportGallery";
import TransportPointRemember from "../../CustomComponents/TransportCustomTabHeader/TransportPointRemember";
import SinglePackageHeader from "../../CustomComponents/SinglePackageHeader/SinglePackageHeader";
import SingleTransportHeader from "../../CustomComponents/SinglePackageHeader/SingleTransportHeader";

function SingleTransport() {
  // const {id} = useParams("packageId")
  const [date, setDate] = useState({
    setDay: "",
    setMonth: "",
    setYear: "",
  });
  const { transportHandle } = useParams();
  const [isSliderVisible, setIsSliderVisible] = useState(false);
  const [showRatePopup, setshowRatePopup] = useState(false);
  const [loader, setLoader] = useState(true);
  const [selectedAddOns, setSelectedAddOns] = useState([]);

  const [selectedMealPlan, setSelectedMealPlan] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState("sedan");
  const [ACtype, setACtype] = useState("withoutAC");

  const handleSelectVehicleChange = (e) => {
    setSelectedVehicle(e.target.value);
  };

  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState("");
  const [ratesOfPackages, setRatesOfPackages] = useState(null);
  const [guest, setGuest] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [singleTransportData, setSingleTransportData] = useState({});
  const [activePayment, setActivePayment] = useState("priceSummary");
  const [paymentDetails, setPaymentDetails] = useState(true);
  const [mobilePriceBreakup, setMobilePriceBreakup] = useState(false);
  const [priceBreakupPopup, setPriceBreakupPopup] = useState(false);
  const [allPackageHotels, setAllPackageHotels] = useState([]);
  const [packageRates, setPackageRates] = useState([]);
  const [markupData, setMarkupData] = useState([]);
  const [promotions, setPromotions] = useState([]);

  const [selectedDiscount, setSelectedDiscount] = useState([]);
  const [selectedAutomaticDiscount, setSelectedAutomaticDiscount] = useState(
    []
  );

  const userType = localStorage.getItem("userType");
  const userDetails = JSON?.parse(localStorage?.getItem("userDetails"));
  const userToken = localStorage.getItem("token");
  const [commission, setCommission] = useState({});
  const [packageId, setPackageId] = useState(null);
  // markup fetch api
  const fetchMarkup = async () => {
    const myHeaders = new Headers();
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/markups`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.success) {
          const findObject = result?.data?.find(
            (mark) => mark?.markupCustomer === "both"
          );
          if (findObject) {
            setMarkupData(findObject);
          } else {
            setMarkupData(null);
          }
        } else {
          setMarkupData("No data found");
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetchMarkup();
  }, []);

  const handleSelectedAddOns = (addon) => {
    setSelectedAddOns((prevSelected) => {
      const isSelected = prevSelected.some(
        (selected) => selected.id === addon.id
      );
      if (isSelected) {
        return prevSelected.filter((selected) => selected.id !== addon.id);
      } else {
        return [...prevSelected, addon];
      }
    });
  };

  useEffect(() => {
    const updateHideToggle = () => {
      if (window.innerWidth <= 768) {
        setMobilePriceBreakup(true);
      } else {
        setMobilePriceBreakup(false);
      }
    };

    updateHideToggle(); // Set the initial state based on screen size
    window.addEventListener("resize", updateHideToggle);

    return () => window.removeEventListener("resize", updateHideToggle);
  }, []);

  const togglePaymentDetails = () => {
    setPaymentDetails(!paymentDetails);
  };

  const handleButtonClick = () => {
    setActivePayment((prev) =>
      prev === "priceSummary" ? "priceDetails" : "priceSummary"
    );
  };

  const [formData, setFormData] = useState({
    company_name: "",
    name: "",
    phone: "",
    email: "",
    querry_detail: "",
    country: "",
    state: "",
    city: "",
    address: "",
  });

  const [activeTab, setActiveTab] = useState("package-information");

  const renderContent = () => {
    switch (activeTab) {
      case "package-information":
        return (
          <TransportOverviewContent
            singleTransportData={singleTransportData}
            loader={loader}
          />
        );
      case "package-itinerary":
        return (
          <TransportItineraryContent
            singleTransportData={singleTransportData}
            loader={loader}
          />
        );
      // case "package-hotels":
      //     return (
      //         <AccommodationContent
      //             loader={loader}
      //             singleTransportData={singleTransportData}
      //             setSelectedCategory={setSelectedCategory}
      //             selectedCategory={selectedCategory}
      //             setSelectedMealPlan={setSelectedMealPlan}
      //             selectedMealPlan={selectedMealPlan}
      //             allPackageHotels={allPackageHotels}
      //             packageRates={packageRates}
      //             ratesOfPackages={ratesOfPackages}
      //         />
      //     );
      case "package-destinations":
        return (
          <TransportInclusionExclusionContent
            singleTransportData={singleTransportData}
            loader={loader}
          />
        );
      case "package-cancellation":
        return (
          <TransportCancellation
            singleTransportData={singleTransportData}
            loader={loader}
          />
        );
      case "package-remember":
        return (
          <TransportPointRemember
            singleTransportData={singleTransportData}
            loader={loader}
          />
        );
      case "package-gallery":
        return (
          <TransportGallery
            singleTransportData={singleTransportData}
            loader={loader}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (packageRates.length > 0) {
      setSelectedMealPlan(packageRates[0]?.mealPlan);
    }
  }, [packageRates.length]);

  const [bookingData, setBookingData] = useState({});
  const location = useLocation();

  const commissionAmount = commission?.commissionAmount;
  const commissionType = commission?.commissionType;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const childAges = [];
    let i = 1;
    while (params.has(`childAge[${i}]`)) {
      childAges.push(params.get(`childAge[${i}]`));
      i++;
    }
    const data = {
      location: params.get("location"),
      room: params.get("room"),
      guest: params.get("guest"),
      children: params.get("children"),
      departureDay: params.get("checkinDay"),
      departureMonth: params.get("checkinMonth"),
      departureYear: params.get("checkinYear"),
      radio: params.get("radio"),
      childAge: childAges,
      date: params.get("date"),
      day: params.get("day"),
      month: params.get("month"),
      year: params.get("year"),
      packageId: params.get("packageId"),
      transportHandle: transportHandle,
    };

    setDate({
      setDay: data.day,
      setMonth: data.month,
      setYear: data.year,
    });

    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transport-holidays/fetch/single/${transportHandle}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setLoader(false);
        setSingleTransportData(data?.data);
        setPackageId(data?.data?.packageId);
        // setSelectedCategory(
        //   data?.data?.packageHotels[0]?.accommodationCategory
        // );
        // if (data?.data?.packageItinerary[0]?.accommodation.length === 0) {
        //   showErrorToast("Accommodations are not added in this package.")
        //   return
        // }
        setSelectedCategory(
          data?.data?.hotelCategory[0]
          // data?.data?.packageItinerary[0]?.accommodation[0]?.hotelcategory
        );

        const allAccommodations = data?.data?.packageItinerary.flatMap(
          (item) => item.accommodation
        );

        if (allAccommodations) {
          const uniqueAccommodations = Array.from(
            new Map(
              allAccommodations?.map((hotel) => [hotel?.accommodationId, hotel])
            ).values()
          );
          setAllPackageHotels(uniqueAccommodations);
        }

        setPackageRates(data?.data?.packageRates);
        scrollToTop();
      })
      .catch((error) =>
        console.error(
          "There has been a problem with your fetch operation:",
          error
        )
      );

    const checkInDate = `${data.year}-${String(data.month).padStart(
      2,
      "0"
    )}-${String(data.date).padStart(2, "0")}`;
    const checkOutDate = `${data.checkoutYear}-${String(
      data.checkoutMonth
    ).padStart(2, "0")}-${String(data.checkoutDay).padStart(2, "0")}`;

    setGuest(data.guest);

    if (data.date && data.month && data.year) {
      setStartDate(checkInDate);
    } else {
      setStartDate(currentDateSingle);
    }
    // setStartDate(checkInDate);
    setEndDate(checkOutDate);
    setBookingData({ ...data, travelDate: startDate });

    fetchPromotions({
      checkInDateFromBooking: checkInDate,
      packageIdFromBooking: Number(data?.packageId),
    });
  }, [location.search]);

  const totalAddonsCostWithoutCommission = selectedAddOns?.reduce(
    (total, addon) => {
      if (addon.priceWithMarkup) {
        if (addon.perPerson && guest) {
          return total + Number(addon.priceWithMarkup) * Number(guest);
        } else {
          return total + Number(addon.priceWithMarkup);
        }
      }
    },
    0
  );

  const totalAddonsCost = selectedAddOns?.reduce((total, addon) => {
    if (addon.perPerson && guest) {
      return total + Number(addon.finalAddonPrice) * Number(guest);
    } else {
      return total + Number(addon.finalAddonPrice);
    }
  }, 0);

  const getRatesFromApi = async (
    packageHub,
    packageDestination,
    todatDays,
    totalNights,
    selectedVehicle
  ) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);
    const destinationNames = packageDestination?.map((dest) => dest.name);

    const formdata = new FormData();
    formdata.append("hub", packageHub);
    destinationNames?.forEach((name) => {
      formdata.append("destinations[]", name);
    });
    formdata.append("days", todatDays);
    formdata.append("nights", totalNights);
    formdata.append("vehicle_type", selectedVehicle);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/transport-fixed-rates/fetch`,
        requestOptions
      );
      const result = await response.json();
      setRatesOfPackages(result?.data[0]);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (
      singleTransportData?.packageHub &&
      singleTransportData?.packageDestination &&
      singleTransportData?.totalDays &&
      singleTransportData?.totalNights &&
      selectedVehicle
    ) {
      getRatesFromApi(
        singleTransportData?.packageHub,
        singleTransportData?.packageDestination,
        singleTransportData?.totalDays,
        singleTransportData?.totalNights,
        selectedVehicle
      );
    }
  }, [singleTransportData, selectedVehicle]);

  // reusable fn
  function calculateFinalPrice(basePrice, markupData, commissionData) {
    let finalPrice = 0;
    let priceAfterMarkup = 0;

    if (!basePrice || typeof basePrice !== "number" || basePrice <= 0) {
      return;
    }

    finalPrice = basePrice;

    if (markupData?.markupType && markupData?.amount) {
      if (markupData.markupType === "percentage") {
        finalPrice += (basePrice * markupData.amount) / 100;
      } else if (markupData.markupType === "fixed") {
        finalPrice += Number(markupData.amount);
      }
    }
    priceAfterMarkup = finalPrice;

    return { finalPrice, priceAfterMarkup };
  }

  function calculateTotalAmount(
    basePrice = 0,
    selectedAutomaticDiscount,
    selectedDiscount,
    // discount = 0,
    // discountType = "fixed",
    guest = 2,
    ratesOfPackages,
    childAgeArray,
    totalAddonsCost,
    totalAddonsCostWithoutCommission,
    volvoAddonPrice,
    markupData,
    commissionAmount,
    commissionType
  ) {
    const childWithAgeGreaterThanSix = childAgeArray.filter(
      (age) => age > 6
    )?.length;
    const childWithAgeLessThanSix = childAgeArray.filter(
      (age) => age <= 6
    )?.length;

    const perPaxCount =
      Number(bookingData.guest) +
      childAgeArray?.length +
      ratesOfPackages?.ExtraAdultCount;

    guest = Number(guest);

    // discount = Number(discount);

    let startingPrice = basePrice;

    let markupAmount;
    let commissionAmt;

    if (markupData?.markupType) {
      if (markupData?.markupType === "percentage") {
        markupAmount = (basePrice * markupData?.amount) / 100;
      } else {
        markupAmount = Number(markupData?.amount);
      }
    }
    let onlyAdultCost = 0;
    if (markupAmount && basePrice) {
      basePrice = Number(basePrice) + markupAmount;
      // onlyAdultCost = Number(basePrice) + markupAmount;
    }

    // onlyAdultCost = basePrice * guest;
    // basePrice = basePrice * guest;

    let adjustedPackagePrice = startingPrice;

    let adultCosts = 0;
    let extraAdultSavings = 0;

    if (ratesOfPackages?.ExtraAdultCount) {
      adultCosts =
        Number(ratesOfPackages?.ExtraAdultCount) *
        Number(ratesOfPackages?.ExtraAdult);

      // const preExtraAdultPrice = adultCosts;
      let extraAdultMarkuoAmount;
      let extraAdultCommissionAmount;

      if (markupData?.markupType) {
        if (markupData?.markupType === "percentage") {
          extraAdultMarkuoAmount = (adultCosts * markupData?.amount) / 100;
        } else {
          extraAdultMarkuoAmount = Number(markupData?.amount);
        }
      }

      if (adultCosts && extraAdultMarkuoAmount) {
        adultCosts += extraAdultMarkuoAmount;
      }

      const preExtraAdultPostMarkup = adultCosts;

      // if (commissionAmount && commissionType) {
      //   if (commissionType === "percentage") {
      //     extraAdultCommissionAmount =
      //       (Number(adultCosts) * Number(commissionAmount)) / 100;
      //   } else if (commissionType === "fixed") {
      //     extraAdultCommissionAmount = Number(commissionAmount);
      //   }
      // }

      // if (adultCosts && extraAdultCommissionAmount) {
      //   adultCosts -= extraAdultCommissionAmount
      // }

      basePrice = basePrice + adultCosts;
      const postExtraAdultPrice = adultCosts;

      extraAdultSavings = preExtraAdultPostMarkup - postExtraAdultPrice;
    }

    let childCosts = 0;
    let childCostWithBed = 0;
    let childCostWithOutBed = 0;
    let childSavingsWithBed = 0;
    let childSavingsWithoutBed = 0;

    if (childAgeArray?.length > 0) {
      childAgeArray.forEach((age) => {
        if (Number(age) >= 6) {
          // childCostWithBed += Number(calculateFinalPrice(ratesOfPackages?.ChildWithBedCost, markupData, commission));
          const result = calculateFinalPrice(
            ratesOfPackages?.ChildWithBedCost,
            markupData,
            commission
          );

          childCostWithBed += Number(result?.finalPrice);
          // childSavingsWithBed += Number(result?.priceAfterMarkup - result?.finalPrice);
        } else {
          const result = calculateFinalPrice(
            ratesOfPackages?.ChildWithOutBedCost,
            markupData,
            commission
          );

          if (volvoAddonPrice !== undefined) {
            const volvoCost =
              +volvoAddonPrice / Number(childWithAgeLessThanSix);
            childCostWithOutBed +=
              Number(result?.finalPrice) + result?.volvoCost;
            // childSavingsWithoutBed += Number(result?.priceAfterMarkup - result?.finalPrice);
          } else {
            childCostWithOutBed += Number(result?.finalPrice);
            // childSavingsWithoutBed += Number(result?.priceAfterMarkup - result?.finalPrice);
          }
        }
      });
    }

    // if (commissionAmount && commissionType && totalAddonsCostWithoutCommission && totalAddonsCost) {
    //   totalAgentSavings += (totalAddonsCostWithoutCommission - totalAddonsCost)
    // }

    let totalChildCostPrice = childCostWithBed + childCostWithOutBed;

    if (totalChildCostPrice) {
      basePrice += totalChildCostPrice;
    }

    let discountAmount;

    const calculateAutomaticDiscountedAmount = (roomRateParsed) => {
      let finalRoomRate = roomRateParsed;
      let totalDiscount = 0;

      selectedAutomaticDiscount &&
        selectedAutomaticDiscount?.length > 0 &&
        selectedAutomaticDiscount?.forEach((discount) => {
          const { promotionMode, promotionAmount } = discount;

          if (promotionMode === "percentage") {
            const percentageDiscount =
              (finalRoomRate * parseFloat(promotionAmount)) / 100;
            totalDiscount += percentageDiscount;
            finalRoomRate -= percentageDiscount;
          } else {
            const fixedDiscount = parseFloat(promotionAmount);
            if (!isNaN(fixedDiscount)) {
              totalDiscount += fixedDiscount;
              finalRoomRate -= fixedDiscount;
            }
          }
        });

      return totalDiscount;
    };

    const calculateDiscountedAmount = (roomRateParsed) => {
      let finalRoomRate = roomRateParsed;
      let totalDiscount = 0;

      selectedDiscount &&
        selectedDiscount?.length > 0 &&
        selectedDiscount?.forEach((discount) => {
          const { promotionMode, promotionAmount } = discount;

          if (promotionMode === "percentage") {
            const percentageDiscount =
              (finalRoomRate * parseFloat(promotionAmount)) / 100;
            totalDiscount += percentageDiscount;
            finalRoomRate -= percentageDiscount;
          } else {
            const fixedDiscount = parseFloat(promotionAmount);
            if (!isNaN(fixedDiscount)) {
              totalDiscount += fixedDiscount;
              finalRoomRate -= fixedDiscount;
            }
          }
        });

      return totalDiscount;
    };

    const totalBookingAutomaticDiscountAmount =
      calculateAutomaticDiscountedAmount(basePrice);
    const totalBookingDiscountAmount = calculateDiscountedAmount(
      Number(basePrice) -
      Number(
        totalBookingAutomaticDiscountAmount
          ? totalBookingAutomaticDiscountAmount
          : 0
      )
    );

    discountAmount =
      Number(totalBookingAutomaticDiscountAmount) +
      Number(totalBookingDiscountAmount);
    // if (discountType === "percentage") {
    //   discountAmount = (basePrice * discount) / 100;
    // } else {
    //   discountAmount = discount;
    // }

    const priceAfterDiscount = basePrice - discountAmount;

    let calculatedCommissionAmount;

    if (commissionAmount && commissionType) {
      if (commission.commissionType === "percentage") {
        calculatedCommissionAmount =
          (priceAfterDiscount * commission.commissionAmount) / 100;
      } else if (commission.commissionType === "fixed") {
        calculatedCommissionAmount = commission.commissionAmount;
      }
    }

    let subtotal;
    if (calculatedCommissionAmount) {
      subtotal = priceAfterDiscount - calculatedCommissionAmount;
    } else {
      subtotal = priceAfterDiscount;
    }

    if (totalAddonsCost) {
      subtotal += totalAddonsCost;
    }

    const gst = subtotal * 0.05;

    const totalAmount = subtotal + gst;

    const discountPercentage = (discountAmount / basePrice) * 100;

    return {
      basePrice: basePrice.toFixed(2),
      discount: discountAmount.toFixed(2),
      priceAfterDiscount: priceAfterDiscount.toFixed(2),
      gst: gst.toFixed(2),
      totalAmount: totalAmount.toFixed(2),
      discountAmount: discountAmount.toFixed(2),
      discountPercentage: discountPercentage,
      // discountType,
      taxAmount: gst.toFixed(2),
      ExtraAdultCost: adultCosts,
      childCosts,
      childCostWithBed,
      childCostWithOutBed,
      totalChildCostPrice,
      adultNumber: guest,
      onlyAdultCost: onlyAdultCost,
      extraAdultNumber: ratesOfPackages?.ExtraAdultCount,
      extraAdultCost: adultCosts,
      childNumber: childAgeArray?.length,

      perPaxCount,
      perPaxCost: (totalAmount / perPaxCount).toFixed(2),
      calculatedAgentAmount: commissionAmt * guest,
      calculatedCommissionAmount,

      extraAdultSavings,
      childSavingsWithBed,
      childSavingsWithoutBed,
      subtotal,
    };
  }

  const handleDiscountChange = (item) => {
    setSelectedDiscount([item]);
  };

  const selectedPrice =
    ACtype === "withAC"
      ? ratesOfPackages?.PriceWithAC
      : ratesOfPackages?.PriceWithoutAC;

  const calculationsOfPackages = calculateTotalAmount(
    selectedPrice,
    selectedAutomaticDiscount,
    selectedDiscount,
    ratesOfPackages?.NoPax,
    ratesOfPackages,
    bookingData?.childAge ? bookingData?.childAge : [],
    totalAddonsCost,
    totalAddonsCostWithoutCommission,
    "",
    markupData,
    commissionAmount,
    commissionType
  );


  const navigateToCheckOutTransport = () => {
    navigate(`/checkout-transport`, {
      state: {
        transportData: singleTransportData,
        bookingData: bookingData,
        selectedVehicle: selectedVehicle,
        selectedCategory: selectedCategory,
        calculationsOfPackages: calculationsOfPackages,
        selectedAddOns: selectedAddOns,
        customized: false,
        ratesOfPackages,
        markupData,
        selectedMealPlan: selectedMealPlan,
        allPackageHotels: allPackageHotels,
        commission: commission,
        // roomsDetails: roomsDetails,
        totalAddonsCost: totalAddonsCost,
        totalAddonsCostWithoutCommission: totalAddonsCostWithoutCommission,
        selectedAutomaticDiscount: selectedAutomaticDiscount,
        selectedDiscount: selectedDiscount,
      },
    });
  };

  const under6AgeChildren = bookingData?.childAge?.filter(
    (age) => Number(age) < 6
  );
  const over6AgeChildren = bookingData?.childAge?.filter(
    (age) => Number(age) >= 6
  );

  // const checkDates = (startDate, endDate) => {
  //   const currentDate = new Date();
  //   const start = new Date(startDate);
  //   const end = new Date(endDate);

  //   return currentDate >= start && currentDate <= end;
  // };

  // const checkBookingDates = (
  //   checkinBookingDate,
  //   checkoutBookingDate,
  //   discountStartDate,
  //   discountEndDate
  // ) => {
  //   const checkin = new Date(checkinBookingDate);
  //   const checkout = new Date(checkoutBookingDate);
  //   const discountStart = new Date(discountStartDate);
  //   const discountEnd = new Date(discountEndDate);

  //   return checkin <= discountEnd && checkout >= discountStart;
  // };


  const checkDates = (startDate, endDate) => {
    const getCurrentDateFormatted = () => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const start = startDate.split("T")[0]
    const end = endDate.split("T")[0];
    return getCurrentDateFormatted() >= start && getCurrentDateFormatted() <= end;
  };

  const checkBookingDates = (
    checkinBookingDate,
    checkoutBookingDate,
    discountStartDate,
    discountEndDate
  ) => {
    const getCurrentDateFormatted = () => {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const checkin = checkinBookingDate?.split("T")[0];
    const checkout = checkoutBookingDate?.split("T")[0];
    const discountStart = discountStartDate?.split("T")[0];
    const discountEnd = discountEndDate?.split("T")[0];

    return checkin <= discountEnd && checkout >= discountStart;

    // return (
    //   getCurrentDateFormatted() >= discountStart &&
    //   getCurrentDateFormatted() <= discountEnd &&
    //   checkout >= discountStart &&
    //   checkin <= discountEnd
    // );
  };

  const isPromotionApplicable = (userType, userId, promotionApplicable) => {
    if (
      Array.isArray(promotionApplicable) &&
      promotionApplicable.length === 0
    ) {
      return true;
    }

    if (promotionApplicable) {
      return promotionApplicable?.some((applicable) => {
        return (
          applicable?.applicableTo === userType &&
          applicable?.applicableIds?.includes(userId?.toString())
        );
      });
    }
  };

  const isBlackoutDate = (blackoutDates, checkDate) => {
    const normalizedCheckDate = new Date(checkDate).toLocaleDateString("en-CA");
    return blackoutDates.some((blackout) => {
      const blackoutDate = new Date(blackout.date).toISOString().split("T")[0];
      return blackoutDate === normalizedCheckDate;
    });
  };

  // const isPromotionApplicableForEntity = (
  //   promotionEntities,
  //   currentEntityType,
  //   currentEntityId
  // ) => {
  //   return promotionEntities.some((entity) => {
  //     return (
  //       entity.entityType === currentEntityType &&
  //       entity?.entityIds?.some((id) => Number(id) === Number(currentEntityId))
  //     );
  //   });
  // };

  const isPromotionApplicableForEntity = (
    promotionEntities,
    currentEntityType,
    currentEntityId
  ) => {
    return promotionEntities.some((entity) => {
      return (
        entity.entityType === currentEntityType &&
        entity?.entityIds?.some((id) => Number(id) === Number(currentEntityId))
      );
    });
  };

  const evaluateConditions = (conditions, validity) => {
    if (conditions.length === 0) {
      return true
    }
    const evaluateCondition = (condition, dateToCheck) => {
      const { conditionType, conditionCondition, conditionValue } = condition;
      let startDate = new Date();
      let endDate = new Date();
      endDate.setDate(startDate.getDate() + 1);
      let inputDate = new Date(dateToCheck);
      let currentDate = new Date(currentDateSingle);
      let comparisonValue;
      let valueToCompare;

      const los = calculateLOS(startDate, endDate);

      if (conditionType === "booking_date") {
        comparisonValue = moment(currentDate).format("DD-MM-YYYY");
        valueToCompare = moment(conditionValue).format("DD-MM-YYYY");

        comparisonValue = new Date(currentDate)
        valueToCompare = new Date(conditionValue)

      } else if (conditionType === "LOS") {
        comparisonValue = los;
        valueToCompare = conditionValue;
      } else {
        comparisonValue = moment(inputDate).format("DD-MM-YYYY");
        valueToCompare = moment(conditionValue).format("DD-MM-YYYY");

        comparisonValue = new Date(inputDate)
        valueToCompare = new Date(conditionValue)
      }

      switch (conditionCondition) {
        case "=":
          return comparisonValue.getTime() === valueToCompare.getTime();
        case ">":
          return comparisonValue > valueToCompare;
        case "!=":
          return comparisonValue != valueToCompare;
        case ">=":
          return comparisonValue >= valueToCompare;
        case "<":
          return comparisonValue < valueToCompare;
        case "<=":
          return comparisonValue <= valueToCompare;
        default:
          return false;
      }
    };

    // let result = conditions.length > 0 ? true : false;

    // for (const condition of conditions) {
    //   const conditionResult = evaluateCondition(condition, validity);

    //   if (condition.logicalCondition === "OR" || !conditions.length === 1) {
    //     result = result || conditionResult;
    //   } else {
    //     result = result && conditionResult;
    //   }
    // }
    // return result;


    if (conditions.length === 1) {
      return evaluateCondition(conditions[0], validity);
    }

    const logicalCondition = conditions[0].logicalCondition || "AND"; 

    let result = logicalCondition === "AND" ? true : false;

    for (const condition of conditions) {
      const conditionResult = evaluateCondition(condition, validity);

      if (logicalCondition === "OR") {
        result = result || conditionResult; 
      } else {
        result = result && conditionResult;
      }
    }

    return result;
  };

  const fetchPromotions = async ({
    checkInDateFromBooking,
    packageIdFromBooking,
  }) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
      myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);

      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/promotions`,
        requestOptions
      );
      const result = await response.json();
      setPromotions(result?.data);

      const applicableAutomaticPromotion = result?.data
        ?.filter((item) => {
          const isAutomatic = item.promotionType === "automatic";

          const isValidDate = checkDates(item.startDate, item.endDate);

          const isBookingWithinDiscount = checkBookingDates(
            checkInDateFromBooking,
            checkInDateFromBooking,
            item.startDate,
            item.endDate
          );

          const isUserEligible = isPromotionApplicable(
            userType === "customer"
              ? "customers"
              : userType === "agent"
                ? "agents"
                : "",
            userDetails?.id,
            item.promotionApplicable
          );

          const blackoutDates = item.promotionBlackOutDate || [];

          const isBlackout = isBlackoutDate(blackoutDates, checkInDateFromBooking);

          const isApplicableForEntity = isPromotionApplicableForEntity(
            item.promotionEntity,
            "transport",
            packageIdFromBooking
          );

          const isConditionsValid = evaluateConditions(
            item.promotionConditions,
            checkInDateFromBooking
          );

          return (
            isAutomatic &&
            isValidDate &&
            isBookingWithinDiscount &&
            isUserEligible &&
            !isBlackout &&
            isApplicableForEntity &&
            isConditionsValid
          );
        })
        ?.sort((a, b) => b.stackable - a.stackable);

      let selectedPromotions = [];

      if (applicableAutomaticPromotion.length > 0) {
        const firstPromotion = applicableAutomaticPromotion[0];
        selectedPromotions.push(firstPromotion);

        if (applicableAutomaticPromotion.length > 1) {
          const secondPromotion = applicableAutomaticPromotion[1];
          if (firstPromotion.stackable && secondPromotion.stackable) {
            selectedPromotions.push(secondPromotion);
          }
        }
      }

      setSelectedAutomaticDiscount(selectedPromotions);
    } catch (error) {
      console.error("Error fetching promotions:", error);
    }
  };

  const fetchCommissions = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${userToken}`);
    myHeaders.append("Cookie", `${process.env.REACT_APP_COOKIE}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      // body: urlencoded,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/v1/website/validate/fetch/commission/package/${packageId}`,
        requestOptions
      );
      const data = await response.json();
      if (data.success) {
        if (data?.data?.commissionType != "none" && data?.data?.commissionAmount !== 0) {
          setCommission(data?.data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (packageId && userType === "agent") {
      fetchCommissions();
    }
  }, [packageId, userType]);

  return (
    <>
      <Header />
      <PageMetaData
        title={`${singleTransportData?.packageName &&
          singleTransportData?.packageName !== "" &&
          singleTransportData?.packageName !== undefined &&
          singleTransportData?.packageName
          } - Mountain Mysteries`}
        description="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        keywords="hotel management, RevCatalyst, property optimization"
      />
      <OgMetaTitles
        title={`${singleTransportData?.packageName &&
          singleTransportData?.packageName !== "" &&
          singleTransportData?.packageName !== undefined &&
          singleTransportData?.packageName
          } - Mountain Mysteries`}
        type="Enhance your hotel capabilities with RevCatalyst's powerful system. Optimize performance, streamline operations, and unleash the full potential of property."
        url={`https://www.mountainmysteries.com/package/${singleTransportData?.packagehandle}?location=&guest=2&children=0&packagehandle=${singleTransportData?.packagehandle}#package-information`}
      />

      <section>
        <div className="container">
          {/* <PackageDetails packageData={singleTransportData} /> */}
          <SingleTransportHeader
            // allPackageHotels={allPackageHotels}
            // selectedCategory={selectedCategory}
            transportData={singleTransportData}
          />
          <main className="justify_content_center flex  packages_flex_wrap">
            <div className="reservation-content">
              <div
                className="m_0 package-update-profile-tab-form profile_tab_form"
                style={{ padding: "0" }}
              >
                <div className="package-update-content">
                  <TransportTabHeader
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  <div className="package-update-content-containers">
                    {renderContent()}
                  </div>
                </div>
              </div>
            </div>

            <aside>
              <div className="common_background common_background_parent_sticky">
                {/* {singleTransportData &&
                                    singleTransportData?.packageRates &&
                                    singleTransportData?.packageRates.length > 0 && ( */}
                <div>
                  <label>Select Vehicle</label>
                  <select
                    className="w-full"
                    name="meal_plan"
                    value={selectedVehicle}
                    onChange={handleSelectVehicleChange}
                  >
                    <option value="" disabled>
                      Select an option
                    </option>
                    <option value="sedan">Sedan</option>
                    <option value="ertiga">Ertiga</option>
                    <option value="innova-crysta">Innova Crysta</option>
                    <option value="maharaja-tempo">Maharaja Tempo</option>
                    <option value="tempo-traveller-twelve-seater">
                      Tempo Traveller
                    </option>
                    <option value="urbenia-twelve-seater">
                      Urbenia-twelve-seater
                    </option>
                  </select>
                </div>

                <div>
                  <label style={{ margin: "10px 0 5px 0" }}>
                    Air Preferences:
                  </label>
                  <div>
                    <label style={{ cursor: "pointer", display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <input
                        type="radio"
                        name="meal_plan"
                        value="withoutAC"
                        checked={ACtype === "withoutAC"}
                        onChange={(e) => setACtype(e.target.value)}
                      />
                      <span>
                        Selective Cool (Partial AC)<br />
                        <span style={{ fontSize: '11px' }}>Cooling tailored to the terrain.</span>
                      </span>
                    </label>
                    <label style={{ cursor: "pointer", display: 'flex', alignItems: 'center', gap: '5px', marginBottom: '10px' }}>
                      <input
                        type="radio"
                        name="meal_plan"
                        value="withAC"
                        checked={ACtype === "withAC"}
                        onChange={(e) => setACtype(e.target.value)}
                      />
                      <span>
                        Constant Breeze (Full AC)<br />
                        <span style={{ fontSize: '11px' }}>Stay cool at every mile.</span>
                      </span>
                    </label>
                  </div>
                </div>
                {/* )} */}

                <div className="flex justify_content_between align_items_center">
                  <h4 style={{ fontSize: "14px", padding: "10px 0px" }}>
                    Fare Summary
                  </h4>

                  <div onClick={togglePaymentDetails}>
                    <button
                      className={`${activePayment === "priceSummary" ? "" : ""
                        }`}
                      onClick={handleButtonClick}
                    >
                      <small className="link_btn">
                        {activePayment === "priceSummary"
                          ? "Minimize Breakup"
                          : "View Breakup"}{" "}
                      </small>
                      <svg
                        width="13"
                        height="8"
                        viewBox="0 0 13 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          transition: "transform 0.3s",
                          transform: paymentDetails
                            ? "rotate(180deg)"
                            : "rotate(0deg)",
                        }}
                      >
                        <path
                          d="M11.5527 1.34473L5.86308 6.65507L0.552735 1.34473"
                          stroke="#222222"
                          strokeWidth="1.5"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <>
                    <div className="flex justify_content_between mt-10">
                      <p>
                        <b>Total Cost</b>
                      </p>
                      <span
                        className={`room_text-right alert_text ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                          }`}
                      >
                        <b>
                          {" "}
                          {formatIndianCurrency(
                            calculationsOfPackages?.basePrice
                          )}
                        </b>
                      </span>
                    </div>

                    {activePayment === "priceSummary" && (
                      <>
                        {(selectedAutomaticDiscount.length > 0 ||
                          selectedDiscount.length > 0) && (
                            <div className="mt-10">
                              <p>
                                <b>
                                  Transport Discount
                                  {selectedAutomaticDiscount?.length > 1
                                    ? "s"
                                    : ""}
                                </b>
                              </p>
                              {selectedAutomaticDiscount?.map(
                                (discount, index) => (
                                  <div
                                    className="flex justify_content_between mt-2"
                                    key={discount.id}
                                  >
                                    <span className="discount-name">
                                      {discount.promotionName}
                                    </span>
                                    <span className="room_text-right green">
                                      {discount.promotionMode === "percentage"
                                        ? `${+discount.promotionAmount}%`
                                        : `${formatIndianCurrency(
                                          discount.promotionAmount
                                        )}`}
                                    </span>
                                  </div>
                                )
                              )}

                              {selectedDiscount &&
                                selectedDiscount?.length > 0 &&
                                selectedDiscount?.map((discount, index) => (
                                  <div
                                    className="flex justify_content_between mt-2"
                                    key={discount.id}
                                  >
                                    <span className="discount-name">
                                      {discount.promotionName}
                                    </span>
                                    <span className="room_text-right green">
                                      {discount.promotionMode === "percentage"
                                        ? `${+discount.promotionAmount}%`
                                        : `${formatIndianCurrency(
                                          discount.promotionAmount
                                        )}`}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          )}

                        {calculationsOfPackages?.discount > 0 && (
                          <div className="flex justify_content_between mt-10 border-bottom-one ">
                            <p>Package Cost (After Disc.)</p>
                            <span
                              className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
                                0 && "filterBlur3"
                                }`}
                            >
                              {formatIndianCurrency(
                                calculationsOfPackages?.priceAfterDiscount
                              )}
                            </span>
                          </div>
                        )}

                        {commission && Object.keys(commission).length > 0 && (
                          <>
                            <div className="flex justify_content_between mt-10">
                              <p>
                                Commission{" "}
                                <small>
                                  ({" "}
                                  {commission.commissionType === "fixed" ? (
                                    <span className="room_text-right">
                                      {formatIndianCurrency(
                                        commission?.commissionAmount
                                      )}
                                    </span>
                                  ) : (
                                    <span className="room_text-right">
                                      {commission?.commissionAmount}%
                                    </span>
                                  )}
                                  )
                                </small>
                              </p>

                              {formatIndianCurrency(
                                calculationsOfPackages?.calculatedCommissionAmount
                              )}
                            </div>
                          </>
                        )}

                        {selectedAddOns && selectedAddOns.length > 0 && (
                          <div className="flex justify_content_between mt-10">
                            <p>Addons Price</p>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "end",
                              }}
                            >
                              {commission &&
                                Object.keys(commission).length > 0 && (
                                  <small
                                    style={{
                                      color: "red",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    {formatIndianCurrency(
                                      totalAddonsCostWithoutCommission
                                    )}
                                  </small>
                                )}
                              <b>{formatIndianCurrency(totalAddonsCost)}</b>
                            </span>
                          </div>
                        )}

                        <div className="flex justify_content_between mt-10">
                          <p>Subtotal </p>

                          {formatIndianCurrency(
                            calculationsOfPackages?.subtotal
                          )}
                        </div>
                      </>
                    )}

                    <div className="flex justify_content_between mt-10 border-bottom-one ">
                      <p>GST(5%) </p>
                      <span
                        className={`room_text-right  ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                          "filterBlur3"
                          }`}
                      >
                        {formatIndianCurrency(
                          calculationsOfPackages?.taxAmount
                        )}
                      </span>
                    </div>

                    <div className="flex justify_content_between mt-10">
                      <h6>
                        <b>Total Amount to be paid</b>
                      </h6>
                      <h6>
                        <span
                          className={` ${Number(calculationsOfPackages?.totalAmount) <= 0 &&
                            "filterBlur3"
                            }`}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                          }}
                        >
                          <b>
                            {formatIndianCurrency(
                              calculationsOfPackages?.totalAmount
                            )}
                          </b>
                        </span>
                      </h6>
                    </div>
                  </>
                </div>

                {promotions.length > 0 && (
                  <>
                    {promotions.some(
                      (item) =>
                        item.promotionType === "coupon" &&
                        (() => {
                          const isValidDate = checkDates(
                            item.startDate,
                            item.endDate
                          );
                          const isBookingWithinDiscount = checkBookingDates(
                            startDate,
                            startDate,
                            item.startDate,
                            item.endDate
                          );
                          const isUserEligible = isPromotionApplicable(
                            userType === "customer"
                              ? "customers"
                              : userType === "agent"
                                ? "agents"
                                : "",
                            userDetails?.id,
                            item.promotionApplicable
                          );
                          const blackoutDates =
                            item.promotionBlackOutDate || [];
                          const isBlackout = isBlackoutDate(
                            blackoutDates,
                            startDate
                          );
                          const isApplicableForEntity =
                            isPromotionApplicableForEntity(
                              item.promotionEntity,
                              "transport",
                              singleTransportData?.packageId
                            );
                          const isConditionsValid = evaluateConditions(
                            item.promotionConditions,
                            startDate
                          );

                          return (
                            isValidDate &&
                            isBookingWithinDiscount &&
                            isUserEligible &&
                            !isBlackout &&
                            isApplicableForEntity &&
                            isConditionsValid
                          );
                        })()
                    ) && (
                        <div
                          className="common_background package_price_breakup_discount"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="flex justify_content_between cursor_pointer">
                            <div className="flex justify_content_between w-100">
                              <div>
                                <h5 className="text-left">Discounts</h5>
                              </div>
                              {selectedDiscount && (
                                <button
                                  className="view_btn"
                                  onClick={() => setSelectedDiscount([])}
                                >
                                  clear
                                </button>
                              )}
                            </div>
                          </div>
                          <div>
                            {promotions?.map((item, index) => {
                              const isCouponCode =
                                item?.promotionType === "coupon" ? true : false;
                              const isValidDate = checkDates(
                                item.startDate,
                                item.endDate
                              );
                              const isBookingWithinDiscount = checkBookingDates(
                                startDate,
                                startDate,
                                item.startDate,
                                item.endDate
                              );
                              const isUserEligible = isPromotionApplicable(
                                userType === "customer"
                                  ? "customers"
                                  : userType === "agent"
                                    ? "agents"
                                    : "",
                                userDetails?.id,
                                item.promotionApplicable
                              );
                              const blackoutDates =
                                item.promotionBlackOutDate || [];
                              const isBlackout = isBlackoutDate(
                                blackoutDates,
                                startDate
                              );
                              const isApplicableForEntity =
                                isPromotionApplicableForEntity(
                                  item.promotionEntity,
                                  "transport",
                                  singleTransportData?.packageId
                                );

                              const isConditionsValid = evaluateConditions(
                                item.promotionConditions,
                                startDate
                              );

                              return (
                                isCouponCode &&
                                isValidDate &&
                                isBookingWithinDiscount &&
                                isUserEligible &&
                                !isBlackout &&
                                isApplicableForEntity &&
                                isConditionsValid && (
                                  <div
                                    key={index}
                                    className="flex discountSingleDiv justify_content_between w-100"
                                  >
                                    <div className="flex">
                                      <label
                                        style={{
                                          textTransform: "capitalize",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                        htmlFor={item?.promotionName}
                                      >
                                        {" "}
                                        <input
                                          // checked={
                                          //   selectedDiscount?.id === item?.id
                                          // }
                                          // onChange={() =>
                                          //   handleDiscountChange(item)
                                          // }
                                          // type="radio"
                                          // id={item?.promotionName}

                                          checked={selectedDiscount?.some(
                                            (discount) => discount.id === item.id
                                          )}
                                          onChange={() =>
                                            handleDiscountChange(item)
                                          }
                                          type="radio"
                                          id={item.promotionName}
                                        />
                                        <div>
                                          <div>
                                            {item?.promotionName?.replace(
                                              /-/g,
                                              " "
                                            )}
                                          </div>
                                        </div>
                                      </label>
                                    </div>
                                    <div>
                                      {item?.promotionMode === "fixed" ? (
                                        formatIndianCurrency(
                                          item?.promotionAmount
                                        )
                                      ) : (
                                        <>{item?.promotionAmount}%</>
                                      )}
                                    </div>
                                  </div>
                                )
                              );
                            })}
                          </div>
                        </div>
                      )}
                  </>
                )}

                <div className="buttons flex_direction_col">
                  {/* <Link to={"/checkout-package"}> */}
                  <>
                    <button
                      style={{ marginTop: "10px", borderRadius: "15px" }}
                      onClick={navigateToCheckOutTransport}
                      className="primary_btn"
                    >
                      Book Now
                    </button>
                  </>

                  {singleTransportData?.packageAddons &&
                    singleTransportData?.packageAddons.length > 0 && (
                      <div className="mt-20">
                        {singleTransportData.packageAddons.some(
                          (item) => !item.detail.includes("Volvo") && item.addonType !== "ticket"
                        ) && <h5 className="mb-10">Addons</h5>}
                        {singleTransportData.packageAddons
                          .filter((item) => !item.detail.includes("Volvo") && item.addonType !== "ticket")
                          .map((item, index) => {
                            let finalAddonPrice = item?.price;
                            let priceWithMarkup = item?.price;

                            if (markupData) {
                              const markup = markupData;
                              if (markup.markupType === "percentage") {
                                finalAddonPrice += (item.price * parseFloat(markup.amount)) / 100;
                              } else if (markup.markupType === "fixed") {
                                finalAddonPrice += parseFloat(markup.amount);
                              }
                            }
                            priceWithMarkup = finalAddonPrice;

                            if (commission?.commissionAmount && commission?.commissionType) {
                              if (commission?.commissionType === "percentage") {
                                finalAddonPrice -=
                                  (Number(finalAddonPrice) * Number(commission?.commissionAmount)) / 100;
                              } else if (commission?.commissionType === "fixed") {
                                finalAddonPrice = Number(commission?.commissionAmount);
                              }
                            }

                            return (
                              <div
                                key={index}
                                className="flex justify_content_between w-100 mt-10 align_items_start"
                              >
                                <div style={{ cursor: "pointer", width: "100%" }}>
                                  <div style={{ fontSize: "15px", fontWeight: 500 }}>
                                    {item.detail} {" "}
                                    {commission?.commissionAmount && commission?.commissionType && (
                                      <span style={{ fontSize: "12px", color: 'red', textDecoration: 'line-through' }}>
                                        {formatIndianCurrency(priceWithMarkup)} {item.perPerson ? `* ${guest}` : ''}
                                      </span>
                                    )}
                                    <span style={{ fontSize: "12px", color: '#222222' }}>
                                      {formatIndianCurrency(finalAddonPrice)} {item.perPerson ? `* ${guest}` : ''}
                                    </span>
                                  </div>
                                </div>
                                <div className="flex justify_content_end" style={{ width: "60%" }}>
                                  <button
                                    style={{ fontSize: "10px" }}
                                    onClick={() => handleSelectedAddOns({ ...item, finalAddonPrice, priceWithMarkup })}
                                  >
                                    {selectedAddOns.some((addon) => addon.id === item.id) ? (
                                      <div style={{ color: "red" }}>
                                        <svg
                                          fill="red"
                                          width="20px"
                                          height="14px"
                                          viewBox="0 0 24 24"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <defs></defs>
                                          <path
                                            id="cancel"
                                            className="cls-1"
                                            d="M936,120a12,12,0,1,1,12-12A12,12,0,0,1,936,120Zm0-22a10,10,0,1,0,10,10A10,10,0,0,0,936,98Zm4.706,14.706a0.951,0.951,0,0,1-1.345,0l-3.376-3.376-3.376,3.376a0.949,0.949,0,1,1-1.341-1.342l3.376-3.376-3.376-3.376a0.949,0.949,0,1,1,1.341-1.342l3.376,3.376,3.376-3.376a0.949,0.949,0,1,1,1.342,1.342l-3.376,3.376,3.376,3.376A0.95,0.95,0,0,1,940.706,112.706Z"
                                            transform="translate(-924 -96)"
                                          />
                                        </svg>
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          background: "#016937",
                                          color: "#fff",
                                          borderRadius: "4px",
                                          padding: "5px 3px 5px 7px",
                                          display: "flex",
                                        }}
                                      >
                                        {" "}
                                        Add
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          height="16px"
                                          viewBox="0 -960 960 960"
                                          width="16px"
                                          fill="#ffffff"
                                        >
                                          <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z" />
                                        </svg>
                                      </div>
                                    )}
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
                </div>
              </div>
              <OurQuery />
            </aside>
          </main>
        </div>
      </section>
      <Footer />

      {showRatePopup && (
        <NewSendEnquiryPopup
          package_id={singleTransportData?.packageId}
          setshowRatePopup={setshowRatePopup}
          typeDetail="package"
        />
      )}
    </>
  );
}

export default SingleTransport;


// {
//   activePayment !== "priceSummary" && (
//     <>
//       <div className="flex justify_content_between mt-10">
//         <p>
//           Adult Cost{" "}
//           <small>
//             (
//             {calculationsOfPackages?.onlyAdultCost /
//               calculationsOfPackages?.adultNumber}{" "}
//             X {calculationsOfPackages?.adultNumber})

//           </small>
//         </p>
//         <span
//           className={`room_text-right ${Number(calculationsOfPackages?.onlyAdultCost) <=
//             0 && "filterBlur3"
//             }`}
//         >
//           {formatIndianCurrency(
//             calculationsOfPackages?.onlyAdultCost
//           )}
//         </span>
//       </div>
//       {calculationsOfPackages?.extraAdultNumber > 0 &&
//         calculationsOfPackages?.ExtraAdultCost ? (
//         <div className="flex justify_content_between mt-10">
//           <p>
//             Extra Adult Cost{" "}
//             <small>

//               (
//               {calculationsOfPackages?.ExtraAdultCost /
//                 calculationsOfPackages?.extraAdultNumber}{" "}
//               X {calculationsOfPackages?.extraAdultNumber})
//             </small>
//           </p>
//           <span
//             className={`room_text-right ${Number(calculationsOfPackages?.totalAmount) <=
//               0 && "filterBlur3"
//               }`}
//           >
//             {formatIndianCurrency(
//               calculationsOfPackages?.ExtraAdultCost
//             )}
//           </span>
//         </div>
//       ) : null}

//       {under6AgeChildren.length > 0 &&
//         calculationsOfPackages?.childNumber > 0 &&
//         calculationsOfPackages?.childCostWithOutBed ? (
//         <div className="flex justify_content_between mt-10">
//           <p>
//             Child Cost ({`< 6 yrs`}){" "}
//             <small>
//               ̀ (
//               {calculationsOfPackages?.childCostWithOutBed /
//                 under6AgeChildren.length}{" "}
//               X {under6AgeChildren.length})
//             </small>
//           </p>
//           <span
//             className={`room_text-right ${Number(
//               calculationsOfPackages?.childCostWithOutBed
//             ) <= 0 && "filterBlur3"
//               }`}
//           >
//             {formatIndianCurrency(
//               calculationsOfPackages?.childCostWithOutBed
//             )}
//           </span>
//         </div>
//       ) : null}

//       {over6AgeChildren.length > 0 &&
//         calculationsOfPackages?.childNumber > 0 &&
//         calculationsOfPackages?.childCostWithBed ? (
//         <div className="flex justify_content_between mt-10">
//           <p>
//             Child Cost ({`> 6 yrs`}){" "}
//             <small>
//               ̀ (
//               {calculationsOfPackages?.childCostWithBed /
//                 over6AgeChildren.length}{" "}
//               X {over6AgeChildren.length})
//             </small>
//           </p>
//           <span
//             className={`room_text-right ${Number(
//               calculationsOfPackages?.childCostWithBed
//             ) <= 0 && "filterBlur3"
//               }`}
//           >
//             {formatIndianCurrency(
//               calculationsOfPackages?.childCostWithBed
//             )}
//           </span>
//         </div>
//       ) : null}

//     </>
//   )
// }