import React, { useEffect, useRef, useState } from "react";
import "./SearchStateInput.css";
import SkeletonLoader from "../SkeletonLoader/SkeletonLoader";
import BgBackdrop from "../BgBackDrop/BgBackDrop";
import { useLocation } from "react-router-dom";

function TransportHubsSearchInput({
    onClick,
    onDataChange,
    dropdownGuestDetails,
    setIsguestDetails,
    className,
}) {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [SearchState, setSearchState] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const [selectedType, setSelectedType] = useState("");
    const [resultArray, setResultArray] = useState([]);
    const location = useLocation();

    const dropdownRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setResultArray([]);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const fetchHubs = async (searchTerm) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/destinations/fetch/hubs`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "key": process.env.REACT_APP_BASE_API_KEY
                },
                body: JSON.stringify({ search: searchTerm }),
                redirect: "follow"
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            if (result) {
                setResultArray(result?.data);
            }
        } catch (error) {
            console.error("Fetch error:", error);
            return null;
        }
    };

    const handleOnStateChange = (e) => {
        setSelectedState(e.target.value);
        const { value } = e.target;
        fetchHubs(value);

        // const filteredArray = SearchState.filter((item) =>
        //   item?.name?.toLowerCase()?.includes(value?.toLowerCase())
        // );
        // setResultArray(filteredArray);
    };

    useEffect(() => {
        onDataChange(selectedState, resultArray, selectedType);
    }, [selectedState, resultArray, selectedType]);

    const handleKeyDown = (event) => {
        if (resultArray?.length === 0) return;

        if (event.key === "ArrowDown") {
            setActiveIndex((prevIndex) =>
                prevIndex < resultArray?.length - 1 ? prevIndex + 1 : prevIndex
            );
        } else if (event.key === "ArrowUp") {
            setActiveIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : prevIndex
            );
        } else if (event.key === "Enter" && activeIndex >= 0) {
            const selectedItem = resultArray[activeIndex];

            setSelectedState(selectedItem.name);
            setSelectedType(selectedItem);
            setResultArray([]);
            setIsguestDetails(true);
            onDataChange(selectedItem.name, resultArray, selectedItem);
        }
    };

    useEffect(() => {
        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [activeIndex, resultArray]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const data = {
            endDestination: params.get("enddestination"),
            startDestination: params.get("startdestination"),
        };

        setSelectedState(data.startDestination);

    }, [location.search]);

    return (
        <>
            <div className="search-location-input relative">
                <input
                    onClick={onClick}
                    className={`search_form_input pac-target-input ${className}`}
                    placeholder="Search Hubs.."
                    value={selectedState}
                    onChange={handleOnStateChange}
                    required
                />

                {resultArray?.length > 0 && (
                    <div className="search_list" ref={dropdownRef}>
                        {resultArray?.map((state, index) => (
                            <div
                                key={index}
                                // className={activeIndex === index ? "active-item" : ""}
                                style={{
                                    backgroundColor: index === activeIndex ? "#e9e9e9" : "",
                                }}
                                onClick={() => [
                                    setSelectedState(state?.name),
                                    setSelectedType(state),
                                    setResultArray([]),
                                    setIsguestDetails(true),
                                ]}
                            >
                                <span>
                                    {state.type === "destination" ? (
                                        <span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="24px"
                                                viewBox="0 -960 960 960"
                                                width="24px"
                                                fill="#000000"
                                            >
                                                <path d="M480-480q33 0 56.5-23.5T560-560q0-33-23.5-56.5T480-640q-33 0-56.5 23.5T400-560q0 33 23.5 56.5T480-480Zm0 294q122-112 181-203.5T720-552q0-109-69.5-178.5T480-800q-101 0-170.5 69.5T240-552q0 71 59 162.5T480-186Zm0 106Q319-217 239.5-334.5T160-552q0-150 96.5-239T480-880q127 0 223.5 89T800-552q0 100-79.5 217.5T480-80Zm0-480Z" />
                                            </svg>
                                        </span>
                                    ) : state.type === "hotel" ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 -960 960 960"
                                            width="24px"
                                            fill="#000000"
                                        >
                                            <path d="M200-160v-366L88-440l-48-64 440-336 160 122v-82h120v174l160 122-48 64-112-86v366H520v-240h-80v240H200Zm80-80h80v-240h240v240h80v-347L480-739 280-587v347Zm120-319h160q0-32-24-52.5T480-632q-32 0-56 20.5T400-559Zm-40 319v-240h240v240-240H360v240Z" />
                                        </svg>
                                    ) : state.type === "activity" ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 -960 960 960"
                                            width="24px"
                                            fill="#000000"
                                        >
                                            <path d="m216-160-56-56 384-384H440v80h-80v-160h233q16 0 31 6t26 17l120 119q27 27 66 42t84 16v80q-62 0-112.5-19T718-476l-40-42-88 88 90 90-262 151-40-69 172-99-68-68-266 265Zm-96-280v-80h200v80H120ZM40-560v-80h200v80H40Zm739-80q-33 0-57-23.5T698-720q0-33 24-56.5t57-23.5q33 0 57 23.5t24 56.5q0 33-24 56.5T779-640Zm-659-40v-80h200v80H120Z" />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 -960 960 960"
                                            width="24px"
                                            fill="#000000"
                                        >
                                            <path d="M40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm720 0v-120q0-44-24.5-84.5T666-434q51 6 96 20.5t84 35.5q36 20 55 44.5t19 53.5v120H760ZM360-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm400-160q0 66-47 113t-113 47q-11 0-28-2.5t-28-5.5q27-32 41.5-71t14.5-81q0-42-14.5-81T544-792q14-5 28-6.5t28-1.5q66 0 113 47t47 113ZM120-240h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 320Zm0-400Z" />
                                        </svg>
                                    )}

                                    <span>
                                        {state.name}
                                    </span>
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </>
    );
}

export default TransportHubsSearchInput;
