import React, { useEffect, useState } from "react";
import { showErrorToast, showSuccessToast } from "../../assets/toastUtils";
import styles from "./PackageQueryForm.module.scss";
import locationIcon from "../../assets/images/location_icon.png";
import destinationIcon from "../../assets/images/experienc_icon.png";
import securityIcon from "../../assets/images/security_icon.png";
import customerIcon from "../../assets/images/customer_icon.png";
import MultiSelectInputSingleId from "../MultiSelectInputSingleId/MultiSelectInputSingleId";
import { useLocation } from "react-router-dom";

const ActivityQueryFormPopup = ({ package_id, setshowRatePopup, typeDetail, activityDestination }) => {
    const [showRatePopup, setShowRatePopup] = useState(false);
    const location = useLocation();

    const [formErrors, setFormErrors] = useState({});

    const [loader, setLoader] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        date_information: "",
        source: "website",
        adult: "",
        time_slot_information: "",
        content: "",
        source_type: "",
        guide_required: "",


        type: typeDetail ? typeDetail : "package",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleShowRatePopup = (e) => {
        e.stopPropagation();
        setshowRatePopup(false);
    };

    const validate = () => {
        const errors = {};
        // Add validation logic for each form field
        // if (currentStep === 1) {
        if (!formData.name.trim()) {
            errors.name = "required";
        }
        if (!formData.company_name.trim()) {
            errors.company_name = "required";
        }
        if (!formData.phone.trim()) {
            errors.phone = "is required";
        }
        if (!formData.querry_detail) {
            errors.querry_detail = "required";
        }
        if (!formData.email) {
            errors.email = "required";
        }
        if (!formData.travel_date) {
            errors.travel_date = "required";
        }
        // }

        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
        myHeaders.append("Cookie", process.env.REACT_APP_COOKIE);
        const params = new URLSearchParams(location.search);

        const adult = params.get("adult");

        const rawData = {
            name: formData?.name,
            email: formData?.email,
            phone_information: formData?.phone,
            content: formData?.content,
            source: formData?.source,
            source_type: formData?.source_type,
            type: formData?.type,

            time_slot_information: formData?.time_slot_information,
            date_information: formData?.date_information,
            guide_required_information: formData?.guide_required,
            paxes_information: adult ? adult : 2,
            activity_destination_information: activityDestination ? activityDestination : 'shimla',
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/website/query/create`,
                {
                    method: "POST",
                    headers: myHeaders,
                    body: JSON.stringify(rawData),
                    redirect: "follow",
                }
            );
            const result = await response.json();
            if (result.success) {
                showSuccessToast(result.message || "Form submitted successfully!");
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    date_information: "",
                    source: "website",
                    adult: "",
                    time_slot_information: "",
                    content: "",
                    source_type: "",
                    guide_required: "",
                });
                setshowRatePopup(false);
            } else {
                showErrorToast(
                    result.message || "An error occurred. Please try again."
                );
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    date_information: "",
                    source: "website",
                    adult: "",
                    time_slot_information: "",
                    content: "",
                    source_type: "",
                    guide_required: "",
                });
            }
        } catch (error) {
            showErrorToast("An error occurred. Please try again.");
            console.error(error);
        }
    };

    // const fetchDestinations = async () => {
    //     setLoader(true);
    //     const myHeaders = new Headers();
    //     myHeaders.append("Accept", "application/json");
    //     myHeaders.append("key", process.env.REACT_APP_BASE_API_KEY);
    //     const formData = new FormData();
    //     // formData.append("limit", 10);

    //     const requestOptions = {
    //         method: "POST",
    //         headers: myHeaders,
    //         redirect: "follow",
    //         body: formData,
    //     };

    //     try {
    //         const response = await fetch(
    //             `${process.env.REACT_APP_BASE_URL}/api/v1/destinations/fetch`,
    //             requestOptions
    //         );
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! Status: ${response.status}`);
    //         }
    //         const result = await response.json();
    //         setDestinationData(result?.data);
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     } finally {
    //         setLoader(false);
    //     }
    // };

    // useEffect(() => {
    //     fetchDestinations();
    // }, []);

    // console.log(destinationData, 'destinationData');

    return (
        <div className="bg-backdrop">
            <div className={styles.new_popup_flex}>
                <div className={styles.new_popup_colored}>
                    <div className="flex align_items_center h-full">
                        <div>
                            <h4
                                style={{
                                    fontSize: "25px",
                                    fontWeight: "600",
                                    color: "#333",
                                    textAlign: "left",
                                }}
                            >
                                Over 22+ Years of Experience
                            </h4>
                            <div className={styles.choose_us}>
                                <div className={styles.highlight}>
                                    <img src={locationIcon} alt="location-icon" />
                                    <span>All in one platform</span>
                                </div>
                                <div className={styles.highlight}>
                                    <img src={destinationIcon} alt="destination-icon" />
                                    <span>Expertise in Mountain Destinations</span>
                                </div>
                                <div className={styles.highlight}>
                                    <img src={securityIcon} alt="security-icon" />
                                    <span> Security</span>
                                </div>
                                <div className={styles.highlight}>
                                    <img src={customerIcon} alt="customer-icon" />
                                    <span>24x7 Customer Support</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className={styles.new_popup_form}>
                    <h4
                        style={{
                            fontSize: "18px",
                            fontWeight: "600",
                            color: "#BB735D",
                            textAlign: "left",
                        }}
                    >
                        Our expert will get in touch with you shortly
                    </h4>
                    <div
                        className="dashboard_checkin_modal_close_btn"
                        onClick={handleShowRatePopup}
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                        >
                            <path
                                d="M1 12.7464L13 0.851318"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                            />
                            <path
                                d="M13 12.7463L0.999999 0.851296"
                                stroke="#AAAAAA"
                                strokeWidth="1.2"
                            />
                        </svg>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                            marginTop: "20px",
                        }}
                    >
                        <div className="flex flex_gap_10">
                            <div className={styles.package_query_input_50}>
                                <label htmlFor="name">Name:</label>
                                <input
                                    // required
                                    className="w-full"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    placeholder="Your Name"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.name && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>

                            <div className={styles.package_query_input_50}>
                                <label htmlFor="email">Email:</label>
                                <input
                                    className="w-full"
                                    type="text"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder="Email"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.email && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex flex_gap_10">
                            <div className={styles.package_query_input_50}>
                                <label htmlFor="phone">Phone:</label>
                                <input
                                    className="w-full"
                                    type="number"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    placeholder="Phone"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.phone && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>
                            <div className={styles.package_query_input_50}>
                                <label htmlFor="source_type">Are you Agent:</label>
                                <select
                                    style={{
                                        padding: "10px",
                                        ...(formErrors.source_type && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                    className="w-full"
                                    name="source_type"
                                    value={formData.source_type}
                                    onChange={handleInputChange}
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    <option value="agent">Yes</option>
                                    <option value="customer">No</option>
                                </select>
                            </div>
                        </div>

                        <div className="flex flex_gap_10">
                            <div className={styles.package_query_input_50}>
                                <label htmlFor="date_information">Date:</label>
                                <input
                                    className="w-full"
                                    type="date"
                                    name="date_information"
                                    value={formData.date_information}
                                    onChange={handleInputChange}
                                    placeholder="Start Date"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.date_information && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>

                            <div className={styles.package_query_input_50}>
                                <label htmlFor="time_slot_information">Time:</label>
                                <input
                                    className="w-full"
                                    type="time"
                                    name="time_slot_information"
                                    value={formData.time_slot_information}
                                    onChange={handleInputChange}
                                    placeholder="Time"
                                    style={{
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc",
                                        ...(formErrors.time_slot_information && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                />
                            </div>
                        </div>

                        <div className="flex flex_gap_10">
                            <div className="w-full">
                                <label htmlFor="guide_required">Do you need Guide:</label>
                                <select
                                    style={{
                                        padding: "10px",
                                        ...(formErrors.guide_required && {
                                            backgroundColor: "#ff000040",
                                            border: "1px solid red",
                                        }),
                                    }}
                                    className="w-full"
                                    name="guide_required"
                                    value={formData.guide_required}
                                    onChange={handleInputChange}
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="content">Query Detail:</label>
                            <textarea
                                className="w-full"
                                type="text-area"
                                name="content"
                                value={formData.content}
                                onChange={handleInputChange}
                                placeholder="Querry Detail"
                                style={{
                                    padding: "10px",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                    ...(formErrors.content && {
                                        backgroundColor: "#ff000040",
                                        border: "1px solid red",
                                    }),
                                }}
                            />
                        </div>
                        <div className="">
                            <button type="submit" className={styles.common_btn}>
                                SEND
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ActivityQueryFormPopup;
